import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CacheMechanism, LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings, ManualParserLoader } from '@gilsdav/ngx-translate-router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccountComponent } from './account/account.component';
import { AllsessionformComponent } from './allsessionform/allsessionform.component';
import { AuthGuardService } from './auth-guard.service';
import { CenterAvailabilityComponent } from './center-availability/center-availability.component';
import { CityResolver } from './center-availability/center-availability.resolver';
import { CenterCalendarComponent } from './center-calendar/center-calendar.component';
import { CenterListComponent } from './center-list/center-list.component';
import { CenterComponent } from './center/center.component';
import { CompleteAccountComponent } from './complete-account/complete-account.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FilterComponent } from './filter/filter.component';
import { FireBaseActionComponent } from './fire-base-action/fire-base-action.component';
import { ForgotpwComponent } from './forgotpw/forgotpw.component';
import { HomeComponent } from './home/home.component';
import { JoinResaComponent } from './join-resa/join-resa.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { MailingListComponent } from './mailing-list/mailing-list.component';
import { MapComponent } from './map/map.component';
import { MoreDetailsComponent } from './more-details/more-details.component';
import { MyBookingsComponent } from './my-bookings/my-bookings.component';
import { MyFavoriteComponent } from './my-favorite/my-favorite.component';
import { MyPreferencesComponent } from './my-preferences/my-preferences.component';
import { OnBoardingComponent } from './on-boarding/on-boarding.component';
import { PaiementComponent } from './paiement/paiement.component';
import { ReservationComponent } from './reservation/reservation.component';
import { ResetpwformComponent } from './resetpwform/resetpwform.component';
import { SearchComponent } from './search/search.component';
import { SignupComponent } from './signup/signup.component';
import { SuccesResaComponent } from './succes-resa/succes-resa.component';

/* eslint-disable */
let routes: Routes = [
  { path: 'clubs-tennis', component: CenterListComponent, pathMatch: 'full' },
  { path: 'clubs-padel', component: CenterListComponent, pathMatch: 'full' },
  { path: 'clubs-badminton', component: CenterListComponent, pathMatch: 'full' },
  { path: 'clubs-squash', component: CenterListComponent, pathMatch: 'full' },
  { path: 'clubs-golf', component: CenterListComponent, pathMatch: 'full' },
  { path: 'feedback/:id', component: FeedbackComponent },
  {
    path: 'reservation-court-tennis', component: CenterAvailabilityComponent,
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-terrain-padel', component: CenterAvailabilityComponent,
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-terrain-squash', component: CenterAvailabilityComponent,
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-terrain-badminton', component: CenterAvailabilityComponent,
    resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  { path: 'form/:reservationToken', component: AllsessionformComponent },
  { path: 'form', component: AllsessionformComponent },
  { path: 'pwchangeform', component: ResetpwformComponent, pathMatch: 'full', data: { name: 'pwchangeform' } },
  { path: 'forgot-pw', component: ForgotpwComponent, pathMatch: 'full', data: { name: 'forgot-pw' } },
  { path: 'login', component: LoginComponent, pathMatch: 'full', data: { name: 'login' } },
  { path: 'logout', component: LogoutComponent, data: { name: 'logout' } },
  { path: 'sign-up', component: SignupComponent, pathMatch: 'full', data: { name: 'sign-up' } },
  { path: 'complete-sign-up', component: CompleteAccountComponent, pathMatch: 'full', data: { name: 'complete-sign-up' } },
  { path: 'search', component: SearchComponent, pathMatch: 'full', data: { name: 'search' } },
  { path: 'on-boarding', component: OnBoardingComponent, pathMatch: 'full' },
  { path: 'action', component: FireBaseActionComponent },
  { path: 'account', canActivate: [AuthGuardService], component: AccountComponent, data: { name: 'account', skipRouteLocalization: true },
    children: [
      { path: '', component: MyBookingsComponent },
      { path: 'favoris', component: MyFavoriteComponent },
      { path: 'preferences', component: MyPreferencesComponent },
    ]
  },
  { path: 'account/mailinglist', component: MailingListComponent, pathMatch: 'full' },
  { path: 'center', component: CenterComponent, pathMatch: 'full' },
  { path: ':id', component: CenterComponent },
  { path: ':id/reservation', component: CenterComponent, data: { activity: '', date: '' } },
  { path: ':id/reservation/:sport', component: CenterComponent, data: { activity: '', date: '' } },
  { path: ':id/booking', component: CenterComponent, data: { activity: '', date: '' }, pathMatch: 'full' },
  { path: ':id/booking/:sport', component: CenterComponent, data: { sport: '' }, pathMatch: 'full' },
  { path: ':id/reviews', component: CenterComponent },
  { path: 'centers/:id/:tab', component: CenterComponent },
  { path: 'filter', component: FilterComponent, pathMatch: 'full' },
  { path: 'more-details', component: MoreDetailsComponent, pathMatch: 'full' },
  { path: 'center-calendar', component: CenterCalendarComponent, pathMatch: 'full' },
  { path: 'reservation/:id', component: ReservationComponent },
  {
    path: 'reservation/:sport/map/:city', component: MapComponent,
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' },
    resolve: { city: CityResolver }
  },

  {
    path: 'reservation-court-tennis/:city', component: CenterAvailabilityComponent,
    resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-tennis-court-rental/:city', component: CenterAvailabilityComponent,
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-location-tennis/:city', component: CenterAvailabilityComponent,
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-location-tennis/:city', component: CenterAvailabilityComponent,
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  }, {
    path: 'reservation-terrain-foot/:city', component: CenterAvailabilityComponent,
    resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-terrain-padel/:city', component: CenterAvailabilityComponent,
    resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-padel-court-rental/:city', component: CenterAvailabilityComponent,
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-badminton-court-rental/:city', component: CenterAvailabilityComponent,
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-table-tennis-court-rental/:city', component: CenterAvailabilityComponent,
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-terrain-squash/:city', component: CenterAvailabilityComponent,
    resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-terrain-badminton/:city', component: CenterAvailabilityComponent,
    resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-session-fitness-detente/:city', component: CenterAvailabilityComponent, resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-session-fitness-aquatique/:city', component: CenterAvailabilityComponent, resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-session-fitness/:city', component: CenterAvailabilityComponent, resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-fitness/:city', component: CenterAvailabilityComponent, resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-session-fitness/:city', component: CenterAvailabilityComponent, resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-golf/:city', component: CenterAvailabilityComponent,
    resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-terrain-golf/:city', component: CenterAvailabilityComponent,
    resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-parcours-golf/:city', component: CenterAvailabilityComponent,
    resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-terrain-futsal/:city', component: CenterAvailabilityComponent,
    resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-terrain-petanque/:city', component: CenterAvailabilityComponent,
    resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '', servicetype: '', characteristic: '', price: '', partySize: '' }
  },
  {
    path: 'reservation-sport/:city', component: CenterAvailabilityComponent,
    resolve: { city: CityResolver },
    pathMatch: 'full', data: { activity: '', date: '' }
  },
  {
    path: 'checkout/:centerId/:serviceId', canActivate: [AuthGuardService], component: PaiementComponent,
    pathMatch: 'full', data: { name: 'reservationdetails', token: '' }
  },
  { path: 'checkout/complete', component: SuccesResaComponent, pathMatch: 'full' },
  {
    path: 'share/events/:token', canActivate: [AuthGuardService], component: JoinResaComponent, pathMatch: 'full'
  },
  { path: '**', redirectTo: '' },
];

routes.unshift({ path: '', component: HomeComponent, pathMatch: 'full' });

export function localizeLoaderFactory(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
  http: HttpClient,
  path?: string): ManualParserLoader {
  return new ManualParserLoader(translate,
    location,
    { ...settings, alwaysSetPrefix: false, useCachedLang: false, cacheMechanism: CacheMechanism.Cookie },
    ['fr', 'es', 'be', 'ch'],
    'ROUTES.');
}

@NgModule({
  imports: [
    TranslateModule.forRoot(),
    RouterModule.forRoot(routes, {}),
    LocalizeRouterModule.forRoot(routes,
      {
        parser: {
          provide: LocalizeParser,
          useFactory: (localizeLoaderFactory),
          deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
        },
      }
    )
  ],
  exports: [RouterModule, LocalizeRouterModule]
})
export class AppRoutingModule { }
