import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Feedback } from '../models/feedback';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  private headers = new HttpHeaders().set('Content-Type', 'application/json');
  private bookingUrl = environment.bookingApiUrl + '/v1';

  constructor(private http: HttpClient) { }

  create(feedback: Feedback): Observable<any> {

    return this.http.post(`${this.bookingUrl}/feedback/${feedback.id}`, JSON.stringify(feedback), { headers: this.headers });
  }

  getFeedback(id: string): Observable<Feedback> {
    return this.http.get<Feedback>(`${this.bookingUrl}/feedback/${id}`)
    .pipe(
      map(res => Feedback.fromJson(res))
    );
  }

}
