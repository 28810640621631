import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Reservation } from '../models/reservation';
import { ReservationsService } from '../service/reservations.service';
import { UserPreferenceService } from '../service/user-preferences.service';

enum Status {
  Fail = 'failed',
  Cancel = 'cancelled',
  Confirm = 'confirmed'
}
@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.scss']
})
export class MyBookingsComponent implements OnInit {
  @Input() isMobileResolution: boolean;

  // listReservation: any;
  currentDate: Date = new Date();
  dataAvailable = false;
  reservationsToCome: Reservation[] = [];
  pastReservations: Reservation[] = [];
  cancelable = true;
  notCancelableError: string;
  isError = false;
  isCancel = false;

  public activeNav = 1;
  public reservationSelected: any;
  public reservationSelectedData: any;
  public status = Status;
  public lang: string;

  constructor(
    private reservationsService: ReservationsService,
    private modalService: NgbModal,
    private userPreferenceService: UserPreferenceService,
    ) { }

    ngOnInit(): void {
      this.lang = this.userPreferenceService.getCountry();
      const reservations$ = this.getReservation();
      reservations$.subscribe(
        res => {
          this.filterData(res);
          this.dataAvailable = true;
        },
        error => {
          console.log(error);
          this.dataAvailable = true;
        }
      );
    }

    private getReservation(): Observable<any>{
      return this.reservationsService.getReservations();
  }

  private filterData(listReservations): void {
    for (const resa of listReservations) {
      if (this.compareDate(resa.start, this.currentDate) === -1) {
        this.pastReservations.push(resa);
      } else {
        this.reservationsToCome.push(resa);
      }
    }
    this.pastReservations.sort((resA, resB) => resA.start < resB.start ? 1 : -1);
    this.reservationsToCome.sort((resA, resB) => resA.start < resB.start ? 1 : -1);
  }


  // ------------------------------------------------
  // Utilitaires
  // ------------------------------------------------

  /**
   * Compares two Date objects and returns e number value that represents
   * the result:
   * 0 if the two dates are equal.
   * 1 if the first date is greater than second.
   * -1 if the first date is less than second.
   * @param date1 First date object to compare.
   * @param date2 Second date object to compare.
   */
  compareDate(date1: Date, date2: Date): number {
    const d1 = new Date(date1); const d2 = new Date(date2);


    // Check if the dates are equal
    const same = d1.getTime() === d2.getTime();
    if (same) { return 0; }

    // Check if the first is greater than second
    if (d1 > d2) { return 1; }

    // Check if the first is less than second
    if (d1 < d2) { return -1; }
  }

  public openConfirm(content, resa): void {
    this.reservationSelected = resa;
    this.reservationsService.getReservation(resa.id).subscribe(
      value => {
        this.reservationSelectedData = value;
        this.modalService.open(content, { centered: true, size: 'lg' });
      }
    )
  }

  public isStatusValid(status: string): boolean {
    if (status !== this.status.Cancel && status !== this.status.Fail) {
      return true;
    }
    return false;
  }

  public cancelReservation(resa, modal: NgbActiveModal): void {
    this.reservationsService.cancelReservation(resa.id).subscribe(
      (res) => {
        this.isCancel = true;
        setTimeout(() => {
          modal.close();
          window.location.reload();
        }, 2000);
      },
      (error) => {
        if (error.status > 400) {
          this.notCancelableError = error.error.message;
          this.isError = true;
        }
      }
    );
  }

}
