import { Component, OnInit } from '@angular/core';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserPreferenceService } from '../service/user-preferences.service';

@Component({
  selector: 'app-on-boarding',
  templateUrl: './on-boarding.component.html',
  styleUrls: ['./on-boarding.component.scss'],
})
export class OnBoardingComponent implements OnInit {
  onBoardingPage = true;
  ready = true;
  isMobileResolution = true; // utiliser pour le header
  isEspagnole = false;
  selectedImage = 'Argument_1.png';

  constructor(
      private deviceService: DeviceDetectorService,
      private userPreferenceService: UserPreferenceService
  ){ }

  ngOnInit(): void {
    if (window.innerWidth <= 768 || this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }

    if (this.userPreferenceService.getCountry() === 'es'){
      this.isEspagnole = true;
    }
  }

  changePicture(event: NgbPanelChangeEvent): void {
    switch (event.panelId) {
      case 'fonctionalite-1':
        this.selectedImage = 'Argument_1.png';
        break;
      case 'fonctionalite-2':
        this.selectedImage = 'Argument_2.png';
        break;
      case 'fonctionalite-3':
        this.selectedImage = 'Argument_3.png';
        break;
      case 'fonctionalite-4':
        this.selectedImage = 'Argument_4.png';
        break;
      case 'fonctionalite-5':
        this.selectedImage = 'Argument_5.png';
        break;
      case 'fonctionalite-6':
        this.selectedImage = 'Argument_6.png';
        break;
    }
  }

  help(): void {
    window.open('https://go.crisp.chat/chat/embed/?website_id=9b5110a7-ec05-4ef0-8d28-d9dad2348bd8', '_blank');
  }

  subscribe(): void {
    window.open('https://pro.anybuddyapp.com/inscription', '_blank');
  }
}
