import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Center } from '../../models/center';
import { CenterRatings } from '../../models/center-ratings';
import { CentersService } from '../../service/center.service';
import { SharedService } from '../../service/shared.service';
/* eslint-disable */
@Component({
  selector: 'app-reviews',
  templateUrl: './center-reviews.component.html',
  styleUrls: ['./center-reviews.component.scss']
})

export class CenterReviewsComponent implements OnInit, AfterViewInit {
  @Output() closeReviewPage = new EventEmitter<boolean>();
  @Input() center: Center;
  @Input() isMobileResolution: boolean;
  centerRatings: CenterRatings;
  loading: boolean;
  constructor(
    private sharedService: SharedService,
    @Inject(PLATFORM_ID) private platformId,
    private deviceService: DeviceDetectorService,
    private centersService: CentersService,
    private cdref: ChangeDetectorRef

  ) {
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
      });
  }
  ngOnInit(): void {
    this.loading = true;
    this.centersService.getCenterFeedbacks(this.center.id)
    .subscribe(
      res => {
        this.centerRatings = new CenterRatings (
          res.centerId,
          res.avg.clubRating,
          res.avg.clubRatingCount,
          res.avg.resourceRating,
          res.avg.resourceRatingCount,
          res.avg.serviceRating,
          res.avg.serviceRatingCount,
          res.avg.staffRating,
          res.avg.staffRatingCount,
          res.data
        );
        this.loading = false;

      },
      error => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  onCloseReviewPage(value: boolean): void {
    this.closeReviewPage.emit(value);
  }
  ngAfterViewInit(): void{
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 768 || this.deviceService.isMobile() || this.deviceService.isTablet() ){
        this.isMobileResolution = true;
      }
      else{
        this.isMobileResolution = false;
      }
    }
    this.cdref.detectChanges();
  }
}
