import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as cryptoJS from 'crypto-js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth.service';
import { AuthFirebaseService } from '../service/auth-firebase.service';
import { SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isMobileResolution = false;
  email: UntypedFormControl;
  password: UntypedFormControl;
  errorMessage: any;
  error = false;
  errorPassword = false;
  errorMail = false;
  code;
  state;
  persistConnexion = false;
  country = 'fr';
  language = '';
  errorAuthMsg = '';
  errorAuthMail = '';

  stepMail = true;
  stepPassword = false;
  stepVerificationMail = false;
  userMail = '';
  show = false;

  @ViewChild('downlodApp') downlodApp: TemplateRef<any>;
  @ViewChild('errorAuth') errorAuth: TemplateRef<any>;
  @ViewChild('linkMailSent') linkMailSent: TemplateRef<any>;
  @ViewChild('checkMail') checkMail: TemplateRef<any>;

  constructor(
    @Inject(PLATFORM_ID) private platform,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    public authService: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    private translate: TranslateService,
    private http: HttpClient,
    private userPreferenceService: UserPreferenceService,
    private modalService: NgbModal,
    private authFirebaseService: AuthFirebaseService,
    public afAuth: AngularFireAuth
  ) {
    if (this.authFirebaseService.getIsLoggedIn) {
      this.navigateEndLogin();
    }

    this.isMobileResolution =
      this.deviceService.isMobile() || this.deviceService.isTablet();
    this.route.queryParams.subscribe((params) => {
      this.code = params.code;
      this.state = params.state;
    });
    this.sharedService.changeEmitted$.subscribe((isMobileResolution) => {
      this.isMobileResolution = isMobileResolution;
    });
  }

  ngOnInit(): void {
    this.getResultSignUp();

    this.country = this.userPreferenceService.getCountry();
    if (this.country !== 'fr') {
      this.language = this.country;
    }
    this.userPreferenceService.getlocalChangeCountry().subscribe((value) => {
      this.country = value;
      if (this.country !== 'fr') {
        this.language = this.country;
      }
    });
    if (this.userPreferenceService.getCountry() === 'es') {
      this.router.navigate(['/es/login-decathlon']);
    }

    if (this.code && this.state) {
      this.getDecathlonAccessToken(this.code);
    }
    this.email = new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]);
    this.password = new UntypedFormControl('', [Validators.required]);
    this.isMobileResolution =
      this.deviceService.isMobile() || this.deviceService.isTablet();
  }

  continueWithMail(): void {
    if (this.isMailValid()) {
      this.stepMail = false;
      this.stepPassword = true;
      this.userMail = this.email.value;
    }
  }

  isMailValid(): boolean {
    this.email.markAsTouched();
    return this.email.valid;
  }

  isPasswordValid(): boolean {
    this.password.markAsTouched();
    return this.password.valid;
  }

  login(): void {
    if (this.isPasswordValid()) {
      this.error = false;
      this.errorMessage = null;

      this.authFirebaseService
        .SignIn(this.userMail, this.password.value)
        .then((result) => {
          result.user.getIdToken().then((token) => {
            this.authFirebaseService.isLoggedIn = true;
            //this.authFirebaseService.userData = result.user;
            this.authFirebaseService.token = token;
            this.navigateEndLogin();
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.error = true;
        });
    }
  }

  getDecathlonAccessToken(code: string): void {
    if (isPlatformBrowser(this.platform)) {
      const codeVerifier = sessionStorage.getItem('codeVerifier');
      sessionStorage.removeItem('codeVerifier');
      const payload = new HttpParams()
        .append('grant_type', 'authorization_code')
        .append('code', code)
        .append('code_verifier', codeVerifier)
        .append('redirect_uri', `${environment.host}/login`)
        .append('client_id', environment.clientId)
        .append('client_secret', environment.clientSecret);
      this.http
        .post(environment.accessToken, payload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .subscribe({
          next: (response) => {
            {
              const token = 'access_token';
              this.authService
                .authWithProvider('decathlon', response[token])
                .subscribe(
                  (res) => {
                    console.log(res);
                    console.log(res.body);
                    console.log(res.body.accessToken);
                    this.authFirebaseService
                      .signInWithCustomToken(res.body.accessToken)
                      .then((value) => {
                        this.authService.isLoggedIn = true;
                        this.navigateEndLogin();
                      })
                      .catch((error) => {
                        console.log(error);
                        this.error = true;
                        this.errorAuthMsg = error.message;
                        this.modalService.open(this.errorAuth);
                      });
                  },
                  (error) => {
                    console.log(error);
                    this.error = true;
                    if (error.status === 401) {
                      this.errorMessage =
                        this.translate.instant('login.error_login');
                    } else {
                      this.errorMessage = error.message;
                    }
                    this.errorAuthMsg = error.message;
                    this.modalService.open(this.errorAuth);
                  }
                );
            }
          },
          error: (err) => {
            this.error = true;
            this.errorMessage = err.error.error_description;
          },
          complete: () => {
            this.navigateEndLogin();
          },
        });
    }
  }

  private strRandom(length: number): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  goToDecathlonLoginPage(): void {
    const state = this.strRandom(43);
    const codeVerifier = this.strRandom(128);
    const codeVerifierHash = cryptoJS
      .SHA256(codeVerifier)
      .toString(cryptoJS.enc.Base64);
    const codeChallenge = codeVerifierHash
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
    const params = [
      'response_type=code',
      'state=' + state,
      'client_id=' + environment.clientId,
      'code_challenge=' + codeChallenge,
      'code_challenge_method=S256',
      'redirect_uri=' + environment.host + '/login',
    ];
    if (isPlatformBrowser(this.platform)) {
      sessionStorage.setItem('codeVerifier', codeVerifier);
      window.location.href = environment.authorize + '?' + params.join('&');
    }
  }

  downloadApp(): void {
    this.modalService.open(this.downlodApp, {
      centered: true,
      windowClass: 'customModalDownloadApp',
    });
  }

  public onCloseModal(modal: NgbActiveModal): void {
    modal.close();
  }

  signUpGoogle(): void {
    this.authFirebaseService
      .GoogleAuth()
      .then((value) => {
        console.log(value);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorMessage = error.message;
        // The email of the user's account used.
        console.log('google', errorMessage);
      });
  }

  signUpFb(): void {
    this.authFirebaseService
      .FacebookAuth()
      .then((value) => {
        console.log(value);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorMessage = error.message;
        // The email of the user's account used.
        console.log('signUpFb', errorMessage);
      });
  }

  signUpApple(): void {
    this.authFirebaseService
      .AppleAuth()
      .then((value) => {
        console.log(value);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorMessage = error.message;
        // The email of the user's account used.
        // The AuthCredential type that was used.
        console.log('signUpApple', errorMessage);
      });
  }

  getResultSignUp(): void {
    console.log('getResultSignUp');
    this.show = false;
    // Result from Redirect auth flow
    this.afAuth
      .getRedirectResult()
      .then((result) => {
        this.show = true;
      })
      .catch((error) => {
        this.show = true;
        // Handle Errors here.
        this.errorAuthMsg = error.message;
        if (error.customData) {
          this.errorAuthMail = error.customData.email;
        }
        this.modalService.open(this.errorAuth);
      });
  }

  navigateEndLogin(): void {
    if (this.authService.getRedirectUrl()) {
      const redirectUrl = this.authService.getRedirectUrl();
      this.authService.cleanRedirectUrl();
      this.router.navigate([redirectUrl]);
    } else {
      this.router.navigate(['/account']);
    }
  }

  goBackMail(): void {
    this.stepPassword = false;
    this.stepVerificationMail = false;
    this.stepMail = true;
  }

  sendMailLink(): void {
    this.errorAuthMsg = '';
    this.errorAuthMail = '';
    this.authFirebaseService
      .SignUpWithMail(this.userMail)
      .then(() => {
        window.localStorage.setItem('emailForSignIn', this.userMail);
        this.modalService.open(this.linkMailSent);
      })
      .catch((error) => {
        this.errorAuthMsg = error.message;
        this.modalService.open(this.errorAuth);
      });
  }
}
