<div class="d-flex flex-column mt-4 mx-4"
    [ngClass]="{'mt-4 mb-5': !isMobileResolution,  'mt-4 mb-3': isMobileResolution}">

    <h2 class="expansion-header card-title"
        [ngClass]="{'mt-5 mb-4': !isMobileResolution,  'mb-4': isMobileResolution}">
        {{'activity_details.activities' | translate}}
    </h2>
    <div>
        <div>
            <div class="d-flex flex-column mb-3">
                <div class="d-flex flex-column mb-4">
                    <ng-container *ngIf="sitesIds.length > 0 ; else infosFromCenter">
                        <div class="d-flex flex-column mt-5" *ngFor="let siteId of sitesIds; let index = index;">
                            <div class="d-flex flex-column mb-4">
                                <div class="activity">{{services[siteId].name| titlecase}}</div>
                                <div class="address">
                                    {{services[siteId].address.line1}}
                                    {{services[siteId].address.postalCode}}
                                    {{services[siteId].address.city}}
                                </div>
                                <div *ngFor="let activityId of activitiesIds;">
                                    <div *ngIf="services[siteId].res[activityId]" class="mt-2 row">
                                        <span class="sport">{{services[siteId].res[activityId].activity}}</span>
                                        <div *ngFor="let service of services[siteId].res[activityId].services"
                                            class="d-flex activity-details-txt grey-text mb-1 col-12 col-md-6">
                                            <div>
                                                <p class="me-1 mb-2">
                                                    <span class="mx-1"> {{service.name}} |</span>
                                                    <span class="mx-1" *ngFor="let characteristic of service.characteristics; let j = index;">
                                                        {{characteristic.name}}
                                                        <span *ngIf="service.characteristics.length - 1 > j"> | </span>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #infosFromCenter>
                        <div class="activity"> {{ services.name }} </div>
                        <div class="address">
                            {{services.address.line1}}
                            {{services.address.postalCode}}
                            {{services.address.city}}
                        </div>
                        <div class="d-flex flex-column mt-5" *ngFor="let activityId of activitiesIds;">
                          <span class="sport">{{ services.res[activityId].activity }}</span>
                          <div class="row">
                                <div *ngFor="let service of services.res[activityId].services;"
                                    class="d-flex activity-details-txt grey-text mb-1 col-12 col-md-6">
                                    <div>
                                        <p class="me-1 mb-2">
                                            <span class="mx-1"> {{service.name}} |</span>
                                            <span class="mx-1" *ngFor="let characteristic of service.characteristics; let j = index;">
                                                {{characteristic.name}}
                                                <span *ngIf="service.characteristics.length - 1 > j"> | </span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
