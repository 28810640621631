import {
  animate, animateChild,
  group,
  query, state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, PLATFORM_ID, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../auth.service';
import { UserPreferenceService } from '../service/user-preferences.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('openPanel', [
      transition('* => *', [
        group([
          query('@fadeIn', animateChild()),
          query('@slideIn', animateChild())
        ])
      ])
    ]),
    trigger('openMenu', [
      transition('* => *', [
        group([
          query('@slideTopIn', animateChild()),
          query('@slideVioletIn', animateChild()),
          query('@scaleIn', animateChild())
        ])
      ])
    ]),
    trigger('fadeIn', [
      transition(':enter', [style({ opacity: 0 }), animate(300)]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
      state('*', style({ opacity: 0.9 }))
    ]),
    trigger('slideIn', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86)')
      ]),
      transition(':leave', [
        animate(
          '0.6s cubic-bezier(0.075, 0.82, 0.165, 1)',
          style({ transform: 'translateX(100%)' })
        )
      ]),
      state('*', style({ transform: 'translateX(2%)' }))
    ]),
    trigger('slideTopIn', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86)')
      ]),
      transition(':leave', [
        animate(
          '0.6s cubic-bezier(0.075, 0.82, 0.165, 1)',
          style({ transform: 'translateY(-100%)' })
        )
      ]),
      state('*', style({ transform: 'translateY(0)' }))
    ]),
    trigger('slideVioletIn', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.4s 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86)')
      ]),
      state('*', style({ transform: 'translateX(0%)' }))
    ]),
    trigger('scaleIn', [
      transition(':enter', [
        style({ transform: 'scale(0) skewY(15deg)' }),
        animate('0.5s 0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86)')
      ]),
      state('*', style({ transform: 'scale(1) skewY(15deg)' }))
    ])
  ]
})
export class MenuComponent implements OnInit, OnChanges {
  isBrowser = false;
  isEspagnole = false;
  burgerStatus = false;
  @Output() newOpenMenu = new EventEmitter<boolean>();
  @Output() closeModal = new EventEmitter<boolean>();
  @Input() isMobileResolution: boolean;
  @Input() sport: boolean;
  @Input() city: boolean;
  @Input() goDownloadInfo: boolean;
  @Input() logoYellow = false;
  @Input() onBoardingPage: boolean;
  @ViewChild('downlodApp') downlodApp: TemplateRef<any>;

  country: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private userPreferenceService: UserPreferenceService,
    private router: Router,
    private modalService: NgbModal,
    public authService: AuthService,
    ) {
    this.isBrowser = isPlatformBrowser(this.platformId);

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.goDownloadInfo !== undefined && changes.goDownloadInfo.currentValue === true){
      this.telechargement();
    }
  }

  ngOnInit(): void  {
    this.country = '';
    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.country = this.userPreferenceService.getCountry();
    }
    if (this.userPreferenceService.getCountry() === 'es'){
      this.isEspagnole = true;
    }
  }

  clickBurger(): void {
    this.burgerStatus = !this.burgerStatus;
    if (this.isBrowser) {
      if (this.burgerStatus) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'inherit';
      }
    }
    this.newOpenMenu.emit(true);
  }

  telechargement(): void{
    this.modalService.open(this.downlodApp, { centered: true });
  }

  public onCloseModal(modal: NgbActiveModal): void {
    this.closeModal.emit(false);
    modal.close();
  }

  public onClickLogin(): void {
    this.router.navigate(['/login']);
  }

  public openLoginModal(content: TemplateRef<any>): void {
    this.modalService.open(content, { centered: true, size: 'lg', windowClass : 'customModalClass' });
  }
}
