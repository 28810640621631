import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from '../service/booking.service';

@Component({
  selector: 'app-info',
  templateUrl: './join-resa.component.html',
  styleUrls: ['./join-resa.component.scss'],
})
export class JoinResaComponent implements OnInit {
  error: string;
  finished = false;
  token: string;
  referal: string;
  constructor(
    private router: Router,
    private bookingService: BookingService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
          this.token = this.route.snapshot.paramMap.get('token')
          if(params.referralUserCode){
            this.referal = params.referralUserCode;
          }
          this.joinResa(this.token, this.referal)
        }
      );
  }

  joinResa(token: string, referal: string): void {
    this.bookingService.addParticipant(token, referal).subscribe(
      event => {
        this.finished = true;
        this.router.navigate(['/reservation/'+event.reservationId])

      },error => {
        this.finished = true;
        this.error = error.error?.message;
      }
    )
  }

  goHome(): void {
    this.router.navigate(['/'])
  }
}
