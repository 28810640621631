import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SharedService } from './service/shared.service';
import { UserPreferenceService } from './service/user-preferences.service';

declare let fbq: Function;
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'hello Anybuddy';
  deviceInfo = null;
  isMobileResolution: boolean;

  constructor(
    private router: Router,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private translate: TranslateService,
    private userPreferenceService: UserPreferenceService,
    @Inject(PLATFORM_ID) private platformId: Object,
    ) {
      if (isPlatformBrowser(this.platformId)) {
        this.router.events.subscribe((y: NavigationEnd) => {
          if (y instanceof NavigationEnd) {
            gtag('config', '345691414', { 'page_path': y.url });
            fbq('track', 'PageView');
          }
        });
      }

      this.translate.addLangs(['fr', 'es', 'nl', 'en', 'be','ch']);
      this.translate.setDefaultLang('fr');
      this.userPreferenceService.init();

      this.epicFunction();

      if (this.deviceService.isMobile() || this.deviceService.isTablet() ){
      this.isMobileResolution = true;
    }
    else{
      this.isMobileResolution = false;
    }
      this.sharedService.emitChange(this.isMobileResolution);
  }

  public ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.loadIubendaCookie();
    }
  }

  epicFunction(): void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  onResize(event): void{
    if (event.target.innerWidth <= 768 || this.deviceService.isMobile() || this.deviceService.isTablet() ){
      this.isMobileResolution = true;
    }
    else{
      this.isMobileResolution = false;
    }
    this.sharedService.emitChange(this.isMobileResolution);

  }

  onActivate(event): void {
    const scrollToTop = window.setInterval(() => {
        const pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16);
}

loadIubendaCookie() {
  const node1 = document.createElement('script');
  node1.type = 'text/javascript';
  node1.text = `var _iub = _iub || []; _iub.csConfiguration = {"consentOnContinuedBrowsing":false,"whitelabel":false,"lang":"${this.userPreferenceService.getLang()}","siteId":2271589,"cookiePolicyId":11677877,"enableCMP":true, "banner":{ "acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#0073CE","acceptButtonCaptionColor":"white","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D","rejectButtonDisplay":true,"rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","position":"float-top-center","textColor":"black","backgroundColor":"white" }};`;
  document.getElementsByTagName('head')[0].appendChild(node1);

  const node2 = document.createElement('script');
  node2.src = '//cdn.iubenda.com/cs/tcf/stub-v2.js';
  node2.type = 'text/javascript';
  document.getElementsByTagName('head')[0].appendChild(node1);

  const node3 = document.createElement('script');
  node3.src = '//cdn.iubenda.com/cs/iubenda_cs.js';
  node3.type = 'text/javascript';
  node3.async = true;
  document.getElementsByTagName('head')[0].appendChild(node3);
}

}
