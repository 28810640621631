<div class="d-flex flex-column" *ngIf="sportDataAvailable && (pageClub || pageReservation)" [ngClass]="{'card-box': pageClub && !isMobileResolution}">
    <div class="d-flex flex-column" [ngClass]="{'mt-4 mb-5': !isMobileResolution,  'mt-4 mb-0': isMobileResolution}">
        <h2 class="card-title-sport mt-4 mx-4 mb-1" *ngIf="pageClub">{{ 'sport.available_sports' | translate }}</h2>
        <h2 class="card-title mt-5 mx-4 mb-4" *ngIf="pageReservation">{{ 'sport.choose_sport' | translate }}</h2>
        <div class="container-fluid sports-div" [ngClass]="{'mt-4': !isMobileResolution, 'sports-scroll-div':!lessThanFourSports, 'sports-div-home': '!pageClub', 'sports-div-home': '!pageReservation' }">
            <div class="d-flex align-items-center pb-4"  *ngIf="sportsToDisplay.length === 0">
                <h3 class="caption-sport-title align-self-center">
                    {{ 'sport.no_sport' | translate }}
                </h3>
            </div>
            <div *ngIf="sportsToDisplay.length !== 0">
                <div class="desktop-carousel">
                    <a *ngFor="let sport of sportsToDisplay; let i = index"
                        [routerLink]="['/' + url + '/' + sport.id]"
                        queryParamsHandling="merge"
                        class="sport-container"
                        (click)="onSportSelected(sport.id)">
                        <img class="sport"
                            loading="lazy"
                            [src]="sport.icon"
                            alt="{{ 'sports.' + sport.activity | translate }}"
                            [ngClass]="sportSelect === sport.id ? 'selected-sport-img-desktop' : ''">
                        <h3 class="caption-sport-title mt-2" [ngClass]="sportSelect === sport.id ? 'bold' : 'regular'">
                            {{ sport.name }}
                        </h3>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="!pageClub && !pageReservation">
    <div class="d-flex justify-content-center mb-5 swipe sport-carousel"
        [ngClass]="{'mt-4': isMobileResolution, 'mt-5': !isMobileResolution}" id="carousel"
        (swipeleft)="swipe($event.type)" (swiperight)="swipe($event.type)" *ngIf="sportDataAvailable">

        <div *ngIf="isMobileResolution; else sportsCarouselDesktop">
            <div class="row" *ngIf="lessThanFourSports">
                <ng-container *ngFor="let sport of sportsToDisplay; let i = index">

                    <a [routerLink]="['/' + country + '/' + translatedPath + '/' + slug]"
                        class="d-flex flex-column justify-content-center" (mouseover)="sportHover(sport.id)">
                        <div >
                            <img class="sport-img" loading="lazy" [src]="sport.icon"
                                alt="{{ 'sports.' + sport.activity | translate }}">
                        </div>
                        <h3 class="caption-sport-title" [ngClass]="selectedSport === sport.activity ? 'bold' : 'regular'">
                            {{ sport.name }}
                        </h3>
                    </a>
                </ng-container>
            </div>

            <div *ngIf="!lessThanFourSports">
                <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true">
                    <ng-template ngbSlide *ngFor="let sport of sportsToDisplay; let i = index">
                        <div class="carousel-wrapper">
                        <a [routerLink]="['/' + country + '/' + translatedPath + '/' + slug]"
                            class="sport-img sport-img-grey" (mouseover)='sportHover(sportsToDisplay[(i)%sportsToDisplay.length].id)'>
                            <img class="sport-img sport-img-grey" loading="lazy"
                            [src]="sportsToDisplay[i%sportsToDisplay.length].icon"
                            alt="{{ 'sports.' + sportsToDisplay[i%sportsToDisplay.length].sport | translate }}">
                            <h3 class="caption-sport-title">
                                {{  sportsToDisplay[(i)%sportsToDisplay.length].name}}
                            </h3>
                        </a>
                        <a [routerLink]="['/' + country + '/' + translatedPath + '/' + slug]"
                            class="sport-img sport-img-grey" (mouseover)="sportHover(sportsToDisplay[(i+1)%sportsToDisplay.length].id)">
                            <img class="sport-img" loading="lazy"
                                [src]="sportsToDisplay[(i+1)%sportsToDisplay.length].icon"
                                alt="{{ 'sports.' + sportsToDisplay[(i+1)%sportsToDisplay.length].sport | translate }}">
                            <h3 class="caption-sport-title">
                                {{  sportsToDisplay[(i+1)%sportsToDisplay.length].name}}
                            </h3>
                        </a>
                        <a [routerLink]="['/' + country + '/' + translatedPath + '/' + slug]"
                            class="sport-img sport-img-grey" (mouseover)="sportHover(sportsToDisplay[(i+2)%sportsToDisplay.length].id)">
                            <img class="sport-img sport-img-grey" loading="lazy"
                                [src]="sportsToDisplay[(i+2)%sportsToDisplay.length].icon"
                                alt="{{ 'sports.' + sportsToDisplay[(i+2)%sportsToDisplay.length].sport | translate }}">
                            <h3 class="caption-sport-title">
                                {{  sportsToDisplay[(i+2)%sportsToDisplay.length].name}}
                            </h3>
                        </a>
                        </div>
                    </ng-template>
                </ngb-carousel>
            </div>
        </div>

        <ng-template #sportsCarouselDesktop>
            <div class="row sports-div-home">
                <ng-container *ngFor="let sport of sportsToDisplay; let i = index">
                    <a [routerLink]="['/' + country + '/' + translatedPath + '/' + slug]"
                        class="d-flex flex-column justify-content-center" (mouseover)='sportHover(sport.id)' (click)="onSportSelected(sport.id)">
                        <div class="align-self-center">
                            <img class="sport-img-home align-self-center" loading="lazy" [src]="sport.icon"
                                alt="{{ 'sports.' + sport.activity | translate }}">
                        </div>
                        <h3 class="caption-sport-title-home align-self-center">
                            {{ 'sports.' +  sport.id| lowercase| translate  }}
                        </h3>
                    </a>
                </ng-container>
            </div>
        </ng-template>
    </div>
</ng-container>
