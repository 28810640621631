export class Post {
  /* eslint-disable @typescript-eslint/dot-notation */
  constructor(
      public title: string,
      public content: string
    ) { }

    public static fromJson(json: object): Post {
      return new Post(
        json['title']['rendered'],
        json['content']['rendered']
      );
    }
    /* eslint-enable @typescript-eslint/dot-notation */
}
