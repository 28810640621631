import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import {
  AfterViewInit, Component,
  ElementRef, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth.service';
import { SearchService } from '../service/search.service';
import { SeoService } from '../service/seo.service';
import { SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import { TopNavBarComponent } from '../top-navbar/top-navbar.component';
/* eslint-disable */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  displayPopUp = true;
  isMobileResolution = false;
  isPopupMobile = false;
  isScroll = true;
  country = 'fr';
  isBrowser: boolean;
  searchImgSrc = '/assets/images/search-2-line.png';
  selectedFilter = '';
  querySearch = '';
  displaySearchPage = false;
  displayMobileSearchPage = false;
  ipaddress = '';
  latitude = '';
  longitude = '';
  currency = '';
  currencysymbol = '';
  isp = '';
  city = '';
  Placeholder = '';
  addressSearch = 'paris-75000';
  language = '';
  homePage = true;
  goDownloadInfo: boolean = false;
  slug: string;

  @ViewChild(TopNavBarComponent, { static: false }) public navbar: TopNavBarComponent;

  private subs: Subscription[] = [];

  @ViewChild('banner') banner: ElementRef;
  @ViewChild('phone') phone: ElementRef;
  @ViewChild('downlodApp') downlodApp: TemplateRef<any>;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private deviceService: DeviceDetectorService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
    private sharedService: SharedService,
    private searchService: SearchService,
    private userPreferenceService: UserPreferenceService,
    public authService: AuthService,
    private seo: SeoService,
    private modalService: NgbModal,
    private location: Location,
  ) {
    this.isPopupMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
        this.isPopupMobile = isMobileResolution;
      });

  }

  ngOnInit(): void {
    this.country = this.userPreferenceService.getCountry();
    if (this.country !== 'fr') {
      this.location.replaceState('/' + this.country)
      this.language = this.country
    } else {
      this.location.replaceState('/')
    }
    let sub: Subscription = this.userPreferenceService.getlocalChangeCountry().subscribe(
      value => {
      this.country = value;
      if (this.country !== 'fr') {
        this.language = this.country
      }
      this.setSlug();
    })
    this.subs.push(sub);
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('scroll', this.scroll, true);
    }
  }

  ngAfterViewInit(): void {
    this.backgroud();
    if (this.isBrowser) {
      this.locate();
      window.addEventListener('scroll', this.scroll, true);
      if (window.innerWidth <= 768 || this.deviceService.isMobile() || this.deviceService.isTablet()) {
        this.isMobileResolution = true;
      }
      else {
        this.isMobileResolution = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => {
      sub.unsubscribe();
    })
  }

  switchAddressByCountry(): string {
    let address: string;
    switch(this.country) {
      case 'es':
        address ='huelva-21001';
        break;
      case 'fr':
        address = 'paris-75000';
        break;
      case 'be':
        address = 'bruxelles-1000';
        break;
      case 'ch':
        address = 'geneve-1200';
        break;
      default:
        address = 'paris-75000';
    }
    return address;
  }

  setSlug(): void {
    this.addressSearch = this.switchAddressByCountry();
    if (this.addressSearch === 'paris-75000') {
      this.slug = this.addressSearch;
    }
    else {
      this.slug = this.addressSearch.substring(0, this.addressSearch.length - 3);
    }
    this.locate();
    this.Placeholder = this.translate.instant('homeComponent.placeholder_form');

    let url = environment.host;
    if(this.country != 'fr') {
      url = `${url}/${this.country}`;
    }
    this.seo.generateTags({
      title: this.translate.instant('homeComponent.meta_title'),
      description: this.translate.instant('homeComponent.meta_title'),
      url: url
    });
  }

  scroll = (event: any): void => {
    if (event.srcElement.scrollTop >= 700 && this.isMobileResolution === false) {
      this.isScroll = false;
    }
    else if (event.srcElement.scrollTop <= 625 && this.isMobileResolution === false) {
      this.isScroll = true;
    }
  }

  installApplication(): void {
    const deviceInfo = this.deviceService.getDeviceInfo();
    if (deviceInfo.os.toUpperCase() === 'ANDROID') {
      this.document.location.href = 'https://play.google.com/store/apps/details?id=com.anybuddyapp.anybuddy&hl=fr&gl=US';
    }
    else if (deviceInfo.os.toUpperCase() === 'IOS') {
      this.document.location.href = 'https://apps.apple.com/fr/app/anybuddy/id1159088112';
    }
    else {
      alert(this.translate.instant('store.errorMsg'));
    }
  }

  focusOutInput(value): void {
    if (value.length === 0) {
      this.Placeholder = this.translate.instant('homeComponent.placeholder_form');
    }
  }

  onOpenFilters(value): void {
    console.log('filter')
    console.log(this.isMobileResolution)
    this.Placeholder = '';
    if (!this.isMobileResolution) {
      if (value.length >= 3) {
        this.querySearch = value;
        this.displaySearchPage = true;
      }
      else {
        this.displaySearchPage = false;
      }
    } else {
      this.displayMobileSearchPage = true;
    }
  }

  hiddenDivSearch(): void {
    this.displaySearchPage = false;
  }

  getResolution(event: boolean): void {
    this.isMobileResolution = true;
  }

  public locate(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const post = position.coords.latitude + ',' + position.coords.longitude;
            this.searchService.getGeoname(post).subscribe(res => {
              this.addressSearch = res['slug'];
            })
          },
          error => {
            this.addressSearch =  this.switchAddressByCountry()
            console.log('Error code: ' + error.code + '<br /> Error message: ' + error.message);
          }
        );
      }
      else {
        this.addressSearch =  this.switchAddressByCountry()
      }
    }
  }

  goClub(target): void {
    if (this.isBrowser) {
      window.location.href = target
    }
  }

  goDownload() {
    this.goDownloadInfo = true
  }

  downloadApp(): void{
    this.navbar.sidebarShow = false;
    this.modalService.open(this.downlodApp, { centered: true, windowClass : 'customModalDownloadApp' });
  }

  public onCloseModal(modal: NgbActiveModal): void {
    modal.close();
  }

  backgroud() {
    let imgCount;
    let images;
    const dir = 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/home/';
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      imgCount = 4;
      images = ['Padel_squash.png', 'Squash_tennis.png', 'Tennis_Bad.png', 'bad.png'];
    } else {
      imgCount = 2;
      images = ['Tennis_Bad_DESKTOP.png', 'Padel_Squash_DESKTOP.png'];
    }
    const randomCount = (Math.floor(Math.random() * imgCount));

    this.banner.nativeElement.setAttribute('style', 'background-image: url(' + dir + images[randomCount] + ')');
  }

  help() {
    window.open("https://go.crisp.chat/chat/embed/?website_id=9b5110a7-ec05-4ef0-8d28-d9dad2348bd8",  '_blank')
  }

  becomePartner() {
    this.router.navigate(['/on-boarding'])
  }

  cardOffer() {
    window.open("https://www.anybuddyapp.com/carte-cadeau-anybuddy-sport/",  '_blank')
  }
}
