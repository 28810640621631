import { Component, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Amenity } from '../../models/amenities';
import { SharedService } from '../../service/shared.service';

@Component({
  selector: 'app-amenities',
  templateUrl: './amenities.component.html',
  styleUrls: ['./amenities.component.css']
})
export class AmenitiesComponent {
  @Input() isMobileResolution = false;
  @Input() amenities: Amenity[] = [];

  constructor(
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService
  ) {
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
      });
  }

}
