import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewChecked, AfterViewInit,
  ChangeDetectorRef, Component, ElementRef, Inject, OnInit, PLATFORM_ID,
  SimpleChanges, TemplateRef, ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { MapComponent } from '../map/map.component';
import { Activity } from '../models/activity';
import { Address } from '../models/address';
import { Center } from '../models/center';
import { Chracteristics } from '../models/characteristic';
import { InfoPaiement } from '../models/infoPaiment';
import { Post } from '../models/post';
import { Service } from '../models/service';
import { ServiceInformation } from '../models/serviceInformation';
import { MoreDetailsComponent } from '../more-details/more-details.component';
import { TypeGround } from '../search/search.component';
import { ActivityService } from '../service/activity.service';
import { AvailabilitiesService } from '../service/availabilities.service';
import { CentersService } from '../service/center.service';
import { CityService } from '../service/city.service';
import { DatetimeService, FormatDate } from '../service/datetime.service';
import { FavoriteService } from '../service/favorite.service';
import { SeoService } from '../service/seo.service';
import { CenterAvailabilitiesService, InfoPaiementService, SelectedSportService, SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import { WordPressService } from '../service/wordpress.service';
export let browserRefresh = false;

/* eslint-disable */
@Component({
  selector: 'app-center-availability',
  templateUrl: './center-availability.component.html',
  styleUrls: ['./center-availability.component.scss'],
})

export class CenterAvailabilityComponent implements OnInit, AfterViewInit, AfterViewChecked {
  public filterObject = []
  public filtercharacteristic = [];
  public imageSport = '';
  public serviceTypeObject;
  public isMobileResolution: boolean;
  public city: string = '';
  public town: string;
  public formatCity: string;
  public country: string;
  public sportSelected: string;
  public activeNav: number;
  public data$: Observable<any>;
  public data: Center[] = [];
  public coords = '48.8534,2.3488';
  public date: string = '';
  public mapReady: boolean = false;
  public numberFilters: number = 0;
  public filter: string[] = [];
  public filterChamps = [];
  public characteristicFilter = "";
  public serviceTypesFilter = "";
  public PriceFilter = "";
  public reduceFilter = "";
  public serviceTypeInfo = '';
  public infoSelected = '';
  public closeResult: string;
  public loading = false;
  public hasSport = true;
  public activies = []
  public listActivity: Activity[] = []
  public charachterics = ""
  public price = ""
  public valuePrice = "";
  public servicetype = ""
  public radius = ""
  public avaibility = true;
  public textHeader: string;
  public textFooter: string;
  public noti;
  public frLanguage = true;
  public noResult = false;
  public cityCodePostale: string;
  public dateSearch = '';
  public today = new Date().toISOString().slice(0, 10);
  public dateDisplay: string;
  public heureActuel = new Date().toLocaleTimeString('en-US', {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric'
  });
  public isBrowser = false;
  public infoSportCarrosel = ''
  private sportImageUrl = {
    padel: `${environment.imageCDN}v1562586228/reserver-terrain-padel.jpg`,
    tennis: `${environment.imageCDN}v1562586228/reserver-terrain-tennis.jpg`,
    squash: `${environment.imageCDN}v1562586227/reserver-terrain-squash.jpg`,
    badminton: `${environment.imageCDN}v1562586227/reserver-terrain-badminton.jpg`
  }
  private typeGround = TypeGround;
  private pathUrl: string;
  private subs: Subscription[] = [];
  private sub: Subscription;
  private favoriteIndexedObj = {};
  public sportTypeList: any[];
  public sportTypeSelected: string[];
  public sportTypeSelectedString: String;
  public isScrollableSport = false;
  public isScrollableTypeSport = false;
  public activeSession: number;
  public sessionListSport: any[];
  public isPartner: boolean = true;
  public initial: ServiceInformation;
  public servPrice: ServiceInformation;
  public infoPaiement: InfoPaiement;
  public isSession: boolean;
  public pagination: any;
  public isPagination: boolean;
  public paginationCount: number = 1;
  public userScroll: number;
  public dataList: any = [];
  public mapDatalist: any = [];
  public sourceFromMap: boolean = false;
  selectedCountry: any;

  @ViewChild('sportSlider', { static: false }) sportSlider;
  @ViewChild('typeSportSlider', { static: false }) typeSportSlider;

  @ViewChild('filterContent') filterContent: TemplateRef<any>;
  @ViewChild('Sport') Sport: TemplateRef<any>;
  @ViewChild('cityModal') cityModal: TemplateRef<any>;
  @ViewChild('top', { static: false }) container: ElementRef;
  @ViewChild('sessionDescription') descriptionModal: TemplateRef<any>;
  @ViewChild('backTopContainer', { static: false }) backTopContainer: ElementRef;
  @ViewChild('centerContainer', { static: false }) centerContainer: ElementRef;
  @ViewChild(MapComponent) mapContainer: MapComponent;

  hasInfrasctructures = false;
  infrastructures = [];
  infrastructuresSelected = [];

  constructor(
    private translate: TranslateService,
    private centerAvailabilitiesService: AvailabilitiesService,
    private selectedSportService: SelectedSportService,
    private sharedService: SharedService,
    private deviceService: DeviceDetectorService,
    private modalService: NgbModal,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private activityService: ActivityService,
    private seo: SeoService,
    @Inject(PLATFORM_ID) private platformId,
    private wordpressService: WordPressService,
    private localize: LocalizeRouterService,
    private datetimeService: DatetimeService,
    private sharedCenterAvailabilities: CenterAvailabilitiesService,
    private cityService: CityService,
    private userPreferenceService: UserPreferenceService,
    private favoriteService: FavoriteService,
    public infoPaiementService: InfoPaiementService,
    private centerService: CentersService,
  ) {
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe(
      (isMobileResolution: boolean) => {
        this.isMobileResolution = isMobileResolution;
      });
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
      if (window.innerWidth <= 768 || this.deviceService.isMobile() || this.deviceService.isTablet()) {
        this.isMobileResolution = true;
      }
      else {
        this.isMobileResolution = false;
      }

    }
  }

  ngAfterViewChecked(): void {
    if (this.sportSlider) {
      if (this.sportSlider.nativeElement.scrollWidth > this.sportSlider.nativeElement.clientWidth) {
        this.isScrollableSport = true;
      } else {
        this.isScrollableSport = false;
      }
    }
    if (this.typeSportSlider) {
      if (this.typeSportSlider.nativeElement.scrollWidth > this.typeSportSlider.nativeElement.clientWidth) {
        this.isScrollableTypeSport = true;
      } else {
        this.isScrollableTypeSport = false;
      }
    }
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.userPreferenceService.getChoosedCountry().subscribe(value => {
      this.selectedCountry = value;
    });
    this.country = '';

    if (this.activateRoute.snapshot.data['city'] === undefined) {
      this.router.navigate([`${this.userPreferenceService.getCountry()}/${this.activateRoute.snapshot.url[0]}/${this.getCity()}`])
    }

    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.country = this.userPreferenceService.getCountry();
      this.frLanguage = false
    }

    if (this.activateRoute.snapshot.url.length > 0) {
      this.pathUrl = this.activateRoute.snapshot.url[0].path;
      if (this.pathUrl) {
        this.infoSelected = this.pathUrl.split('-')[2]
      }
    }

    if (this.activateRoute.snapshot.data['city'] === undefined) {
      if (this.infoSelected) {
        this.router.navigate([`${this.userPreferenceService.getCountry()}/${this.activateRoute.snapshot.url[0]}/${this.getCity()}`], { queryParams: { activity: this.infoSelected } })
      } else {
        this.router.navigate([`${this.userPreferenceService.getCountry()}/${this.activateRoute.snapshot.url[0]}/${this.getCity()}`])
      }
    }
    if (this.activateRoute.snapshot.data['city'] !== undefined) {
      this.city = this.activateRoute.snapshot.data['city'].slug;
      this.cityCodePostale = this.activateRoute.snapshot.data['city'].cityCodePostale
      const slug = this.activateRoute.snapshot.paramMap.get('city');
      this.town = this.cityService.formatCity(slug);
    }
    this.formatCity = this.cityService.formatCity(this.city);
    this.date = this.activateRoute.snapshot.queryParams['date'];
    this.initDateToDisplay(this.date)

    if (this.pathUrl === "reservation-sport" || this.pathUrl === "reserva-sport") {
      this.hasSport = false;
      this.infoSelected = "sport";
      this.mapReady = true;
    }
    if (this.activateRoute.snapshot.queryParams['servicetype'] !== undefined && this.activateRoute.snapshot.queryParams['servicetype'] !== null &&
      this.activateRoute.snapshot.queryParams['servicetype'] !== "") {
      this.servicetype = this.activateRoute.snapshot.queryParams['servicetype'];
    }
    if (this.activateRoute.snapshot.queryParams['characteristic'] !== undefined && this.activateRoute.snapshot.queryParams['characteristic'] !== null &&
      this.activateRoute.snapshot.queryParams['characteristic'] !== "") {
      this.charachterics = this.activateRoute.snapshot.queryParams['characteristic'];
      this.numberFilters = this.charachterics.split(',').length;
    }
    if (this.activateRoute.snapshot.queryParams['searchPrice'] !== undefined && this.activateRoute.snapshot.queryParams['price'] !== null &&
      this.activateRoute.snapshot.queryParams['searchPrice'] !== "") {
      this.price = "" + this.activateRoute.snapshot.queryParams['searchPrice'] / 100;
      this.valuePrice = this.activateRoute.snapshot.queryParams['searchPrice']
      this.numberFilters++;
    }
    if (this.activateRoute.snapshot.queryParams['radius'] !== undefined &&
      this.activateRoute.snapshot.queryParams['radius'] !== null &&
      this.activateRoute.snapshot.queryParams['radius'] !== "") {
      this.radius = this.activateRoute.snapshot.queryParams['radius'];
      if(this.radius != null && this.radius != ''){
        String((Number(this.radius)* 1.5));
      }
      this.numberFilters++;
    }
    if (this.activateRoute.snapshot.queryParams['isPartner']) {
      this.isPartner = JSON.parse(this.activateRoute.snapshot.queryParams['isPartner']);
      if (this.activateRoute.snapshot.queryParams['isPartner'] === "false") {
        this.numberFilters++;
      }
    }
    if (this.pathUrl !== "reservation-sport" && this.pathUrl !== "reserva-sport") {
      this.sportSelected = this.translate.instant('keySport.' + this.infoSelected);
      this.infoSportCarrosel = this.translate.instant('keySport.' + this.infoSelected);
      if ((this.sportSelected !== 'fitness') && (this.sportSelected !== 'tennis')) {
        this.activeSession = 0;
      } else {
        this.activeSession = 1;
      }
      if ((this.sportSelected === 'tennis' && this.servicetype === '') || (this.servicetype === 'tennis-court-rental')) {
        this.activeSession = 1;
        this.numberFilters++;
      }
      if (this.sportSelected === 'tennis' && this.servicetype === 'tennis-lessons') {
        this.activeSession = 2;
        this.numberFilters++;
      }
      if (this.activeSession === 0 || this.sportSelected === 'tennis') {
        this.getCentersAvailabilities(this.city, this.translate.instant('keySport.' + this.infoSelected), this.serviceTypeInfo, this.date, this.servicetype, this.charachterics, this.valuePrice, this.radius, this.isPartner);
      }
    } else {
      this.loading = true;
    }
    this.getSportByActivity();
    this.activateRoute.queryParams.subscribe(
      (queries) => {
        if (queries['radius']) {
          this.radius = queries['radius'];
          if(this.radius != null && this.radius != ''){
            String((Number(this.radius)* 1.5));
          }
        }
      }
    );
    this.getFilter();
    if (this.infoSelected !== "sport") {
      const wordpressHeader$ = this.getWordpressHeader(this.town);
      const wordpressFooter$ = this.getWordpressFooter(this.town);
      this.wordpressService.loadPosts('intro', this.infoSelected, this.town).subscribe(
        value => {
          this.textHeader = value[0].content;
        }, error => {
          console.log(error)
        }
      )
      const subsHeader: Subscription = wordpressHeader$.subscribe(
        (header: string) => {
          this.textHeader = header;
        },
        (error: any) => {
          console.error(error)
          const title = this.translate.instant('availabilities.reservation1', { sport: this.infoSelected, city: this.town });
          const fullCurrentUrl: string = `https://www.anybuddyapp.com${this.router.url}`;
          this.seo.generateTags({
            title: title,
            description: this.translate.instant('availabilities.reservation', { sport: this.infoSelected.toUpperCase(), city: this.town }),
            url: fullCurrentUrl,
            image: this.sportImageUrl[this.infoSelected]
          });
        }
      )
      const subsFooter: Subscription = wordpressFooter$.subscribe(
        (footer: string) => {
          this.textFooter = footer;
        },
        (error: any) => {
          console.error(error)
        }
      )
      this.subs.push(subsHeader, subsFooter)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 768 || this.deviceService.isMobile() || this.deviceService.isTablet()) {
        this.isMobileResolution = true;
      }
      else {
        this.isMobileResolution = false;
      }

    }

    this.sub = this.sharedCenterAvailabilities.changeSearchEmitted$.subscribe(
      (res) => {
          let sportTypeString;
          if (this.sportTypeSelected === undefined || (this.sportTypeSelected && this.sportTypeSelected.indexOf('all') !== -1)) {
            sportTypeString = this.translate.instant('keySport.' + this.infoSelected);
          } else {
            sportTypeString = this.sportTypeSelected.join();
          }
          this.loading = false;
          this.getCentersAvailabilities(this.city, sportTypeString, this.serviceTypeInfo, this.date, this.servicetype, this.charachterics, this.valuePrice, this.radius, this.isPartner, false)
        this.sourceFromMap = true;
      }
    );

    // this.activateRoute.queryParams.pipe(skip(1)).subscribe(res => {
    //   console.log(res)
    //   if (res && res.date) {
    //     console.log('ng after view init')
    //     this.getCentersAvailabilities(this.city, this.translate.instant('keySport.' + this.infoSelected), this.serviceTypeInfo, res.date, this.servicetype, this.charachterics, this.price, this.radius, this.isPartner);
    //   }
    // })
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  private initDateToDisplay(date: string): void {
    let day: string;
    if (date) {
      day = date;
    } else {
      day = new Date().toISOString();
    }
    this.dateDisplay = this.datetimeService.formatDateDisplay(day.split('T')[0], this.userPreferenceService.getLocale(), FormatDate.full)
  }

  widthSize(): void {
    if (this.sportSlider) {
      if (this.sportSlider.nativeElement.scrollWidth > this.sportSlider.nativeElement.clientWidth) {
        this.isScrollableSport = true;
      } else {
        this.isScrollableSport = false;
      }
    }
    if (this.typeSportSlider) {
      if (this.typeSportSlider.nativeElement.scrollWidth > this.typeSportSlider.nativeElement.clientWidth) {
        this.isScrollableTypeSport = true;
      } else {
        this.isScrollableTypeSport = false;
      }
    }
  }

  getCentersAvailabilities(city, sport, serviceType, dateInfo, service, charachterics, price, radius, isPartner, refreshMap = true): void {
    if (dateInfo !== undefined) {
      if (dateInfo.length === 16) {
        this.today = dateInfo.slice(0, 10);
        this.heureActuel = dateInfo.slice(11, dateInfo.length);
      }
      else {
        if (dateInfo.length >= 11 && dateInfo.length < 16) {
          this.today = dateInfo.slice(0, 10);
        }
        else if (dateInfo.length === 10) {
          this.today = dateInfo;
        }
      }
    }

    if (this.activeSession === 2 || this.sportSelected === 'fitness') {
      this.isSession = true;
    } else {
      this.isSession = false;
    }
    this.dataList = [];
    this.mapReady = false
    const centerAvailabilities$ = this.centerAvailabilitiesService.getCentersAvailabilities(city, sport, serviceType, dateInfo, service, charachterics, price, radius, isPartner, this.isSession).subscribe(
      (availabilities) => {
        this.data = availabilities.data;
        this.sourceFromMap = false;
        this.dataList = [...this.dataList, ...availabilities.data]
        // this.sharedCenterAvailabilities.emitChange(this.dataList);
        this.pagination = availabilities['paging'];
        this.initSessionList();
        this.initfavorites(this.data);
        this.data = this.data.filter(center => center.address.country == this.selectedCountry.countryCode);
        if (this.hasData()) {
          this.noResult = true;
        }
        if(refreshMap == false){
          this.mapReady = true;
        }
        this.loading = true;
        this.sharedCenterAvailabilities.setIsFromFilter(false);
      },
      (err) => {
        this.loading = true;
      }
    );
    this.subs.push(centerAvailabilities$);
    if(refreshMap){
      const centerMap$ = this.centerAvailabilitiesService.getCentersMap(city, sport, serviceType, dateInfo, service, charachterics, price, radius, isPartner, this.isSession).subscribe(
        value => {
          this.sourceFromMap = false;
          this.mapDatalist = [ ...value]
          this.sharedCenterAvailabilities.emitChange(this.mapDatalist);
          this.mapReady = true;
        },error => {
          this.mapReady = true;
        }
      )
      this.subs.push(centerMap$);
    }
  }

  initfavorites(data) {
    if (data !== undefined) {
      this.favoriteIndexedObj = {};
      data.forEach(center => {
        this.favoriteIndexedObj[center.id] = this.favoriteService.isFavorite(center.id);
      });
    }
  }

  public isMapReady(mapReady: boolean): void {
    this.mapReady = mapReady;
    if (this.container) {
      this.container.nativeElement.scrollIntoView();
    }
  }

  public newSportSelected(sport: string): void {
    this.sportSelected = sport;
    this.selectedSportService.sportSelected = sport;
    const [, city] = this.activateRoute.snapshot.url;
    let ground: TypeGround
    if (sport === "tennis") {
      ground = this.typeGround.Court
    } else if (sport.includes("fitness")) {
      ground = this.typeGround.Session
    } else {
      ground = this.typeGround.Terrain;
    }
    let translatedPath: any = this.localize.translateRoute(`reservation-${ground}-${sport}`);
    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.router.navigate([`${this.userPreferenceService.getCountry()}/${translatedPath}/${city}`], { queryParams: { date: this.date } });
    }
    else {
      this.router.navigate([`${translatedPath}/${city}`], { queryParams: { date: this.date } });
    }
  }

  public unselectSports(): void {
    this.sportSelected = null;
    this.selectedSportService.sportSelected = null;
  }

  public openFilterModal(content) {
    this.modalService.open(content, { centered: true });
  }

  public submitFilter(filter): void {
    if (this.sportSelected === 'tennis' && filter[0] && filter[0].id === 'tennis-lessons') {
      this.activeSession = 2;
    } else {
      this.activeSession = 1;
    }
    let searchPrice = "";
    const datez = this.today + "T" + this.heureActuel;
    const city = this.cityService.formatCity(this.city);
    let sportTypeString;
    if (this.sportTypeSelected === undefined || (this.sportTypeSelected && this.sportTypeSelected.indexOf('all') !== -1)) {
      sportTypeString = this.translate.instant('keySport.' + this.infoSelected);
    } else {
      sportTypeString = this.sportTypeSelected.join();
    }
    if (filter !== this.sportTypeSelected) {
      this.numberFilters = 0;
      this.mapReady = false;
      this.filterObject = filter;
      let charId = [];
      let filerArray = [];
      if (filter[0] !== undefined) {
        this.serviceTypeObject = filter[0]
        this.servicetype = filter[0].id
        filerArray.push(filter[0].name)
        this.numberFilters++;
      }
      if (filter[1].length > 0) {
        for (const key of filter[1]) {
          charId.push(key.id);
          filerArray.push(key.name);
        }
        this.numberFilters = this.numberFilters + filter[1].length;
      }
      this.filtercharacteristic = filter[1]

      this.infrastructuresSelected = [];
      this.filtercharacteristic.forEach(f => {
        if (this.infrastructures.findIndex(i => i.id == f.id) != -1) {
          this.infrastructuresSelected.push(f);
        }
      });

      this.charachterics = charId.toString();

      if (filter[3] != 100) {
        this.numberFilters++;
        this.radius = filter[3];
        if(this.radius != null && this.radius != ''){
          String((Number(this.radius)* 1.5));
        }
        filerArray.push(filter[3])
      }
      if (filter[2] != 90) {
        this.price = filter[2];
        searchPrice = "" + (filter[2] * 100);
        this.numberFilters = this.numberFilters + 1
        filerArray.push(filter[2]);
      }
      this.isPartner = filter[5];
      if (!this.isPartner) {
        this.numberFilters++;
      }
      this.filter = filerArray;
      this.sharedCenterAvailabilities.setIsFromFilter(true);
      this.getCentersAvailabilities(this.city, sportTypeString, this.serviceTypeInfo, this.date, this.servicetype, this.charachterics, searchPrice, this.radius, this.isPartner, true);
      if (this.userPreferenceService.getCountry() !== 'fr') {
        this.router.navigate([`${this.userPreferenceService.getCountry()}/${this.pathUrl}/${city}`],
          { queryParams: { activity: sportTypeString, date: datez, servicetype: this.servicetype, characteristic: this.charachterics, radius: this.radius, searchPrice } });
      } else {
        this.router.navigate([`${this.pathUrl}/${city}`],
          { queryParams: { activity: sportTypeString, date: datez, servicetype: this.servicetype, characteristic: this.charachterics, radius: this.radius, searchPrice, isPartner: this.isPartner } });
      }
      this.modalService.dismissAll();
    } else {
      this.getCentersSlots();
      if (this.userPreferenceService.getCountry() !== 'fr') {
        this.router.navigate([`${this.userPreferenceService.getCountry()}/${this.pathUrl}/${city}`],
          { queryParams: { activity: sportTypeString, date: datez, servicetype: this.servicetype, characteristic: this.charachterics, radius: this.radius, searchPrice, isPartner: this.isPartner } });
      } else {
        this.router.navigate([`${this.pathUrl}/${city}`],
          { queryParams: { activity: sportTypeString, date: datez, servicetype: this.servicetype, characteristic: this.charachterics, radius: this.radius, searchPrice, isPartner: this.isPartner } });
      }
    }
  }

  removeServiceType(item): void {
    this.servicetype = '';
    const datez = this.today + "T" + this.heureActuel;
    this.sharedCenterAvailabilities.setIsFromFilter(true);
    this.getCentersAvailabilities(this.city, this.translate.instant('keySport.' + this.infoSelected), this.serviceTypeInfo, this.date, this.servicetype, this.charachterics, this.price, this.radius, this.isPartner, true);
    const [, city] = this.activateRoute.snapshot.url;
    this.router.navigate([`${this.pathUrl}/${city.path}`], { queryParams: { activity: this.infoSelected, date: datez, servicetype: this.servicetype, characteristic: this.charachterics, price: this.price, isPartner: this.isPartner } });
    this.serviceTypeObject = {}
    this.numberFilters = this.numberFilters - 1
  }

  removecharacteristic(item): void {
    let inde = this.filtercharacteristic.findIndex(r => r.id === item)
    this.filtercharacteristic.splice(inde, 1);
    let filerArray = [];
    for (const key of this.filtercharacteristic) {
      filerArray.push(key.id)
    }
    this.charachterics = filerArray.toString();
    const datez = this.today + "T" + this.heureActuel
    this.sharedCenterAvailabilities.setIsFromFilter(true);
    this.getCentersAvailabilities(this.city, this.translate.instant('keySport.' + this.infoSelected), this.serviceTypeInfo, this.date, this.servicetype, this.charachterics, this.price, this.radius, this.isPartner, true);
    const [, city] = this.activateRoute.snapshot.url;
    this.router.navigate([`${this.pathUrl}/${city.path}`], { queryParams: { activity: this.infoSelected, date: datez, servicetype: this.servicetype, characteristic: this.charachterics, price: this.price, isPartner: this.isPartner } }).then(() => {
      // window.location.reload();
    });
    this.numberFilters = this.numberFilters - 1
  }

  removeprice(item): void {
    this.price = ''
    const datez = this.today + "T" + this.heureActuel
    this.sharedCenterAvailabilities.setIsFromFilter(true);
    this.getCentersAvailabilities(this.city, this.translate.instant('keySport.' + this.infoSelected), this.serviceTypeInfo, this.date, this.servicetype, this.charachterics, this.price, this.radius, this.isPartner, true);
    const [, city] = this.activateRoute.snapshot.url;
    this.router.navigate([`${this.pathUrl}/${city.path}`], { queryParams: { activity: this.infoSelected, date: datez, servicetype: this.servicetype, characteristic: this.charachterics, price: this.price, isPartner: this.isPartner } }).then(() => {
      // window.location.reload();
    });
    this.numberFilters = this.numberFilters - 1
  }

  removeradius(item): void {
    this.radius = ''
    this.numberFilters = this.numberFilters - 1
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed`;
    });
  }

  getDate(event): void {
    const [path, city] = this.activateRoute.snapshot.url;
    this.dateSearch = event
    this.today = event.slice(0, 10);
    this.heureActuel = event.slice(12, event.length);
    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.router.navigate([`${this.userPreferenceService.getCountry()}/${path}/${city}`], { queryParams: { activity: this.infoSelected, date: event } }).then(() => {
        window.location.reload();
      });
    } else {
      this.router.navigate([`${path}/${city}`], { queryParams: { activity: this.infoSelected, date: event } }).then(() => {
        window.location.reload();
      });
    }
    this.modalService.dismissAll()
  }

  openSportPoup(): void {
    alert("affiche moi")
  }

  getSportByActivity(): void {
    this.activityService.getActivitiesByCity(this.city).subscribe(
      res => {
        this.listActivity = res;
        this.activies = res['activities'];
        let infoSport = res['activities'].find(r => r.id === this.translate.instant('keySport.' + this.infoSelected.toLowerCase()));
        if (this.sportSelected !== undefined) {
          if (infoSport && infoSport.activities) {
            this.sportTypeList = infoSport.activities;
            this.sportTypeSelected = [];
            if (this.activateRoute.snapshot.queryParams['activity'] !== undefined && this.activateRoute.snapshot.queryParams['activity'] !== 'fitness') {
              const activityInUrl = this.activateRoute.snapshot.queryParams['activity'].split(',');
              activityInUrl.forEach(activity => {
                this.sportTypeSelected.push(activity);
              });
            } else {
              this.sportTypeSelected.push('all');
            }
            this.getCentersSlots();
          }
        }
        if (infoSport !== undefined) {
          this.imageSport = infoSport['icon']
        } else {
          this.getSportByContry()
        }
      }
    )
  }
  getSportByContry(): void {
    this.activityService.getActivitiesByCountry(this.userPreferenceService.getCountry()).subscribe(
      res => {
        let infoSport = res['activities'].find(r => r.id === this.translate.instant('keySport.' + this.infoSelected.toLowerCase()))
        if (infoSport !== undefined) {
          this.imageSport = infoSport['icon']
        }
      }
    )
  }

  orientation(id): void {
    this.sportSelected = id;
    this.selectedSportService.sportSelected = id;
    const city = this.cityService.formatCity(this.city);
    //const ground: TypeGround = id === "tennis" ? this.typeGround.Court : this.typeGround.Terrain;
    let ground: TypeGround
    if (id === "tennis") {
      ground = this.typeGround.Court
    } else if (id.includes("fitness")) {
      ground = this.typeGround.Session
    } else {
      ground = this.typeGround.Terrain;
    }
    this.localize.changeLanguage(this.userPreferenceService.getCountry())
    let translatedPath: any = this.localize.translateRoute(`reservation-${ground}-${id}`);
    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.router.navigate([`${this.userPreferenceService.getCountry()}/${translatedPath}/${city}`], { queryParams: { activity: this.sportSelected, date: this.date } });
    }
    else {
      this.router.navigate([`${translatedPath}/${city}`], { queryParams: { activity: this.sportSelected, date: this.date } });
    }

    this.modalService.dismissAll()
  }

  isFilter(event): void {
    this.modalService.open(this.filterContent, { centered: true })
  }

  openSport($event): void {
    this.modalService.open(this.Sport, { centered: true })
  }
  openCity(): void {
    if (this.isMobileResolution) {
      this.modalService.open(this.cityModal, { centered: true })
    }
  }

  getNewCity(event): void {
    const ground: TypeGround = this.translate.instant('keySport.' + this.infoSelected) == "tennis" ? this.typeGround.Court : this.typeGround.Terrain;
    const sp = this.translate.instant('keySport.' + this.infoSelected)
    let translatedPathSport: any = this.localize.translateRoute(`reservation-${ground}-${sp}`);
    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.router.navigate([`${this.userPreferenceService.getCountry()}/${translatedPathSport}/${event}`], { queryParams: { activity: this.infoSelected, date: this.date } }).then(() => {
        window.location.reload();
      });
    } else {
      this.router.navigate([`${translatedPathSport}/${event}`], { queryParams: { activity: this.infoSelected, date: this.date } }).then(() => {
        window.location.reload();
      });
    }
    this.modalService.dismissAll();
  }

  closePoup($event) {
    this.modalService.dismissAll();
  }

  private getWordpressHeader(city: string): Observable<string> {
    let title = this.translate.instant('availabilities.reservation', { sport: this.infoSelected.toUpperCase(), city });
    return this.wordpressService.loadPosts('intro', this.infoSelected, city).pipe(
      tap((res: Post[]) => {
        if (res !== undefined && res.length > 0) {
          title = res[0].title;
        }
        this.seo.generateTags({
          title: title,
          description: this.translate.instant('availabilities.reservation', { sport: this.infoSelected.toUpperCase(), city }),
          image: this.sportImageUrl[this.infoSelected]
        });
      }),
      map((res: Post[]) => {
        if (res !== undefined && res.length > 0) {
          const header: string = res[0].content;
          return header;
        }
      })
    )
  }

  private getWordpressFooter(city: string): Observable<string> {
    return this.wordpressService.loadPosts('content', this.infoSelected, city).pipe(
      map((res: Post[]) => {
        if (res !== undefined && res.length > 0) {
          const footer: string = res[0].content;
          return footer;
        }
      })
    )
  }

  getNameSport(name): string {
    return this.translate.instant('sports.' + name)
  }

  getImageUrl(sportInfo): string {
    return sportInfo['icon']
  }

  getCity(): String {
    if (this.userPreferenceService.getCountry() === 'es') {
      return 'huelva-21001'
    } else {
      return "paris-75000";
    }
  }

  setFavorite(centerId) {
    this.favoriteService.addOrRemoveFavoriteIfExist(centerId);
    this.favoriteIndexedObj[centerId] = this.favoriteService.isFavorite(centerId);
  }

  chooseSessionsType(event) {
    if (event.value !== 'all') {
      if (this.sportTypeSelected.indexOf(event.value) !== -1) {
        this.sportTypeSelected.splice(this.sportTypeSelected.indexOf(event.value), 1);
        if (this.sportTypeSelected.length === 0) {
          this.sportTypeSelected.push('all');
        }
      } else {
        if (this.sportTypeSelected.indexOf('all') !== -1) {
          this.sportTypeSelected.splice(this.sportTypeSelected.indexOf('all'), 1);
        }
        this.sportTypeSelected.push(event.value);
      }
    }
    if (this.sportTypeList.length === this.sportTypeSelected.length || event.value === 'all') {
      this.sportTypeSelected = [];
      this.sportTypeSelected.push('all');
    }
    this.submitFilter(this.sportTypeSelected);
  }

  serviceTypeIsSelected(value): boolean {
    if (this.sportTypeSelected.indexOf(value) !== -1) {
      return true;
    }
    return false;
  }

  onPrev(id: string): void {
    if (id === 'sportSlider') {
      const element = this.sportSlider.nativeElement;
      element.scrollLeft = element.scrollLeft - (this.sportSlider.nativeElement.clientWidth + 20);
    }
    if (id === 'typeSportSlider') {
      const element = this.typeSportSlider.nativeElement;
      element.scrollLeft = element.scrollLeft - (this.typeSportSlider.nativeElement.clientWidth + 20);
    }
  }

  onNext(id: string): void {
    if (id === 'sportSlider') {
      const element = this.sportSlider.nativeElement;
      element.scrollLeft = element.scrollLeft + (this.sportSlider.nativeElement.clientWidth - 20);
    }
    if (id === 'typeSportSlider') {
      const element = this.typeSportSlider.nativeElement;
      element.scrollLeft = element.scrollLeft + (this.typeSportSlider.nativeElement.clientWidth - 20);
    }
  }

  getCentersSlots(): void {
    this.sportTypeSelectedString = '';
    if (this.sportTypeSelected === undefined || (this.sportTypeSelected && this.sportTypeSelected.indexOf('all') !== -1)) {
      this.sportTypeSelectedString = this.sportSelected;
    } else {
      for (let i = 0; i < this.sportTypeSelected.length; i++) {
        this.sportTypeSelectedString = this.sportTypeSelectedString + this.sportTypeSelected[i];
        if (i !== (this.sportTypeSelected.length - 1)) {
          this.sportTypeSelectedString = this.sportTypeSelectedString + ',';
        }
      }
    }
    this.sharedCenterAvailabilities.setIsFromFilter(true);
    this.getCentersAvailabilities(this.city, this.sportTypeSelectedString, this.serviceTypeInfo, this.date, this.servicetype, this.charachterics, this.valuePrice, this.radius, this.isPartner);
  }

  getServiceInformationBySlotId(centerId, slotId): ServiceInformation {
    let flag = false;
    let serviceInformation = new ServiceInformation();
    for (const center of this.dataList) {
      if (centerId === center.id) {
        for (const timeSlot of center.timeSlots) {
          if (flag) {
            break;
          }
          for (const service of timeSlot.services) {
            if (flag) {
              break;
            }
            if (slotId === service.slotId) {
              serviceInformation.id = service['id'];
              serviceInformation.availablePlaces = service['availablePlaces'];
              serviceInformation.duration = service['duration'];
              serviceInformation.discountPrice = service['discountPrice'];
              serviceInformation.isCancellable = service['isCancellable'];
              serviceInformation.price = service['price'];
              serviceInformation.prices = service['prices'];
              serviceInformation.serviceFee = service['serviceFee'];
              serviceInformation.serviceFeeText = service['serviceFeeText'];
              serviceInformation.slotId = service['slotId'];
              serviceInformation.totalCapacity = service['totalCapacity'];
              flag = true;
            }
          }
        }
      }
    }
    return serviceInformation;
  }

  initSessionList() {
    this.sessionListSport = [];
    this.setSessionList();
    this.sessionListSport = this.sessionListSport.sort((a, b) => {
      return <any>new Date(a.startDateTime) - <any>new Date(b.startDateTime);
    });
  }

  setSessionList() {
    if (this.data !== undefined) {
      this.data.forEach(center => {
        center.timeSlots.forEach((timeSlot) => {
          timeSlot.services.forEach((service) => {
            const serviceCenter = this.getServiceCenterByServiceId(service.id);
            if (
              (this.sportSelected === 'fitness' && this.sportTypeSelected !== undefined && (this.isServiceTypeSelected(center.id, service.id) || this.sportTypeSelected.indexOf('all') !== -1))
              || (this.sportSelected === 'tennis' && serviceCenter.serviceTypeId === "tennis-lessons")
            ) {
              let session = Object.assign({}, service);
              session.startDateTime = timeSlot.startDateTime;
              session.center = center;
              let facilities = this.getFacilitiesByService(service.id);
              session.characteristics = facilities.keys[0];
              session.siteAddress = facilities.values[0];
              session.serviceCenter = serviceCenter;
              this.sessionListSport.push(session);
            }
          });
        });
      });
    }
  }

  getServiceCenterByServiceId(serviceId: string): Service {
    let serviceCenter: Service;
    for (let center of this.data) {
      for (let service of center.services) {
        if (service.id === serviceId) {
          serviceCenter = service;
          break;
        }
      }
      if (serviceCenter !== undefined) {
        break;
      }
    }
    return serviceCenter;
  }

  isServiceTypeSelected(centerId, serviceId: string): boolean {
    if (this.sportTypeSelected.indexOf(this.getServiceTypeId(centerId, serviceId)) !== -1) {
      return true;
    } else {
      return false
    }
  }

  getServiceTypeId(centerId: string, serviceId: string): string {
    for (let center of this.data) {
      if (center.id === centerId) {
        for (let service of center.services) {
          if (service.id === serviceId) {
            for (let serviceType of center.serviceTypes) {
              if (service.serviceTypeId === serviceType.id) {
                return serviceType.activityId;
              }
            }
          }
        }
      }
    }
    return undefined;
  }

  getFacilitiesByService(serviceId: string): Map<Chracteristics[], Address> {
    let facilities: Map<Chracteristics[], Address> = new Map<[], undefined>();
    let resourceId = '';
    this.data.forEach(center => {
      center.services.forEach(service => {
        if (serviceId === service.id) {
          resourceId = service.resourceId;
        }
      });
    });
    this.data.forEach(center => {
      if (center.resources) {
        center.resources.forEach(resource => {
          if (resource.id === resourceId) {
            facilities.set(resource.characteristics, undefined);
          }
          if (center.sites) {
            center.sites.forEach(site => {
              if (resource.siteId && resource.siteId === site.id) {
                if (facilities.size > 0) {
                  facilities.set(facilities.keys[0], site.address);
                }
              }
            })
          }
        })
      }
    });
    return facilities;
  }

  getHours(date: string): string {
    if (date !== undefined) {
      const today = date.slice(11, date.length);
      return today;
    }
  }

  getMinPrice(service: []): number {
    const price = Math.min.apply(null, service.map(function (o) { return o['price']; }));
    return price;
  }

  openSessionDescriptionModal(text: string) {
    const modalRef = this.modalService.open(MoreDetailsComponent, { size: 'lg', centered: true });
    modalRef.componentInstance.serviceDescription = text;
  }

  isTarifPopup(tarifContent): void {
    this.modalService.open(tarifContent, { scrollable: true })
  }

  bookSessionActivity(selectedSession, tarifContent) {
    this.initial = this.getServiceInformationBySlotId(selectedSession.center.id, selectedSession.slotId);
    this.servPrice = this.getServiceInformationBySlotId(selectedSession.center.id, selectedSession.slotId);
    const selectedService = {
      startDateTime: selectedSession.startDateTime,
      services: [this.initial]
    }
    sessionStorage.setItem('slots', JSON.stringify(selectedService));
    sessionStorage.setItem('activities', this.sportSelected);

    if (selectedSession.prices && selectedSession.prices.length > 0 && selectedSession.availablePlaces) {
      this.isTarifPopup(tarifContent);
    } else {
      let infoPaiement = new InfoPaiement()
      infoPaiement.centerId = selectedSession.center.id;
      infoPaiement.serviceId = selectedService.services[0]['id'];
      infoPaiement.slotId = selectedService.services[0]['slotId'];
      infoPaiement.price = selectedService.services[0]['price'];
      infoPaiement.places = [];
      this.infoPaiementService.servPrice = infoPaiement;
      sessionStorage.setItem('infoPaiement', JSON.stringify(infoPaiement));
      if (this.userPreferenceService.getCountry() !== 'fr') {
        this.router.navigate(['/es/checkout/' + selectedSession.center.id + '/' + selectedSession.id], {
          state: infoPaiement
        });
      } else {
        this.router.navigate(['/checkout/' + selectedSession.center.id + '/' + selectedSession.id], {
          state: infoPaiement
        });
      }
    }
  }

  addMoreSessionInList(): void {
    this.centerAvailabilitiesService.getAvaibilitiesPagination(this.pagination.next).subscribe(
      res => {
        this.isPagination = false;
        this.data = res.data;
        this.sourceFromMap = false;
        this.dataList = [...this.dataList, ...this.data]
        this.pagination = res.paging;
        this.setSessionList();
      }
    );
  }

  addPagination(): void {
    if (this.pagination && this.pagination.hasNextPage && !this.isPagination) {
      this.isPagination = true;
      this.paginationCount += 1;
      this.addMoreSessionInList();
    }
  }

  hasData(): boolean {
    let flag = false;
    for (const data of this.dataList) {
      if (data !== undefined && data.length !== 0) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  onScroll($event): void {
    const pos = -this.getYPosition($event) + 15;
    this.backTopContainer.nativeElement.style.bottom = pos + 'px';
    if (this.getYPosition($event) !== 0) {
      this.backTopContainer.nativeElement.style.display = 'block';
    } else {
      this.backTopContainer.nativeElement.style.display = 'none';
    }
  }

  getYPosition(e: Event): number {
    return (e.target as Element).scrollTop;
  }

  backToTop(): void {
    this.centerContainer.nativeElement.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  setTennisServiceType(serviceType: string): void {
    this.servicetype = serviceType;
    const datez = this.today + "T" + this.heureActuel;
    const [, city] = this.activateRoute.snapshot.url;
    this.router.navigate([`${this.pathUrl}/${city.path}`], { queryParams: { activity: this.infoSelected, date: datez, servicetype: serviceType, characteristic: this.charachterics, price: this.price, isPartner: this.isPartner } });
    this.getCentersAvailabilities(this.city, this.translate.instant('keySport.' + this.infoSelected), this.serviceTypeInfo, this.date, serviceType, this.charachterics, this.valuePrice, this.radius, this.isPartner, true);
  }

  getFilter(): void {
    this.infrastructures = [];
    this.hasInfrasctructures = false;
    if (this.sportSelected) {
      this.centerService.getFilterByActivity(this.sportSelected).subscribe(res => {
        const filter = res;
        if (filter.length > 1) {
          filter.forEach(f => {
            const pos = f.resourceCharacteristicTypes.findIndex(c => c.id == 'infrastructure');
            if (pos != -1) {
              this.infrastructures = this.infrastructures.concat(filter[0].resourceCharacteristicTypes[pos].options)
            }
          });
          const key = 'id';
          this.infrastructures = [...new Map(this.infrastructures.map(item =>
            [item[key], item])).values()];
        } else if (filter.length == 1) {
          const pos = filter[0].resourceCharacteristicTypes.findIndex(c => c.id == 'infrastructure')
          if (pos != -1) {
            this.infrastructures = this.infrastructures.concat(filter[0].resourceCharacteristicTypes[pos].options)
          }
        }
        if (this.infrastructures.length > 1) {
          this.hasInfrasctructures = true;
        }
      });
    }
  }

  infraIsSelected(value): boolean {
    if (this.infrastructuresSelected.findIndex(i => i.id == value) !== -1) {
      return true;
    }
    return false;
  }

  chooseInfra(infra) {
    const index = this.infrastructuresSelected.findIndex(i => i.id == infra.id);
    if (index == -1) {
      this.infrastructuresSelected.push(infra);
      this.filtercharacteristic.push(infra);
    } else {
      this.infrastructuresSelected.splice(index, 1)
      const indexFilter = this.filtercharacteristic.findIndex(i => i.id == infra.id);
      if (indexFilter != -1) {
        this.filtercharacteristic.splice(indexFilter, 1);
      }
    }
    const filter = [this.serviceTypeObject ? this.serviceTypeObject : undefined, this.filtercharacteristic, this.price ? this.price : 90, this.radius ? this.radius : 100, this.isPartner];
    this.submitFilter(filter);
  }

  searchOnMap() {
    if (this.container && !this.hasSport) {
      this.container.nativeElement.scrollIntoView();
    }
  }
}
