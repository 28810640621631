<div *ngIf="allSessionForm" class="row feedback">
  <div class="col-md-4"></div>
  <div class="col-md-4 col-xs-12 card">
    <!-- Header -->
    <div style="text-align: center;margin-top: -50px;">
      <img src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/buddy/logo-simple.png" alt="" style="width: 100px;">
    </div>
    <!-- Content -->
    <div class="header">
      <h1>Finaliser ma réservation</h1>
    </div>
    <div class="content">
      <div *ngIf="!isSaved" class="container">
        <h4>Réservation au club : {{ allSessionForm.centerName }}</h4>
        <h4> {{ allSessionForm.centerAddress}} </h4>
        <h4>Joueur : {{ allSessionForm.playerName }}</h4>
        <h4>de {{ allSessionForm.start | date:'short' }} à {{ allSessionForm.end | date:'short' }}</h4>
      </div>
      <div *ngIf="!isSaved" class="question">
        <h2>Quel est le nom de votre entreprise ?</h2>
        <div id="s1">
          <input type="text" class="card-input" rows="3" cols="20" [(ngModel)]="allSessionForm.companyName" placeholder="Nom d'entreprise..."/>
        </div>
      </div>

      <div class="actions">
        <button *ngIf="!isSaved" (click)="valide()">Envoyer</button>
        <p *ngIf="isSaved" style="margin-bottom: 0px;">Merci  !
          <br>ATTENTION, CETTE RÉSERVATION NE COMPTE QUE POUR UN JOUEUR !
          <br>Ta partie va être réservée d'ici peu avec All Session et la mairie de Paris.
          <br>Demande donc à tes partenaires de réserver aussi leur place en rejoignant ta partie via ce lien :
          <br><a href="{{urlCenter}}">{{urlCenter}}</a>
        </p>
      </div>
    </div>
  </div>
  <div class="col-md-4"></div>
</div>
