import {ErrorHandler, Injectable} from '@angular/core';
import firebase from 'firebase/compat';
import FirebaseError = firebase.FirebaseError;

interface AngularFireError extends Error {
  rejection: FirebaseError;
}

function errorIsAngularFireError(err: any): err is AngularFireError {
  return err.rejection && err.rejection.name === 'FirebaseError';
}

// Not providedIn 'root': needs special handling in app.module to override default error handler.
@Injectable()
export class FirebaseErrorGesture implements ErrorHandler {
  handleError(error: any): void {
    // AngularFire errors should be catchable and handled in components; no need to further process them.
    if (!errorIsAngularFireError(error)) {
      console.error(error);
    }
  }
}
