import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Promotion } from '../../models/promotion';
import { BrowserStorageService } from '../../service/storage.service';
import { UserPreferenceService } from '../../service/user-preferences.service';
import { VoucherService } from '../../service/voucher.service';

@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss'],
})
export class PromoCodeComponent implements OnInit {
  @Input() modal: NgbActiveModal;
  @Output() voucher = new EventEmitter();
  @Output() modalchoose = new EventEmitter();
  isEspagnole = false;
  public error = false;
  public placeholderInput = 'Entrez votre code promo';
  public imgPartner: string;
  public partner: string;

  public promotionModel: Promotion;
  constructor(
    public voucherService: VoucherService,
    private sessionStorageService: BrowserStorageService,
    private translate: TranslateService,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    if (this.userPreferenceService.getCountry() !== 'es') {
      this.isEspagnole = true;
    }
  }

  public openModalClub(content, partner, img): void {
    this.partner = partner;
    this.imgPartner = img;
    this.modalchoose.emit({ part: partner, img: this.imgPartner });
    // this.modalService.open(content, { centered: true });
  }

  public closeModal(modal: NgbActiveModal): void {
    modal.close();
  }

  public onSubmit(form: NgForm): void {
    if (!form.valid) {
      this.error = true;
    } else {
      this.voucherService
        .checkVoucher('promo', form.controls.promo.value, null, null)
        .subscribe(
          (code) => {
            this.sessionStorageService.setObject('voucherPromo', {
              information: code.information,
              amount: code.discountAmount,
              operation: code.discountOperation,
            });
            this.promotionModel = new Promotion(
              'promo',
              form.controls.promo.value,
              null,
              null
            );
            this.voucher.emit({ code, promo: this.promotionModel });
          },
          (error) => {
            if (error.status === 404) {
              this.translate
                .get('voucher.invalid')
                .subscribe((res) => (this.error = res));
            } else {
              this.error = error.error.message;
            }
          }
        );
    }
  }
}
