import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { AuthFirebaseService } from '../service/auth-firebase.service';

@Component({
  selector: 'app-fire-base-action',
  templateUrl: './fire-base-action.component.html',
  styleUrls: ['./fire-base-action.component.scss']
})
export class FireBaseActionComponent implements OnInit {
  constructor(
    public authService: AuthService,
    private router: Router,
    private authFirebaseService: AuthFirebaseService,
    private route: ActivatedRoute,
  ) { }

  mode = '';
  showError = false;
  showVerifMail = false;
  errorMsg = '';

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
          if (params.mode){
            this.mode = params.mode;
            this.transferToPage(this.mode, params);
          } else {
            this.showError = true;
            this.errorMsg = '';
          }
        }
      );
  }

  transferToPage(mode: string, params): void {
    switch (mode) {
      case 'signIn':
        this.router.navigate(['/complete-sign-up'], {queryParams: params});
        break;
      case 'resetPassword':
        this.router.navigate(['/pwchangeform'], {queryParams: params});
        break;
      case 'verifyEmail':
        this.verifiMail(params.oobCode);
        break;
    }
  }

  verifiMail(code: string): void {
    this.authFirebaseService.VerifyMail(code)
      .then( () => {
          this.showVerifMail = true;
        }
      )
      .catch( (error) => {
        this.errorMsg = error.message;
        this.showError = true;
        }
      );
  }
}
