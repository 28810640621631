import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { AuthFirebaseService } from './service/auth-firebase.service';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        @Inject(PLATFORM_ID) private platform,
        private authService: AuthService,
        private router: Router,
        private firebaseAuth: AuthFirebaseService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (isPlatformBrowser(this.platform) && this.firebaseAuth.getIsLoggedIn) {
           // logged in so return true
            return true;
        }
        // Store the attempted URL for redirecting
        this.authService.setRedirectUrl(state.url);
        this.router.navigate(['/login']);
        return false;
    }
}
