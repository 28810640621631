import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnChanges, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { REVIEWS_LIST } from '../models/review-data';

@Component({
  selector: 'app-presse-carousel',
  templateUrl: './presse-carousel.component.html',
  styleUrls: ['./presse-carousel.component.scss'],
  providers: [NgbCarouselConfig]
})
export class PresseCarouselComponent implements OnInit, OnChanges {
  presseReviews: any = REVIEWS_LIST.filter(menuItem => menuItem);
  cardShown = 2.5;
  arrowOutside = true;
  @Input() isMobileResolution: boolean;


  isBrowser: boolean;
  constructor(@Inject(PLATFORM_ID) private platformId) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    const dummmyEvent = {target: {innerWidth: window.innerWidth}};
    this.onResize(dummmyEvent);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isMobileResolution.currentValue != null && changes.isMobileResolution.currentValue !== undefined){
      if (changes.isMobileResolution.currentValue === true){
        this.cardShown = 1.5;
      } else{
        this.cardShown = 2.5;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    const width = event.target.innerWidth;
    if (width < 576){
      this.cardShown = 1.5;
      this.arrowOutside = false;
    } else if (width < 768){
      this.cardShown = 1.5;
      this.arrowOutside = false;
    } else if (width < 992){
      this.cardShown = 2.5;
      this.arrowOutside = true;
    } else{
      this.cardShown = 2.5;
      this.arrowOutside = true;
    }
  }
}
