import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { InfoPaiement } from '../../../models/infoPaiment';
import { Places } from '../../../models/places';
import { Prices } from '../../../models/prices';
import { ServiceInformation } from '../../../models/serviceInformation';
import { ServicesSlots } from '../../../models/slots';
import { MoreDetailsComponent } from '../../../more-details/more-details.component';
import { InfoPaiementService, SharedService } from '../../../service/shared.service';
import { UserPreferenceService } from '../../../service/user-preferences.service';
@Component({
  selector: 'app-activity-availability-displayer',
  templateUrl: './activity-availability-displayer.component.html',
  styleUrls: ['./activity-availability-displayer.component.scss'],
})
export class ActivityAvailabilityDisplayerComponent implements OnInit, OnChanges {

  @Input() servicesSlots: ServicesSlots[] = [];
  @Input() center;
  @Input() selectedSport;
  @Input() serviceInformation;
  @Input() selectedSlot;
  @Input() loading = false;
  @Input() centerId: string;
  color = '#FFC300';
  isMobileResolution = true;
  isChangeNbrFromPaiement: boolean;
  currentDay = '';

  servPrice: ServiceInformation;
  prices: Prices[] = [];
  places: Places[] = [];

  feePrice = 0;
  slotId: string;
  placeDispo = 0;
  initial: ServiceInformation;
  today = new Date().toISOString().slice(0, 10);
  @ViewChild('tarifcontent', { static: false }) public tarifcontent: ElementRef;

  constructor(
    private userPreferenceService: UserPreferenceService,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private modalService: NgbModal,
    public infoPaiementService: InfoPaiementService,
    private router: Router,
  ) {
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedSlot !== undefined){
        this.selectedSlot = changes.selectedSlot.currentValue;
    }

    if (changes.serviceInformation !== undefined && changes.serviceInformation.currentValue){
      this.serviceInformation = changes.serviceInformation.currentValue;
    }
  }

  ngOnInit(): void {
    if (this.servicesSlots === undefined){
      this.servicesSlots = [];
    }
    this.isChangeNbrFromPaiement = false;
  }

  openSessionDescriptionModal(session: any): void {
    const modalRef = this.modalService.open(MoreDetailsComponent, { size: 'lg', centered: true });
    modalRef.componentInstance.session = session;
    modalRef.componentInstance.selectedSport = this.selectedSport;
    modalRef.componentInstance.onBookOrChooseTarif.subscribe(
      value => {
        this.modalService.dismissAll();
        this.onBookActivity(session, this.selectedSlot, this.tarifcontent);
      });
  }

  // quand l'utilisateur appuie sur le bouton "réserver" d'un des services présentés reservationDetails/
  onBookActivity(service, selectedSlot, tarifContent): void {

    const slotSelected = selectedSlot.filter( slots => slots.startDateTime === service.startTime)[0];

    const serviceId = slotSelected.services.filter(
      s =>
        s.id === service.id &&
        s.duration === service.duration &&
        s.price === service.price
    );
    const selectedService = {
      startDateTime: slotSelected.startDateTime,
      services: [...serviceId]
    };
    sessionStorage.removeItem('slots');
    sessionStorage.setItem('slots', JSON.stringify(selectedService));
    this.feePrice = selectedService.services[0].serviceFee;
    sessionStorage.setItem('activities', this.selectedSport);
    if (selectedService.services[0].prices &&
      selectedService.services[0].prices.length > 0  &&
      selectedService.services[0].availablePlaces){
      this.placeDispo = selectedService.services[0].availablePlaces;
      this.servPrice = selectedService.services[0];
      this.initial = selectedService.services[0];
      this.isTarifPopup(tarifContent);
    } else {
      const infoPaiement = new InfoPaiement();
      infoPaiement.centerId = this.centerId;
      infoPaiement.serviceId = selectedService.services[0].id;
      infoPaiement.slotId = selectedService.services[0].slotId;
      infoPaiement.price = selectedService.services[0].price;
      infoPaiement.places = [];
      this.infoPaiementService.servPrice = infoPaiement;
      sessionStorage.setItem('infoPaiement', JSON.stringify(infoPaiement));
      this.servPrice = selectedService.services[0].prices;
      const country = this.userPreferenceService.getCountry();
      if (country !== 'fr') {
        this.router.navigate(['/' + country + '/checkout/' + this.centerId + '/' + service.id], {
          state: infoPaiement
        });
      } else {
        this.router.navigate(['/checkout/' + this.centerId + '/' + service.id], {
          state: infoPaiement
        });
      }
    }
  }

  isTarifPopup(tarifContent): void{
    this.modalService.open(tarifContent, { scrollable: true  });
  }

  isOtherDay(session: any): boolean {
    const date = session.startTime.split('T')[0];
    if (this.currentDay === ''){
      this.currentDay = date;
    }
    if (this.currentDay === date){
      return false;
    } else {
      this.currentDay = date;
      return true;
    }
    return false;
  }

  isToday(startTime: any): boolean {
    return this.today === startTime.slice(0, 10);
  }
}
