import { Chracteristics } from './characteristic';
import { Prices } from './prices';
/* eslint-disable */
export class ServiceInformation{

  public static fromJson(json): ServiceInformation {
    return new ServiceInformation()
    ;
  }
    id?: string;
    name?: string;
    price?: number;
    currency?: string;
    charachteristic?: Chracteristics[];
    prices?: Prices[];
    startTime?: string;
    duration?: number;
    photo?: string;
    availablePlaces?: number;
    totalCapacity?: number;
    description?: string;
    nbPlaces?: number;
    placePrise?: number;
    discountPrice?: number;
    isCancellable?: boolean;
    serviceFee?: number;
    serviceFeeText?: string;
    slotId?: string;
    
    constructor()
      {
        
      }
}
