import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {
  price: number;
  uri = `${environment.bookingApiUrl}/v2`;

  constructor(private http: HttpClient) { }

  checkVoucher(promoType: string, code1: string, code2?: string, email?: string): Observable<any> {
    return this.http.get(`${this.uri}/vouchers?type=${promoType}&code1=${code1}&code2=${code2}&email=${email}`);
  }

  applyVoucher(promoType: string, code1: string, code2?: string, email?: string): Observable<any> {
    return this.http.post(`${this.uri}/vouchers/type=${promoType}&code1=${code1}&email=${email}`, null);
  }
}
