<div class="d-flex flex-column login-div">

  <!-- Formulaire de verification du mail : STEP 1 -->
  <ng-container *ngIf="step1">
    <div class="d-flex"
         [ngClass]="{'mb-3 justify-content-left': isMobileResolution, 'mt-4 mb-3 justify-content-center': !isMobileResolution}">
      <div class="title" [ngStyle]="{'background-color':'#ffc300'}"
           [ngClass]="{'ms-4 mb-4': isMobileResolution, 'mb-5': !isMobileResolution}">
        {{ (mode=== 'signIn' ? ('login.login_title') : ('signup.signup_title')) | translate }}
      </div>
    </div>


    <form class="d-flex flex-column my-5" [formGroup]="form"
          [ngClass]="{'mx-3': isMobileResolution}">
      <span *ngIf="connection" class="text-center mb-3">veuillez verifier le mail pour vous connecter</span>
      <input class="custom-input"
             [ngClass]="{'mt-2': isMobileResolution, 'my-2': !isMobileResolution, 'error_input': errorMail}"
             placeholder="{{ 'signup.email' | translate }}" type="email" formControlName="email" required>
      <div class="d-flex align-self-center mx-3 mt-2" *ngIf="errorMail">
        <p class="sublabel error" [ngClass]="{' my-0': isMobileResolution, ' my-2': !isMobileResolution}">
          {{ 'signup.error_mail' | translate }}
        </p>
      </div>

    </form>

    <div class="d-flex align-self-center text-center mx-3 mt-2" *ngIf="errorMessage">
      <p class="sublabel error" [ngClass]="{' my-0': isMobileResolution, ' my-2': !isMobileResolution}">
        {{ errorMessage }}
      </p>
    </div>

    <button type="submit" class="fill-btn my-5" [ngStyle]="{'background-color':'#ffc300'}"
            [ngClass]="{'mb-4 mx-3 ': isMobileResolution, 'mt-0': !isMobileResolution}"
            (click)="completeSignupMail()">
      {{ (mode=== 'signIn' ? ('login.login') : ('signup.signup_title')) | translate }}
    </button>


  </ng-container>
  <!-- Fin du formulaire de verification du mail : STEP 1 -->


  <!-- footer -->
  <ng-container *ngIf="step1">
    <h2></h2>

    <app-assistance style=" margin-left: 6%;
" [isMobileResolution]="isMobileResolution"></app-assistance>
  </ng-container>
  <!-- fin footer -->

</div>
