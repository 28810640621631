
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { isPlatformBrowser } from '@angular/common';
import { tap } from 'rxjs/operators';
import { AuthFirebaseService } from './service/auth-firebase.service';
import { UserPreferenceService } from './service/user-preferences.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) private platform,
    private router: Router,
    private userPreferenceService: UserPreferenceService,
    private authFireBase: AuthFirebaseService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const re = '/v1/auth';
    let token;
    if (isPlatformBrowser(this.platform)) {
      token = this.authFireBase.token;
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: token
        }
      });
    }
  }
    request = request.clone({
      setHeaders: {
        'Accept-Language': this.userPreferenceService.getLocale(),
        'X-ANYBUDDY-API-KEY': 'YW55YnVkZHlhcHAuY29tX3g1dDQ5QXkzRU5XXmRgL0M='
      }
    });

    // Exclude interceptor for login request:
    if (request.url.search(re) === -1) {
      return next.handle(request).pipe(
        tap(event => {
        }, error => {
          console.error('http request error', error);
          if (error instanceof HttpErrorResponse) {
            console.warn(`http request status ${error.status}`);
            if (error.status === 401) {
              this.router.navigate(['/logout']);
            }
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
