<div class="navbar-container" [ngClass]="{'position' : isTopBarFixed, 'transparent-background ' : !isYellowLogo, 'white-background': isYellowLogo, 'hided': hided }">
  <nav class="custom-navbar navbar" [ngClass]="{'transparent-background' : !isYellowLogo, 'white-background': isYellowLogo}">
    <div class="container-box">
      <div>
        <img class="logo-anybuddy" *ngIf="!isYellowLogo" [routerLink]="['/' + country]" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/logo/LOGO.png" alt="Anybuddy">
        <img class="logo-anybuddy" *ngIf="isYellowLogo" [routerLink]="['/' + country]" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/logo/logo-yellow.png" alt="Anybuddy">
      </div>
      <div class="test" *ngIf="!isMobileResolution">
        <ng-container *ngTemplateOutlet="leftNavBar"></ng-container>
      </div>
    </div>
    <div class="container-box left">
      <div *ngIf="!isMobileResolution">
        <ng-container *ngTemplateOutlet="rightNavBar"></ng-container>
      </div>
      <div class="me-4 ms-2" *ngIf="!isMobileResolution">
        <app-language [idSelector1]="idSelector1Langage" [idSelector2]="idSelector2Langage" [yellow]="yellow"></app-language>
      </div>
      <div class="ms-auto" *ngIf="isMobileResolution">
        <button [class.yellow]="yellow" [class.light]="!yellow" (click)="sidebarShow = !sidebarShow">
          <svg *ngIf="!yellow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z"/></svg>
          <svg *ngIf="yellow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" fill="rgba(255,255,255,1)"/></svg>
        </button>
      </div>
    </div>
  </nav>
</div>


<div class="bg-nav-slider" [class.bg-nav-slider-in]="sidebarShow"></div>

<div class="sidebar-slider" [class.sidebar-slide-in]="sidebarShow">
  <div class="sidebar-close" (click)="sidebarShow = !sidebarShow">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/></svg>
  </div>
  <div class="sidebar-content">
    <div *ngIf="isMobileResolution">
      <ng-container *ngTemplateOutlet="rightNavBar"></ng-container>
      <ng-container *ngTemplateOutlet="leftNavBar"></ng-container>
    </div>

    <div *ngIf="isMobileResolution" class="lang mt-2" (click)="openLangageModal(content)">
      <div class="d-flex" style="text-align: left">
        <div class="my-auto">
          <span class="font-weight-bold" style="font-size: 1.3rem">
            {{('languagesCountriesLang.' + languageChoosed.languageCode) | translate}} ({{ languageChoosed.trueLanguageCode | uppercase }})
          </span>
        </div>
        <svg class="ms-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z"/></svg>
      </div>
    </div>

  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="btn-close p-0" (click)="modal.dismiss('Cross click')">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/></svg>
    </button>
  </div>
  <div class="modal-body">
    <app-language [footer]="true"></app-language>
  </div>
</ng-template>

<ng-template #rightNavBar>
  <ng-content select="[app-top-navbar-right]"></ng-content>
</ng-template>
<ng-template #leftNavBar>
  <ng-content select="[app-top-navbar-left]"></ng-content>
</ng-template>
