import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { Center } from '../models/center';
import { CenterFacilities } from '../models/center-facilities';
import { Places } from '../models/places';
import { Reservation } from '../models/reservation';
import { Service } from '../models/service';
import { Vouchers } from '../models/vouchers';
import { CentersService } from '../service/center.service';
import { ReservationsService } from '../service/reservations.service';

enum Status {
  Fail = 'failed',
  Cancel = 'cancelled',
  Confirm = 'confirmed',
  Waiting = 'awaitingValidation'
}
@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss'],
})
export class ReservationComponent implements OnInit {
  resaId: string;
  resa: Observable<Reservation>;
  reservation: Reservation;

  facilities: CenterFacilities[];
  facility: CenterFacilities;

  centerId: string;
  center: Center;
  centerService: Service;

  vouchers: Vouchers[];
  places: Places[];

  date: string;
  instructions: string[];
  price: any;
  googleMapLink: string;

  isLittleWidth = false;
  dataAvailable = false;
  hideTitle = true;
  isCancel = false;
  notCancelableError: string;
  isError = false;

  public status = Status;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private reservationsService: ReservationsService,
    private centersService: CentersService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    if (window.innerWidth < 1024) {
      this.isLittleWidth = true;
    } else {
      this.isLittleWidth = false;
    }

    this.resaId = this.route.snapshot.paramMap.get('id');
    this.reservationsService.getReservation(this.resaId).subscribe(
      result => {
        this.reservation = result;
        this.vouchers = result.vouchers;
        this.places = result.places;
        this.center = result.center;
        this.createGoogleMapLink();
        this.price = result.priceCents;
        this.centersService.listCenterFacilities(this.center.id).subscribe(
          facilities => {
            this.facilities = facilities;
            this.facilities.forEach(facility => {
              if (facility.id === this.reservation.facilityId) {
                this.facility = facility;
                this.dataAvailable = true;
              }
            });
          }
        );
      }
    );
  }

  public openFeeInfoModal(feeInfo): void {
    if (this.reservation.serviceFeeText && this.reservation.serviceFeeText !== '' ){
      this.modalService.dismissAll();
      this.modalService.open(feeInfo, { centered: true });
    }
  }

  widthSize(event): void {
    if (event.target.innerWidth < 1024) {
      this.isLittleWidth = true;
    } else {
      this.isLittleWidth = false;
    }
  }

  createGoogleMapLink(): void {
    this.googleMapLink = `https://www.google.com/maps/search/?api=1&query=${this.center.location.lat},${this.center.location.lon}`;
  }

  openCancelReservation(content): void {
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

  public cancelReservation(modal: NgbActiveModal): void {
    if(this.reservation.status === this.status.Cancel || this.reservation.status === this.status.Fail){
      this.notCancelableError = this.translateService.instant('my_resa.already_cancelled');
      this.isError = true;
    } else {
      this.reservationsService.cancelReservation(this.resaId).subscribe(
        (res) => {
          this.isCancel = true;
          setTimeout(() => {
            modal.close();
            window.location.reload();
          }, 2000);
        },
        (error) => {
          this.notCancelableError = error.error.message;
          this.isError = true;
        }
      );
    }
  }
}
