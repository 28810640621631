<div class="header-container">
  <div class="price-icon-container">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 9H4v7h16v-7zm0-4V5H4v3h16z" fill="rgba(255,255,255,1)"/></svg>
  </div>
  <h3 class="title"> {{ 'stripe.add_card' | translate }} </h3>
  <h6 class="soustitle">{{ 'stripe.to_pay_add_card' | translate }}</h6>
</div>

<form id="payment-form">
  <div class="form-row">
    <div class="input-block first d-flex">
      <input id="firstname" name="firstname" [placeholder]="'checkout.firstname' | translate" required class="input-custom w-45 me-auto"
             [ngClass]="errorName ? 'error-input' : ''"  [(ngModel)]="userProfile.firstName"/>
      <input id="name" name="name" [placeholder]="'checkout.lastname' | translate" required class="input-custom w-45 ms-auto"
             [ngClass]="errorName ? 'error-input' : ''" [(ngModel)]="userProfile.lastName" />
    </div>

    <input id="mail" type="email" name="mail" placeholder="Mail" required class="StripeElement" [(ngModel)]="userProfile.email" class="input-custom w-100"
           [ngClass]="errorMail ? 'error-input' : ''"/>
    <p class="soustitle">
      {{ 'checkout.email_infos' | translate }}
    </p>
  </div>
  <div id="link-authentication-element">
    <!--Stripe.js injects the Link Authentication Element-->
  </div>
  <div id="payment-element">
    <!--Stripe.js injects the Payment Element-->
  </div>
  <div *ngIf="!loaded" class="d-flex mb-2">
    <svg class="mx-auto" version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 -7 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" style="margin-top: -5px;">
    <path fill="#a778fb" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
      <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/>
    </path>
  </svg>
  </div>
  <p *ngIf="isPaidSuccess == false" class="errorPayment">{{ errorPayment }}</p>
  <button id="submit" class="btn-submit" (click)="handleFormSubmission()" [disabled]="!loaded || inPayment">
    <svg *ngIf="isLoad" version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 -7 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" style="margin-top: -5px;">
            <path fill="#a778fb" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
              <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/>
            </path>
          </svg>
    <span id="button-text">{{ 'checkout.valid_and_pay' | translate }}</span>
  </button>
  <div id="payment-message" class="hidden"></div>
</form>
