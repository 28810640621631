import { formatDate, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ServicesSlots } from '../../models/slots';
import { SharedService } from '../../service/shared.service';
import { UserPreferenceService } from '../../service/user-preferences.service';
registerLocaleData(localeFr, 'fr');
@Component({
  selector: 'app-calendar-carousel',
  templateUrl: './calendar-carousel.component.html',
  styleUrls: ['./calendar-carousel.component.scss'],
  providers: [NgbCarouselConfig]
})
export class CalendarCarouselComponent implements OnInit, OnChanges {

  @Input() isMobileResolution: boolean;
  @Input() sportSelected ;
  @Input() slotDate;
  @Input() slots: ServicesSlots[];
  color: string;
  @Output() newDateEvent = new EventEmitter<string>();
  @Output() newSlotEvent = new EventEmitter<ServicesSlots[]>();

  dates: Date [] = [];
  afterChargement = false;
  currentDate = new Date();
  selectedDate = new Date();
  slotin: ServicesSlots;
  lastDate = new Date(this.currentDate.getDate(), 1 );
  displayFullCalendar = false;
  slotSelected: ServicesSlots = null;
  lookingForSlot = false;
  moreSlots: ServicesSlots[] = [];
  fitnessSports = ['aquagym',
    'cardio',
    'danse',
    'muscle-strengthening',
    'relaxation-well-being',
    'team-training', 'fitness'];
  private timeSlot: string;

  constructor(
    private translate: TranslateService,
    private userPreferenceService: UserPreferenceService,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService
  ) {
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.slotDate !== undefined &&  changes.slotDate.currentValue !== ''){
      if (this.slotDate.includes('T')) {
        this.timeSlot = this.slotDate;
      }
      this.selectedDate = new Date(changes.slotDate.currentValue);
      this.onDateSelected(this.selectedDate);
      if (this.slotDate.includes('T')) {
        const service = [];
        const serviceSlots = this.slots.filter(x => x.startDateTime === this.timeSlot);
        serviceSlots.forEach(s => service.push(s.services));
        const slot = new ServicesSlots(this.timeSlot, service );
        this.onSlotSelected(slot);
      }
    }

    if (changes.sportSelected !== undefined &&  changes.sportSelected.currentValue !== ''){
      this.moreSlots = [];
    }


    if (changes.slots && changes.slots.currentValue) {
      if (this.slots !== changes.slots.currentValue){
        this.slots = changes.slots.currentValue;
      }
      if (this.fitnessSports.includes(this.sportSelected)){
        const slots = [];
        this.moreSlots = [];
        this.slots.forEach(slot => {

          const format = 'yyyy-MM-dd';
          const locale = 'fr-FR';
          const formattedDate = formatDate(this.selectedDate, format, locale);
          const date = slot.startDateTime.split('T');
          const i = slots.findIndex( s => s.startDateTime.split('T')[1] === date[1]);
          if (i === -1 && date[0] === formattedDate){
            slots.push(slot);
          } else {
            this.moreSlots.push(slot);
          }
        });
        this.slots = slots;
    }

      if (this.slotDate && this.timeSlot) {
        if (this.timeSlot.split('T')[0] === this.slotDate) {
          const inf = this.slots.find(r => r && r.startDateTime === this.timeSlot);
          if (inf) {
            this.onSlotSelected(inf);
          }
        }
      }
      if (this.timeSlot === undefined || !this.timeSlot.includes('T')) {
        this.selectAllSlot();
      }
      this.lookingForSlot = false;
    }
  }

  ngOnInit(): void {
    this.createDateTabs();
  }

  createDateTabs(): void{
    const date = this.currentDate;
    this.dates.push(new Date(date));
    for ( let i = 0; i < 8; i++) {
      this.dates.push(new Date(date.setDate(date.getDate() + 1)));
    }
  }

  isSelectedDate(date): boolean {
    if (date.getDate() === this.selectedDate.getDate()
      && date.getMonth() === this.selectedDate.getMonth()
      && date.getFullYear() === this.selectedDate.getFullYear()){

      return true;
    }
    return false;

  }

  compareSlot(slotSelected , slot): boolean{
    return slotSelected === slot;
  }

  onDateSelected(date: Date): void {
    this.lookingForSlot = true;
    const format = 'yyyy-MM-dd';
    const locale = 'fr-FR';
    const formattedDate = formatDate(date, format, locale);
    let formattedtoDate = formattedDate;
    if (this.fitnessSports.includes(this.sportSelected)){
      const val = this.dates.findIndex(d => formatDate(d, format, locale) === formatDate(date, format, locale));
      if (val !== -1){
        formattedtoDate = formatDate(this.dates[this.dates.length - 1], format, locale);
        this.selectedDate = date;
        this.newDateEvent.emit(formattedDate + '|' + formattedtoDate);
      }
    } else {
      this.selectedDate = date;
      this.newDateEvent.emit(formattedDate + '|' + formattedtoDate);
    }

  }

  onSlotSelected(slot: ServicesSlots): void {
    if (this.slotSelected === slot ) {
      this.slotSelected = null;
      this.selectAllSlot();
    } else {
      this.slotSelected = slot;
      if (this.fitnessSports.includes(this.sportSelected)){
        let res = this.slots.filter(s => s.startDateTime.split('T')[1] === slot.startDateTime.split('T')[1]);
        res = res.concat(this.moreSlots.filter(s => s.startDateTime.split('T')[1] === slot.startDateTime.split('T')[1]));
        this.newSlotEvent.emit(res);
      } else {
        this.newSlotEvent.emit([...[slot]]);
      }
    }
  }

  getDayFrom(date: Date): string{
    const weekday = new Array(7);
    weekday[0] = 'sunday';
    weekday[1] = 'monday';
    weekday[2] = 'tuesday';
    weekday[3] = 'wednesday';
    weekday[4] = 'thursday';
    weekday[5] = 'friday';
    weekday[6] = 'saturday';
    this.translate.instant('day.' + weekday[date.getDay()]);
    return  this.translate.instant('day.' + weekday[date.getDay()]);
  }

  getMonthFrom(date: Date): string{
    const monthNamesShort = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    const monthNamesShortFr = ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Jul', 'Aout', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (this.userPreferenceService.getCountry() === 'es'){
      return monthNamesShort[date.getMonth()];
    }
    return monthNamesShortFr[date.getMonth()];
  }

  // ------------------------------------------------
  // Utilitaires
  // ------------------------------------------------

  /**
   * Compares two Date objects and returns e number value that represents
   * the result:
   * 0 if the two dates are equal.
   * 1 if the first date is greater than second.
   * -1 if the first date is less than second.
   * @param date1 First date object to compare.
   * @param date2 Second date object to compare.
   */
  compareDate(date1: Date, date2: Date): number {
    const d1 = new Date(date1); const d2 = new Date(date2);


    // Check if the dates are equal
    const same = d1.getTime() === d2.getTime();
    if (same) { return 0; }

    // Check if the first is greater than second
    if (d1 > d2) { return 1; }

    // Check if the first is less than second
    if (d1 < d2) { return -1; }
  }

  selectAllSlot(): void {
    this.slotSelected = null;
    if (this.slots.length === 0 && !this.fitnessSports.includes(this.sportSelected)) {
      this.newSlotEvent.emit([]);
    } else {
      const slotList = this.slots.concat(this.moreSlots);
      const slots = slotList.filter((element): element is ServicesSlots => {
        return element !== null;
      });
      this.newSlotEvent.emit(slots);
    }
  }
}
