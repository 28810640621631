import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AllSessionForm} from '../models/allsessionform';


@Injectable({
  providedIn: 'root',
})

export class AllSessionFormService {
  private headers = new HttpHeaders().set('Content-Type', 'application/json');
  private bookingUrl = environment.bookingApiUrl + '/v1';

  constructor(private http: HttpClient) { }

  getAllSessionForm(reservationToken: string): Observable<AllSessionForm> {
    return this.http.get<AllSessionForm>(`${this.bookingUrl}/allsessionform/${reservationToken}`)
      .pipe(
        map(res => AllSessionForm.fromJson(res))
      );
  }

  create(allSessionForm: AllSessionForm): Observable<any> {

    return this.http.post(`${this.bookingUrl}/allsessionform`, JSON.stringify(allSessionForm),
      { headers: this.headers })
      .pipe(
        tap(res => {
          AllSessionForm.fromJson(res);
          },
          error => console.log(error))
      );
  }
}
