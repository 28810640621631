import { Component, EventEmitter, Injectable, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatetimeService } from '../service/datetime.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import { DATE_I18N_VALUES } from './date-trad';
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private userPreferenceService: UserPreferenceService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return DATE_I18N_VALUES[this.userPreferenceService.getCountry()].weekdays[weekday - 1];
  }
  getWeekdayLabel(weekday: number): string {
    return DATE_I18N_VALUES[this.userPreferenceService.getCountry()].weekdays[weekday - 1];
  }
  getWeekLabel(): string {
    return DATE_I18N_VALUES[this.userPreferenceService.getCountry()].weekLabel;
  }
  getMonthShortName(month: number): string {
    return DATE_I18N_VALUES[this.userPreferenceService.getCountry()].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
@Component({
  selector: 'app-center-calendar',
  templateUrl: './center-calendar.component.html',
  styleUrls: ['./center-calendar.component.scss'],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ]
})
export class CenterCalendarComponent implements OnInit {

  @Output() newDateEvent = new EventEmitter<string>();

  public isOpen = false;
  public model: NgbDateStruct;
  public date: {year: number, month: number};
  public startDate: NgbDateStruct;
  public hours: string;
  public allHours: string[];

  constructor(
    private calendar: NgbCalendar,
    private activateRoute: ActivatedRoute,
    private datetimeService: DatetimeService
  ) {}

  ngOnInit(): void {
    let newDate: Date;
    this.startDate = this.calendar.getToday();
    if (this.activateRoute.snapshot.queryParamMap.get('date')) {
      const dateTime = this.activateRoute.snapshot.queryParamMap.get('date').split('T');
      const [date, slot] = dateTime;
      newDate = new Date(date);
      this.model = this.datetimeService.formatDateStrucModel(this.activateRoute.snapshot.queryParamMap.get('date'));
      this.hours = slot;
    } else {
      newDate = new Date();
      this.model = this.calendar.getToday();
    }
    this.allHours = this.datetimeService.initTimeSlot(newDate);
    if (!this.hours) {
      this.hours = this.allHours[0];
    }
  }

  public openDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  public getDate(): void{
    const date = this.model.year + '-' + this.getvalue(this.model.month) + '-' + this.getvalue(this.model.day) + 'T' + this.hours;
    this.newDateEvent.emit(date);
  }

  public getvalue(val: number): string{
    if (val < 10){
      return '0' + val;
    }
    return '' + val;
  }

  public onTimeChanged(newHour: string): void {
    this.hours = newHour;
  }
}
