import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CityService {

  constructor() { }

  public formatCity(cityFormat: string): string {
    const fullCity = cityFormat.split('-');
    fullCity.pop();
    const city = fullCity.join('-');
    return city;
  }


}
