import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-star-bar-rating',
  templateUrl: './star-bar-rating.component.html',
  styleUrls: ['./star-bar-rating.component.css']
})
export class StarBarRatingComponent implements AfterViewInit{

  @Input() starBarId: string;
  @Input() starNumber: number;

  @Output() starResult = new EventEmitter<number>() ;

  stars = 0;
  list: HTMLCollection;

  constructor() { }

  ngAfterViewInit(): void {
    this.list = document.getElementById(this.starBarId).getElementsByTagName('li');
    this.initStar(this.starNumber);
  }

  fnShow(num): void {
    this.starNumber = num || this.stars;
    for (let i = 0; i < this.list.length; i++) {
      this.list[i].className = i < this.starNumber ? 'light' : '';
    }
  }

  initStar(starNumber): void {
    for (let i = 0; i < this.list.length; i++) {
      this.list[i].className = i < starNumber ? 'light' : '';
      this.stars = starNumber;
    }
  }

  setRateAndSend(rate): void{
    this.stars = rate;
    this.sendBackRate(rate);
  }

  sendBackRate(rate): void{
    this.starResult.emit(rate);
  }

}
