<div class="container">
    <div *ngIf="center && center.bookingRules" class="booking-rules">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11v6h2v-6h-2zm0-4v2h2V7h-2z"/></svg>
        <span class="ms-2">{{center.bookingRules}}</span>
    </div>
</div>

<ng-container>
  <div class="row p-0 mt-2 mx-0">
    <div [ngClass]="{'col-12': isMobileResolution, 'col-4' : !isMobileResolution}">
      <app-activity-availability-filter [sportSelected]="selectedSport" [url]="urlPath"
                                        [centerId]="centerId" (newSportSelectedEvent)="newSportSelected($event)"
      (filterUpdated)="updateAvailabilitiesWithFilter($event)">
      </app-activity-availability-filter>
    </div>

    <div class="h-100" [ngClass]="{'col-12': isMobileResolution, 'col-md-8' : !isMobileResolution, 'mt-2': isMobileResolution}">
      <app-calendar-carousel [sportSelected]="selectedSport" [slotDate]="slotDate" [slots]="servicesSlots" (newDateEvent)="onDateSelected($event)"
                             (newSlotEvent)="onSlotSelected($event)">
      </app-calendar-carousel>
      <app-activity-availability-displayer *ngIf="servicesSlots" [servicesSlots]="servicesSlots" [center]="center" [selectedSlot]="selectedSlot" [serviceInformation]="serviceInformation" [loading]="loading" [centerId]="centerId" ></app-activity-availability-displayer>
    </div>
  </div>
</ng-container>
