<div class="d-flex flex-column login-div">

  <nav class="custom-navbar" [ngClass]="{'mt-4 ms-2 mb-3': isMobileResolution, 'my-5': !isMobileResolution}">
    <div class="row">
      <div  [ngClass]="{'col-9': isMobileResolution, 'col-10': !isMobileResolution}"></div>
      <div class="col" *ngIf="step1">
        <a href="/">
          <img class="navbar-icon" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/close-circle-fill.png" alt="Menu">
        </a>
      </div>
    </div>
  </nav>

  <!-- Formulaire de saisie du numéro de téléphone : STEP 1 -->
  <ng-container *ngIf="step1">
    <div class="d-flex"
         [ngClass]="{'mb-3 justify-content-left': isMobileResolution, 'mt-4 mb-3 justify-content-center': !isMobileResolution}">
      <div class="title mx-auto" [ngStyle]="{'background-color':'#ffc300'}"
           [ngClass]="{' mb-4': isMobileResolution, 'mb-5': !isMobileResolution}">
        {{ 'signup.signup_title' | translate }}
      </div>
    </div>


    <form class="d-flex flex-column" [formGroup]="form" [ngClass]="{'mx-3': isMobileResolution}">

      <input class="custom-input"
             [ngClass]="{'mt-2': isMobileResolution, 'my-2': !isMobileResolution, 'error_input': errorMail}"
             placeholder="{{ 'signup.email' | translate }}" type="email" formControlName="email" required>
      <div class="d-flex align-self-center mx-3 mt-2" *ngIf="errorMail">
        <p class="sublabel error" [ngClass]="{' my-0': isMobileResolution, ' my-2': !isMobileResolution}">
          {{ 'signup.error_mail' | translate }}
        </p>
      </div>

      <p class="sublabel align-self-center mx-4"
         [ngClass]="{' mt-1 mb-2': isMobileResolution, 'mt-1 mb-4': !isMobileResolution}">
        {{ 'signup.email_sublabel' | translate }}
      </p>

      <p class="sublabel" [ngClass]="{'mt-4 mx-3 ': isMobileResolution, 'mt-3 align-self-center sublabel-big': !isMobileResolution}">
        <input type="checkbox" class="form-check-input">
        {{ 'signup.cgv1' | translate }}
        <span class="bold not-underlined">
                    {{ 'signup.accept_and_continue' | translate }}
                </span>
        {{ 'signup.cgv3' | translate }}
        <a href="#" class="yellow">
          {{ 'signup.cgv4' | translate }}
        </a>
        {{ 'signup.cgv5' | translate }}
        <a href="#" class="yellow">
          {{ 'signup.cgv6' | translate }}
        </a>
        {{ 'signup.cgv7' | translate }}
      </p>


    </form>

    <div class="d-flex align-self-center mx-3 mt-2" *ngIf="errorMessage">
      <p class="sublabel error" [ngClass]="{' my-0': isMobileResolution, ' my-2': !isMobileResolution}">
        {{ errorMessage }}
      </p>
    </div>

    <button type="submit" class="fill-btn" [ngStyle]="{'background-color':'#ffc300'}"
            [ngClass]="{'mb-4 mx-3 ': isMobileResolution, 'mt-0': !isMobileResolution}"
            (click)="signup()"
    >
      {{ 'signup.accept_and_continue' | translate }}
    </button>

    <p class="link-text mt-2">
      {{ 'signup.already_signed_in' | translate }}
      <span><a class="bold" [routerLink]="['/' + country, 'login']">{{ 'signup.login' | translate }}</a></span>
    </p>

  </ng-container>
  <!-- Fin du formulaire de saisie du numéro de téléphone : STEP 1 -->


  <!-- Page succes envoi mail : STEP 4 -->
  <div class="d-flex flex-column justify-content-center align-self-center success-send-mail" *ngIf="step2">

    <img class="buddy align-self-center" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/buddy/buddy_165x165.png" alt="Buddy">

    <p class="sublabel mb-4" style="text-align: center;">
      {{ 'signup.sent_mail' | translate }} {{form.controls.email.value}}
    </p>

    <a  [routerLink]="['/login']" class="empty-btn link-btn align-self-center"
        [ngClass]="{'mb-2': isMobileResolution, 'my-0': !isMobileResolution}">
      {{ 'forgotpw.popup_login_link' | translate }}
    </a>

  </div>
  <!-- Fin page succes envoi mail : STEP 4 -->


  <!-- footer -->
  <ng-container *ngIf="step1">
    <h2></h2>

    <app-assistance style=" margin-left: 6%;
" [isMobileResolution]="isMobileResolution"></app-assistance>
  </ng-container>
  <!-- fin footer -->

</div>
