import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Promotion } from '../../models/promotion';
import { BrowserStorageService } from '../../service/storage.service';
import { VoucherService } from '../../service/voucher.service';

@Component({
  selector: 'app-external-code',
  templateUrl: './external-code.component.html',
  styleUrls: ['./external-code.component.scss'],
})
export class ExternalCodeComponent {
  @Input() modal: NgbActiveModal;
  @Input() imgPartner: string;
  @Input() partner: string;
  @Output() voucher = new EventEmitter();
  public error = false;
  public promotionModel: Promotion;
  constructor(
    public voucherService: VoucherService,
    private sessionStorageService: BrowserStorageService
  ) {}

  public onSubmit1(form: NgForm): void {
    if (!form.valid) {
      this.error = true;
    } else {
      this.error = false;
    }
  }

  public onSubmit(form: NgForm): void {
    if (!form.valid) {
      this.error = true;
    } else {
      let promoValue;
      if (form.controls.promo.value === '') {
        promoValue = null;
      } else {
        promoValue = form.controls.promo.value;
      }
      this.voucherService
        .checkVoucher(
          this.partner.toLowerCase(),
          form.controls.id.value,
          promoValue,
          form.controls.mail.value
        )
        .subscribe(
          (code) => {
            this.sessionStorageService.setObject('voucherPromo', {
              information: code.information,
              amount: code.discountAmount,
              operation: code.discountOperation,
            });

            this.promotionModel = new Promotion(
              this.partner.toLowerCase(),
              form.controls.id.value,
              form.controls.promo.value,
              form.controls.mail.value
            );
            this.voucher.emit({ code, promo: this.promotionModel });
            // this.modalService.close()
          },
          (error) => {
            console.log(error.error);
            this.error = error.error.message;
          }
        );
    }
  }
}
