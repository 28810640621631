<div class="d-flex  mt-1 mb-1">
    <img class="star-icon" [ngClass]="{'star-icon-small': small, 'star-icon-medium': medium, 'star-icon-big': big}" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/star-fill.png" alt="rating" *ngIf="!white && !ratingStarsAll">
    <img class="star-icon" [ngClass]="{'star-icon-small': small, 'star-icon-medium': medium, 'star-icon-big': big}" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/star-fill-white.png" alt="rating" *ngIf="white && !ratingStarsAll">

    <div  *ngIf="(ratingCount && ratingCount > 0) ; else no_reviews" [ngClass]="{'d-flex': ratingStarsAll}">

        <span class="rating" [ngClass]="{'text-small': small, 'text-medium': medium, 'text-big': big, 'underline': underline, 'yellow': yellow, 'white': white}">
            {{rating | number : '1.1-1'}}
        </span>

      <div *ngIf="!white && ratingStarsAll" class="ms-1">
        <img class="star-icon" *ngFor='let star of generateArrayFromRate()' [ngClass]="{'star-icon-small': small, 'star-icon-medium': medium, 'star-icon-big': big}"
             src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/{{getStarImgFromNumber(star)}}" alt="rating">
      </div>
      <div *ngIf="white && ratingStarsAll" class="ms-1">
        <img class="star-icon" *ngFor='let star of generateArrayFromRate()' [ngClass]="{'star-icon-small': small, 'star-icon-medium': medium, 'star-icon-big': big}"
             src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/{{getStarImgFromNumber(star)}}" alt="rating">
      </div>

      <!-- <span class="rating-count" [ngClass]="{'grey-text mt-1': small, 'underline': underline, 'white': white}"> -->
        <span class="rating-count"  [ngClass]="{'text-small': small, 'text-medium': medium, 'text-big': big, 'underline': underline, 'yellow': yellow, 'white': white, 'grey': grey}" *ngIf="!veryShort">
            ({{ratingCount}}<span *ngIf="long" > {{'star_rating.reviews' | translate}}</span>)
        </span>
        <!-- <span class="rating-count" [ngClass]="{'grey-text mt-1': small, 'underline': underline}" *ngIf="link-review"> -->
        <span class="rating-count clickable" [ngClass]="{'text-small': small, 'text-medium': medium, 'text-big': big, 'underline': underline, 'yellow': yellow, 'white': white, 'grey': grey}" *ngIf="linkReview">
          {{'star_rating.see_reviews' | translate}}
        </span>
    </div>

    <ng-template #no_reviews>
        <span class="rating ms-1"  [ngClass]="{'text-small': small, 'text-medium': medium, 'text-big': big, 'white': white}">
            {{'star_rating.no_review' | translate}}
        </span>
    </ng-template>
</div>
