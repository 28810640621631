<!-- favorite list -->
<div class="container">
  <div class="header">
    <h1 class="title"> {{ 'my_bookings.my_account' | translate }} </h1>
  </div>
  <hr>
  <div class="title-container mt-5 mb-2">
    <h2 class="sub-title">{{ 'my_preferences.my_preferences_title' | translate }}</h2>
    <p class="text">{{ 'my_preferences.my_preferences_subtitle' | translate }}</p>
  </div>

  <h2 class="mb-3">{{ 'my_preferences.marketing' | translate }}</h2>

  <div class="col-12 col-md-10 p-0 m-0">
    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" class="custom-accordion">
      <ngb-panel title="{{ 'my_preferences.marketing_title' | translate }}">
        <ng-template ngbPanelContent>
          <div>
            <form [formGroup]="marketingForm" class="pb-1 card-padding">
              <div *ngFor="let type of marketingType">
                <input class="custom-control-input" type="checkbox" [value]="type.id" [id]="type.id"
                       (change)="onCheckboxChange($event)" [checked]="isChecked(type.id,marketingForm.controls.selectedMarketingCommunication)"
                       formArrayName="selectedMarketingCommunication">
                <label class="custom-control-label" [htmlFor]="type.id" >{{ ('my_preferences.' + type.text) | translate }}</label>
              </div>
            </form>
            <div class="d-flex">
              <button class="m-auto btn btn-save" (click)="saveMarketingData()">{{ 'my_preferences.save' | translate }}</button>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <p class="text-center" *ngIf="msgMarketing" [ngClass]="errorMsgMarketing ? 'invalid' : 'valid' ">{{msgMarketing}}</p>
  </div>

  <hr>

  <h2 class="mb-3">{{ 'my_preferences.account_management' | translate }}</h2>
  <button class="btn btn-danger" (click)="askAccountDeletion()">{{ 'menuComponent.supressAccount' | translate}}</button>
</div>


<ng-template #deleteAccountModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'menuComponent.deleteAccount' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/></svg>
    </button>
  </div>
  <div class="modal-body">
<p class="text-center">
  {{'menuComponent.supressingMsg' | translate}}
</p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-danger me-auto col-5" (click)="deleteAccount()">{{ 'menuComponent.yes' | translate}}</button>
    <button type="button" class="btn btn-dark ms-auto col-5" (click)="modal.close()">{{ 'menuComponent.no' | translate}}</button>
  </div>
</ng-template>

<ng-template #deleteAwaitingAccount>
  <div class="modal-body">
    <p class="w-100 text-center">{{ 'menuComponent.deletingAccount' | translate}}</p>
    <div class="loader m-auto"></div>
  </div>
</ng-template>

<ng-template #deleteAccountResult class="my-modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{'menuComponent.deleteAccount' | translate}}</h4>
  </div>
  <div class="modal-body">
<p class="text-center">
  {{ errorMsg ? errorMsg : ('menuComponent.deletionSuccess' | translate) }}
</p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-danger m-auto col-5" (click)="closeResultModal()">{{ 'menuComponent.close' | translate}}</button>
  </div>
</ng-template>
