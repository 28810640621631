<div class="modal-body">
    <div class="row">
        <div class="col-xs-12 mt-2 ms-5">
            <div class=" m-0 align-items-center">
                <div class="m-0 expansion-title-spe card-header ">{{'select_tarif.who_comes' | translate}} </div>
                <div class="expansion-title-tarif-st   ">{{'select_tarif.who_comes_st' | translate}} </div>
            </div>
        </div>
    </div>


    <form *ngIf="numerPlaces" (ngSubmit)="nav(servPrice)" [formGroup]="numerPlaces">

        <hr class="mt-2 mb-3" />

        <div class="d-flex align-items-center tarifs-supply"
            *ngFor="let prix of servPrice.prices; let i = index">

            <div class="m-0 expansion-title-tarif card-header ">{{prix.name}} {{prix.price/ 100}} €
                {{servPrice.currency}}

            </div>

            <div class="m-0 number-input  d-inline-block ">

                <button (click)="decrementNbrPlaces(prix.name,prix.price,prix.id,prix.nbPlaces,i)" min="0"
                    type="button"></button>

                <input disabled type="number" class="quantity " min="0" name="num" [value]="0"
                    formControlName="{{prix.name}}">

                <button (click)=" incrementNbrPlaces(prix.name,prix.price,prix.id,prix.nbPlaces,i)" type="button"
                    class=" plus "></button>

            </div>

        </div>
        <hr class="mt-2 mb-3" />




        <div>
            <div class="d-flex block-dimen">
                <div class="card-header block-wi ">{{'select_tarif.place' | translate}}</div>
                <div class=" card-header">{{placePrise}} /{{placeDispo}} </div>
            </div>
        </div>
        <div>
            <div class="d-flex block-wi-1">
                <div class="card-header ">{{'select_tarif.total' | translate}}</div>
                <div class=" card-header">{{total / 100}} € </div>
            </div>
        </div>



    </form>
</div>
<div class="modal-footer validate-block">

    <div>
        <button class="btn-sup" type="submit" (click)="reset(servPrice)">{{'select_tarif.reset' | translate}}</button>
    </div>
    <div>
        <button [disabled]="placePrise < 1" class="validate-btn" [style.background]="placePrise > 0 ? color : ''" type="submit"
            (click)="closeModalWithValidate = true;validateNbrPlaces(servPrice)">{{'select_tarif.validate' |
            translate}}</button>
    </div>

</div>
