export class Paging {

    public static fromJson(json): Paging {
        return new Paging(
            json.hasNextPage,
            json.limit,
            json.next,
            json.previous,
            json.totalItems,
            json.totalPages,
        );
    }

    constructor(
        public hasNextPage: boolean,
        public limit: number,
        public next: boolean,
        public previous: boolean,
        public totalItems: number,
        public totalPages: number,
    ) { }

}
