import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: 'star-rating.component.html',
  styleUrls: ['star-rating.component.scss']
})

export class StarRatingComponent {

  @Input() veryShort: boolean;
  @Input() short: boolean;
  @Input() long: boolean;
  @Input() linkReview: boolean;
  @Input() small: boolean;
  @Input() medium: boolean;
  @Input() big: boolean;
  @Input() underline: boolean;
  @Input() yellow: boolean;
  @Input() grey: boolean;
  @Input() white: boolean;
  @Input() rating: number;
  @Input() ratingCount: number;
  @Input() ratingStarsAll = false;
  src: string;
  constructor() { }

  generateArrayFromRate(): number[]{
    const res: number[] = [];
    let rating = this.rating;
    for (let i = 0; i < 5; i++) {
      rating -= 1;
      if (rating < 0 ){
        if (rating % 1 === 0) {
          res.push(0);
        } else {
          const floatPart = rating % 1;
          res.push(1 - Math.abs(floatPart));
          rating = -1;
        }
      } else{
        res.push(1);
      }
    }
    return res;
  }

  getStarImgFromNumber(rate: number): string {
    if (this.white) {
      switch (rate) {
        case 0:
          return 'star-empty-white.png';
        case 1:
          return 'star-fill-white.png';
        default:
          if (rate < 0.5) {return 'star-empty-white.png'; }
          else {return 'star-half-fill-white.png'; }
      }
    } else {
      switch (rate) {
        case 0:
          return 'star-empty.png';
        case 1:
          return 'star-fill.png';
        default:
          if (rate < 0.5) {return 'star-empty.png'; }
          else {return 'star-half-fill.png'; }
      }
    }
  }
}
