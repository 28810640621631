import { Component, Input, OnInit } from '@angular/core';
import { NgbCalendar, NgbDatepickerI18n, NgbDateStruct, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth.service';
import { CentersService } from 'src/app/service/center.service';
import { DatetimeService, FormatDate } from 'src/app/service/datetime.service';
import { UserPreferenceService } from 'src/app/service/user-preferences.service';
import { CustomDatepickerI18n } from '../../../menu-top/menu-top.component';
import { Center } from '../../../models/center';
import { DATE_I18N_VALUES } from '../../../models/days';
import { AuthFirebaseService } from '../../../service/auth-firebase.service';
@Component({
  selector: 'app-infos-club-no-partner',
  templateUrl: './infos-club-no-partner.component.html',
  styleUrls: ['./infos-club-no-partner.component.scss'],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ]
})
export class InfosClubNoPartnerComponent implements OnInit {

    @Input() center: Center;
    @Input() country: string;

    public numDays: number[];
    public days: string[];
    public isLoggedIn = null;
    public model: NgbDateStruct;
    public startDate: NgbDateStruct;
    public dayDisplay: string;
    public slotSelected: string;
    public hours: string[];
    public error: boolean = null;
    public errMsg = '';

    constructor(
      private userPreferenceService: UserPreferenceService,
      private authService: AuthService,
      private datetimeService: DatetimeService,
      private calendar: NgbCalendar,
      private centerService: CentersService,
      private authFirebaseService: AuthFirebaseService,
    ) {}

    ngOnInit(): void {
      this.isLoggedIn = this.authFirebaseService.getIsLoggedIn;
      if (this.isLoggedIn) {
        this.initCalendareTime();

      }
      const lang = this.userPreferenceService.getLang();
      this.days = DATE_I18N_VALUES[lang].weekdaysLong;
      this.numDays = Object.keys(this.center.openingHours).map(val => parseInt(val, 10));
    }

    private initCalendareTime(): void {
      this.startDate = this.calendar.getToday();
      this.model = this.calendar.getToday();
      const date: string = this.datetimeService.formatDate(this.model);
      this.hours = this.datetimeService.initTimeSlot(new Date());
      const formatDate = this.datetimeService.formatDateDisplay(date, this.userPreferenceService.getLocale(), FormatDate.short);
      this.dayDisplay = formatDate;
      this.slotSelected = this.hours[0];
    }

    public onCalendarOpen(event: boolean, slotDrop: NgbDropdown): void {
      if (event) {
        if (slotDrop.isOpen()) {
          slotDrop.close();
        }
      }
    }

    public onTimeSlotOpen(event: boolean, calendarDrop: NgbDropdown): void {
      if (event) {
        if (calendarDrop.isOpen()) {
          calendarDrop.close();
        }
      }
    }


  onDateChanged(calendarDrop: NgbDropdown): void {
    const today = this.datetimeService.formatDate(this.model);
    this.dayDisplay = this.datetimeService.formatDateDisplay(today, this.userPreferenceService.getLocale(), FormatDate.short);
    this.hours = this.datetimeService.initTimeSlot(new Date(this.model.year, this.model.month, this.model.day));
    calendarDrop.close();
  }

  onTimeChanged(newTime: string, slotDrop: NgbDropdown): void {
    this.slotSelected = newTime;
    slotDrop.close();
  }

  onSubmitDemand(): void {
    if (this.model && this.slotSelected) {
      const date = this.datetimeService.formatDate(this.model);
      this.centerService.requestDemand(this.datetimeService.concatDatetime(date, this.slotSelected), this.center.id).subscribe(
        res => {
          this.error = false;
          this.errMsg = null;
        },
        error => {
          this.error = true;
          this.errMsg = error.message;
        }
      );
    }
  }

  addClubInLocalStorage(): void {
    localStorage.setItem('anybuddy-pro', JSON.stringify({centerId: this.center.id, centerName: this.center.name, dateLimit: new Date()}));
  }

  addUrlInStorage(): void {
    this.authService.setRedirectUrl('/' + this.userPreferenceService.getCountry() + '/club-' + this.center.id);
  }

}
