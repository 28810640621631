import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import {User} from '../models/user-firebase';
import { GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from '@angular/fire/auth';
import { environment } from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: `${environment.host}/complete-sign-up`,
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: 'com.anybuddyapp.anybuddy'
  },
  android: {
    packageName: 'com.anybuddyapp.anybuddy',
    installApp: true,
    minimumVersion: '12'
  },
};

@Injectable({
  providedIn: 'root',
})

export class AuthFirebaseService {
  userData: User; // Save logged in user data
  token: string; // Save token
  isLoggedIn = false; // Save token
  isTokenGetted = new BehaviorSubject(false);
  constructor(
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    afAuth.useDeviceLanguage();
    this.initToken();
  }

  // Sign in with email/password
  SignIn(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password);
  }

  getFireAuth() {
    return this.afAuth;
  }

  getRedirectResult() {
    return this.afAuth.getRedirectResult();
  }

  initToken(): void {
    this.afAuth.idToken.subscribe(token => {
      console.log('logged')
      if(this.router.url.includes('/login')){
        this.router.navigate(['/account'])
      }
      this.isLoggedIn = true;
      this.token = token;
      this.isTokenGetted.next(true);
    },error => {
      console.log('not logged')
      this.isLoggedIn = false;
      this.token = '';
      this.isTokenGetted.next(true);
    })
  }

  initUserData(): void {
    this.afAuth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        console.log('user')
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
      } else {
        console.log('not user')
        this.userData = null;
        localStorage.setItem('user', 'null');
      }
    });
  }


  signInWithCustomToken(token: string) {
    return this.afAuth.signInWithCustomToken(token)
  }

  getUser(): Promise<User>{
    return this.afAuth.currentUser;
  }

  SignUpWithMail(email: string){
    return this.afAuth.sendSignInLinkToEmail(email, actionCodeSettings);
  }


  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u) => u.sendEmailVerification());
  }

  VerifyPasswordResetCode(code) {
    return this.afAuth.verifyPasswordResetCode(code);
  }

  ResetPassword(code: string, password: string){
    return this.afAuth.confirmPasswordReset(code, password);
  }

  VerifyMail(code: string) {
    return this.afAuth.applyActionCode(code);
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail);
  }
  // Returns true when user is looged in and email is verified
  get getIsLoggedIn(): boolean {
    return this.token != null && this.token != '';
  }


  GoogleAuth() {
    return this.afAuth.signInWithRedirect(new GoogleAuthProvider());
  }

  FacebookAuth(){
    return this.afAuth.signInWithRedirect(new FacebookAuthProvider());
  }

  AppleAuth(){
    return this.afAuth.signInWithRedirect( new OAuthProvider('apple.com'));
  }

  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.router.navigate(['dashboard']);
        this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  SetUserData(user: any) {
    this.userData = user;
  }

  SignOut() {
    this.token = '';
    this.isLoggedIn = false;
    this.userData = null;
    localStorage.setItem('user', 'null');
    return this.afAuth.signOut().then(() => {
        console.log('disconnected')
      }, err => {
        console.log(err);
      }
    );
  }

}
