import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NewReservation } from '../models/new-reservation';
import { Reservation } from '../models/reservation';
/* eslint-disable */
@Injectable({
  providedIn: 'root',
})
export class BookingService {
  private headers = new HttpHeaders().set('Content-Type', 'application/json');
  private bookingUrl = environment.bookingApiUrl + '/v1'
  private organiserUrl = environment.organiserApiUrl + '/v1'

  constructor(private http: HttpClient) { }

  checkout(reservation: NewReservation): Observable<Reservation> {
    return this.http.post(`${environment.bookingApiUrl}/v2/reservations`, JSON.stringify(reservation), { headers: this.headers })
    .pipe(
      tap((res: Reservation) => console.log(`added checkout w/ id=${res.id}`))
    );
  }

  addCustomerSource(source: string): Observable<any> {
    return this.http.post(this.bookingUrl + '/customer/sources', JSON.stringify({ source: source }), { headers: this.headers })
    .pipe(
      tap((res: any) => console.log(`added customer w/ id=${res}`))
    );
  }

  getBookingShareLink(resaId): Observable<any> {
    return this.http.get(this.organiserUrl + `/events/${resaId}/share`);
  }

  addParticipant(token: string, referal?: string): Observable<any> {
    let httpParams = ''
    if(referal) {
      httpParams = `?referralUserCode=${referal}`
    }

    return this.http
    .patch(this.organiserUrl +`/invites/events/${token}${httpParams}`, {});
  }
}
