import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { AuthFirebaseService } from '../service/auth-firebase.service';

@Component({
  template: `<h1>Logout</h1>`
})
export class LogoutComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platform,
    public authService: AuthService,
    private router: Router,
    private authFirebaseService: AuthFirebaseService,
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform) && this.authFirebaseService.getIsLoggedIn) {
      this.authFirebaseService.SignOut();
      this.authService.logout();
    }
    this.router.navigate(['/login']);
  }
}
