import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BookingService } from '../service/booking.service';
import { SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';

@Component({
  selector: 'app-succes-resa',
  templateUrl: './succes-resa.component.html',
  styleUrls: ['./succes-resa.component.scss']
})
export class SuccesResaComponent implements OnInit {
  public isPaimentSuccess = false;
  public freeCancellationHours: number;
  public country: string;
  isMobileResolution = false;
  awaitingValidation = false;
  language = '';
  eventId: string;
  link: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userPreferenceService: UserPreferenceService,
    private sharedService: SharedService,
    private deviceService: DeviceDetectorService,
    private bookingService: BookingService,
  ) {

    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
      });
  }

  ngOnInit(): void {
    this.country = '';
    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.country = this.userPreferenceService.getCountry();
    }
    this.country = this.userPreferenceService.getCountry();
    if (this.country !== 'fr') {
      this.language = this.country;
    }
    this.userPreferenceService.getlocalChangeCountry().subscribe(
      value => {
        this.country = value;
        if (this.country !== 'fr') {
          this.language = this.country;
        }
      });
    if (this.route.snapshot.queryParams.awaitingValidation) {
      this.awaitingValidation = true;
    }
    this.isPaimentSuccess = this.route.snapshot.queryParams.redirect_status !== 'failed';
    const slotService = JSON.parse(sessionStorage.getItem('slots'));
    if (slotService) {
      this.freeCancellationHours = slotService.services[0].freeCancellationHours;
    }
    console.log(this.route);
    this.eventId = this.route.snapshot.queryParams.eventId;
    this.bookingService.getBookingShareLink(this.eventId).subscribe(
      value => {
        this.link = value.link;
      }
    );
  }

  Resa(): void{
    this.router.navigate(['/account']);
  }

  mate(): void {
    const btnCopy = document.getElementById( 'copy' );

    navigator.clipboard.writeText(this.link)
      .then(() => {
        btnCopy.classList.add( 'copied' );
        const temp = setInterval(  () => {
          btnCopy.classList.remove( 'copied' );
          clearInterval(temp);
        }, 600 );

      });
  }

  goHome(): void {
    this.router.navigate(['/']);
  }
}
