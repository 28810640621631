import { isPlatformBrowser, Location } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from '../auth.service';
import { AuthFirebaseService } from '../service/auth-firebase.service';
import { SharedService } from '../service/shared.service';
import { CustomValidators } from '../validators/custom-validators';

@Component({
  selector: 'app-resetpwform',
  templateUrl: './resetpwform.component.html',
  styleUrls: ['./resetpwform.component.scss']
})
export class ResetpwformComponent implements OnInit{

  RequestResetForm: UntypedFormGroup;
  disable = false;
  public token: string;
  public visibleOK = false;
  errorMsg: string;

  isMobileResolution = false;

  error = false;
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;

  resetToken: null;
  CurrentState: any;
  IsResetFormValid = true;
  selectedElement: any;
  focused: boolean;
  codeOob: string;

  constructor(
    @Inject(PLATFORM_ID) private platform,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    public authService: AuthService,
    private location: Location,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authFirebaseService: AuthFirebaseService,
  ) {
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();

    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
      });

    this.RequestResetForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],

    });
    this.RequestResetForm = this.createSignupForm();
  }

  ngOnInit(): void {
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.route.queryParams
      .subscribe(params => {
          this.codeOob = params.oobCode;
        }
      );
  }
  ResetPassword(): void {
    this.errorMsg = null;
    const psw = this.RequestResetForm.controls.password.value;
    this.authFirebaseService.VerifyPasswordResetCode(this.codeOob)
      .then( (mail) => {
        this.authFirebaseService.ResetPassword(this.codeOob, psw)
          .then( () => {
              this.router.navigate(['/login']);
            }
          )
          .catch(
            (error) => {
              this.errorMsg = error.message;
            }
          );
      })
      .catch(
        (error) => {
          this.errorMsg = error.message;
        }
      );
  }
  onFocus(event): void{
    if (event){
      this.selectedElement = event.target;
      this.focused = true;
    } else {
      this.selectedElement = null;
      this.focused = false;
    }
  }
  createSignupForm(): UntypedFormGroup {
    return this.formBuilder.group(
      {
        password: [
          null,
          Validators.compose([
            Validators.required,
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        validator: CustomValidators.passwordMatchValidator

      }
    );
  }
  onPreviousPage(): void{
    if (isPlatformBrowser(this.platform)) {
      this.location.back();
    }
  }

}
