import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-more-details',
  templateUrl: './more-details.component.html',
  styleUrls: ['./more-details.component.css'],

})
export class MoreDetailsComponent{
  constructor(
    public activeModal: NgbActiveModal,
    route: ActivatedRoute,
    router: Router, ){}
  static closeCallback: any;
  @Input() src;
  @Input() public session;
  @Input() public selectedSport;

  public onBookOrChooseTarif: Subject<boolean> = new Subject<boolean>();
  openModal: any;

  bookOrChooseTarif(): void {
    this.onBookOrChooseTarif.next(true);
  }
}
