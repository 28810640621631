<!-- LOADER -->
<ng-container>
  <app-loader *ngIf="!dataAvailable"></app-loader>
</ng-container>
<div class="container">
  <div class="header">
    <h1 class="title"> {{ 'my_bookings.my_account' | translate }} </h1>
  </div>
  <div class="horizontal-line"></div>
  <div class="my-reservations mt-5 mb-5">
    <h2 class="sub-title">{{ 'my_bookings.my_reservations' | translate }}</h2>
    <p class="text">{{ 'my_bookings.see_and_cancel_reservations' | translate }}</p>
  </div>
  <div class="tabs">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="activeNav">

      <!-- reservation incoming  -->
      <li [ngbNavItem]="1" class="center-nav-item">
        <a ngbNavLink class="center-nav-link"> {{ 'my_bookings.coming' | translate }} </a>
        <ng-template ngbNavContent>
          <div *ngFor="let resa of reservationsToCome; trackBy: trackByFn" class="reservation-container">
            <div class="reservation">
              <div class="reservation-infos" routerLink="/reservation/{{resa.id}}" routerLinkActive="active">
                <div class="icon-container">
                  <svg class="coupon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                       width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M2 4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v5.5a2.5 2.5 0 1 0 0 5V20a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4zm6.085 15a1.5 1.5 0 0 1 2.83 0H20v-2.968a4.5 4.5 0 0 1 0-8.064V5h-9.085a1.5 1.5 0 0 1-2.83 0H4v14h4.085zM9.5 11a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                  </svg>
                </div>
                <div class="reservation-details">
                  <div class="first-details-container">
                    <span class="text-bold"> {{ resa.center.name }} </span>
                    <span class="dash"> - </span>
                    <span class="text-details">
                                            {{resa.start | date:'shortDate':undefined:lang }}
                      {{ 'my_bookings.at' | translate }}
                      {{resa.start | date: "HH:mm" }}
                                        </span>
                    <span class="dash"> - </span>
                  </div>
                  <div class="second-details-container">
                    <span class="text-details sport"> {{ resa.activityName | titlecase }} </span>
                    <span class="dash" class="dash"> - </span>
                    <span class="text-details"> {{ resa.duration }} min </span>
                  </div>
                </div>
                <div class="price-container">
                  <span class="text-bold"> {{ resa.priceCentsTotal / 100 }} {{ resa.currencyCode }} </span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </div>
              <div *ngIf="isStatusValid(resa.status)" class="btn-reservation txt-btn-reservation" (click)="openConfirm(confirmModal, resa)" (click)="isError = false" (click)="isCancel = false">
                {{ 'my_bookings.want_cancel_reservation' | translate }}
              </div>
              <div *ngIf="!isStatusValid(resa.status)" class="btn-reservation state-reservation">
                                <span>{{ 'my_bookings.reservation_cancelled' |
                                  translate }}</span>
                <svg class="denied" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                    fill="rgba(255,255,255,1)" />
                </svg>
              </div>
            </div>
            <div class="horizontal-line"></div>
          </div>

          <!-- no reservation -->
          <div *ngIf="reservationsToCome.length === 0" class="row no-resa-container">
            <div class="col-12 col-md-2 image-container px-4">
              <img src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/buddy/buddy_basic.svg">
              <h5 class="d-block d-md-none">{{'my_bookings.no_futur_booking_title' | translate}}</h5>
            </div>
            <div class="col-12 col-md-5 text-container mt-3 mt-md-0 px-4">
              <h5 class="d-none d-md-block">{{'my_bookings.no_futur_booking_title' | translate}}</h5>
              <p>{{'my_bookings.no_futur_booking_text' | translate}}</p>
            </div>
            <div class="col-12 col-md-5 button-container mt-3 mb-3 mt-md-0 mb-md-0 px-4">
              <button [routerLink]="'/reservation-sport'" class="col-12 col-md-10">{{'my_bookings.book_sport' | translate}}</button>
            </div>
          </div>

        </ng-template>
      </li>

      <!-- réservation done  -->
      <li [ngbNavItem]="2" class="center-nav-item">
        <a ngbNavLink class="center-nav-link">{{ 'my_bookings.previous' | translate }}</a>
        <ng-template ngbNavContent>
          <div *ngFor="let resa of pastReservations; trackBy: trackByFn" class="reservation-container">
            <div class="reservation">
              <div class="reservation-infos" routerLink="/reservation/{{resa.id}}" routerLinkActive="active">
                <div class="icon-container">
                  <svg class="coupon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                       width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M2 4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v5.5a2.5 2.5 0 1 0 0 5V20a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4zm6.085 15a1.5 1.5 0 0 1 2.83 0H20v-2.968a4.5 4.5 0 0 1 0-8.064V5h-9.085a1.5 1.5 0 0 1-2.83 0H4v14h4.085zM9.5 11a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                  </svg>
                </div>
                <div class="reservation-details">
                  <div class="first-details-container">
                    <span class="text-bold"> {{ resa.center.name }} </span>
                    <span class="dash"> - </span>
                    <span class="text-details">
                                            {{resa.start | date: "dd/MM/y" }}
                      {{ 'my_bookings.at' | translate }}
                      {{resa.start | date: "HH:mm" }}
                                        </span>
                    <span class="dash"> - </span>
                  </div>
                  <div class="second-details-container">
                    <span class="text-details"> {{ resa.activityName | titlecase }} </span>
                    <span class="dash"> - </span>
                    <span class="text-details"> {{ resa.duration }} min </span>
                  </div>
                </div>
                <div class="price-container">
                  <span class="text-bold"> {{ resa.priceCentsTotal / 100 }} {{ resa.currencyCode }} </span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </div>
              <div class="btn-reservation state-reservation">
                                <span *ngIf="isStatusValid(resa.status)">{{ 'my_bookings.reservation_confirmed' |
                                  translate }}</span>
                <span *ngIf="!isStatusValid(resa.status)">{{ 'my_bookings.reservation_cancelled' |
                  translate }}</span>
                <svg *ngIf="isStatusValid(resa.status)" class="valid" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        fill="rgba(255,255,255,1)" />
                </svg>
                <svg *ngIf="!isStatusValid(resa.status)" class="denied"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                    fill="rgba(255,255,255,1)" />
                </svg>
              </div>
            </div>
            <div class="horizontal-line"></div>
          </div>

          <div *ngIf="pastReservations.length === 0" class="row no-resa-container">
            <div class="col-12 col-md-2 image-container px-4">
              <img src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/buddy/buddy_sleep.svg">
              <h5 class="d-block d-md-none">{{'my_bookings.no_past_booking_title' | translate}}</h5>
            </div>
            <div class="col-12 col-md-5 text-container mt-3 mt-md-0 px-4">
              <h5 class="d-none d-md-block">{{'my_bookings.no_past_booking_title' | translate}}</h5>
              <p>{{'my_bookings.no_past_booking_text' | translate}}</p>
            </div>
            <div class="col-12 col-md-5 button-container mt-3 mb-3 mt-md-0 mb-md-0 px-4">
              <button [routerLink]="'/reservation-sport'" class="col-12 col-md-10">{{'my_bookings.book_sport' | translate}}</button>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>

<ng-template #confirmModal let-modal>
  <div class="modal-body">
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="container">
      <ngb-alert *ngIf="isCancel" type="success">{{ 'my_bookings.success_resa_cancel' | translate }}</ngb-alert>
      <div class="title">
        <h4>{{ 'my_bookings.confirm_cancel_reservation' | translate }}</h4>
      </div>
      <div class="cancel-frame">
        <p class="m-0">{{reservationSelectedData.cancellationCondition}}</p>
        <p class="texte-cancel">{{'my_resa.resa_cancel_is' | translate}}
          <span *ngIf="!reservationSelectedData.isCancellable">
            {{ 'my_resa.not_cancellable' | translate }}
          </span>
          <span *ngIf="reservationSelectedData.isCancellable && reservationSelectedData.isCancellableNow">
            {{ 'my_resa.cancellable' | translate }}
          </span>
          <span *ngIf="reservationSelectedData.isCancellable && !reservationSelectedData.isCancellableNow">
            {{ 'my_resa.cancellable_anymore' | translate }}
          </span>
        </p>
      </div>
      <div class="info-resa">
        <div class="first-infos">
          <div class="icon-container space-right">
            <svg class="coupon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                 height="24">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M2 4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v5.5a2.5 2.5 0 1 0 0 5V20a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4zm6.085 15a1.5 1.5 0 0 1 2.83 0H20v-2.968a4.5 4.5 0 0 1 0-8.064V5h-9.085a1.5 1.5 0 0 1-2.83 0H4v14h4.085zM9.5 11a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
            </svg>
          </div>
          <div class="details">
            <span class="text-bold font-big space-right"> {{ reservationSelected.center.name }} </span>
            <span class="dash space-right"> - </span>
            <span class="text-details font-big">
                            {{reservationSelected.start | date: "dd/MM/y" }}
              {{ 'my_bookings.at' | translate }}
              {{reservationSelected.start | date: "HH:mm" }}
                        </span>
          </div>
        </div>
        <div class="second-infos">
          <div>
            <span class="text-details font-big"> {{ reservationSelected.activityName | titlecase }} </span>
            <span class="dash"> - </span>
            <span class="text-details font-big"> {{ reservationSelected.duration }} min </span>
          </div>
          <span class="text-bold font-big"> {{ reservationSelected.priceCentsTotal / 100 }} {{
            reservationSelected.currencyCode }} </span>
        </div>
      </div>
      <div class="btn-confirm">
        <button class="button button-denied" (click)="modal.dismiss('Cross click')"> {{ 'my_bookings.no' |
          translate }} </button>
        <button class="button button-confirm" (click)="cancelReservation(reservationSelected, modal)"> {{
          'my_bookings.yes' | translate }} </button>
      </div>
      <div *ngIf="isError" class="error-cancel">
        <p>{{notCancelableError}}</p>
      </div>
    </div>
  </div>

</ng-template>
