import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Post } from '../models/post';

@Injectable({
    providedIn: 'root'
})
export class WordPressService {

    private wpUrl = 'https://www.anybuddyapp.com';

    constructor(private http: HttpClient) { }
    // type : intro
    loadPosts(type, sport, city): Observable<Post[]> {
        let url;
        if (city){
            url = `${this.wpUrl}/wp-json/wp/v2/pages?slug=embed-${type}-${sport}-${city}`;
        }
         else {
             url = `${this.wpUrl}/wp-json/wp/v2/pages?slug=embed-${type}-${sport}`;
        }
        return this.http.get<Post[]>(url)
        .pipe(
          map(res => res.map(post => Post.fromJson(post)))
        );
    }
}
