<div class="d-flex flex-column login-div mb-5">

    <ng-container *ngIf="isMobileResolution">

        <nav class="custom-navbar mt-4 ms-2 mb-3">
            <div class="row">
                <div class="col-9"></div>
                <div class="col">
                    <a (click)="getHome()">
                        <img class="navbar-icon" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/close-circle-fill.png" alt="Menu">
                    </a>
                </div>
            </div>
        </nav>

        <div class="d-flex flex-column mx-4 mt-4" *ngIf='existe'>
            <div class="expansion-header mb-4 card-title">
                {{'search.what_do_you_want_to_do' | translate}}
            </div>
        </div>

        <div class="d-flex flex-column align-items-center mt-3">
            <nav class="d-flex justify-content-between align-items-center search-div">
                <div class="ms-3">
                    <button class="custom-btn">
                        <img class="icon-nav" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/search-2-line.png"
                            alt="{{'center.back' | translate}}">
                    </button>
                    <input class="searchInput" type="text" name=""
                        placeholder="{{placeholder}}" (focus)="searchFilters($event.target.value)"  (keyup)="searchFilters($event.target.value)" >
                </div>
                <button class="custom-btn searchButton me-3">
                    <img class="icon-nav" [src]="searchImgSrc" alt="{{'center.share' | translate}}"
                        (mouseover)="searchImgSrc = '/assets/images/send-plane-black.png'"
                        (mouseout)="searchImgSrc = '/assets/images/send-plane-grey.png'">
                </button>
            </nav>
        </div>

    </ng-container>


    <div class="d-flex flex-column nav-filters-div mx-3" [ngClass]="{'mt-4': !isMobileResolution, ' mt-5': isMobileResolution}">
        <div class="filter-div mx-3" *ngIf='existe'>

            <div class="d-flex filter-title mb-4">
                {{ 'search.explore' | translate }} Anybuddy
            </div>
            <div class="d-flex justify-content-around">
                <div class="d-flex flex-column" (click)="onSelectFilter('city')">
                    <button class="filter-round-btn mb-1" [ngClass]="{
                        'white-btn': selectedFilter === '' || selectedFilter !== 'city' ,
                        'yellow-btn': selectedFilter === 'city'
                      }">
                        <img class="filter-icon-big" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/map-pin-2-line-grey.png" alt=""
                            *ngIf="selectedFilter === '' || selectedFilter !== 'city'">
                        <img class="filter-icon-big" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/map-pin-2-fill-white.png" alt=""
                            *ngIf="selectedFilter === 'city'">
                    </button>
                    <div class="filter-label" [ngClass]="{
                        'regular': selectedFilter === '' || selectedFilter !== 'city' ,
                        'bold': selectedFilter === 'city'
                      }">
                      {{ 'search.city' | translate }}
                    </div>
                </div>

                <div class="d-flex flex-column" (click)="onSelectFilter('sport')">
                    <button class="filter-round-btn mb-1" [ngClass]="{
                        'white-btn': selectedFilter === '' || selectedFilter !== 'sport' ,
                        'yellow-btn': selectedFilter === 'sport'
                      }">
                        <img class="filter-icon-big" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/ball-icon-grey.png" alt=""
                            *ngIf="selectedFilter === '' || selectedFilter !== 'sport'">
                        <img class="filter-icon-big" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/ball-icon-white.png" alt=""
                            *ngIf="selectedFilter === 'sport'">
                    </button>
                    <div class="filter-label" [ngClass]="{
                        'regular': selectedFilter === '' || selectedFilter !== 'sport' ,
                        'bold': selectedFilter === 'sport'
                      }">
                      {{ 'search.sport' | translate }}
                    </div>
                </div>
                <div class="d-flex flex-column" (click)="onSelectFilter('club')">
                    <button class="filter-round-btn mb-1" [ngClass]="{
                        'white-btn': selectedFilter === '' || selectedFilter !== 'club' ,
                        'yellow-btn': selectedFilter === 'club'
                      }">
                        <img class="filter-icon-big" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/home-4-line-grey.png" alt=""
                            *ngIf="selectedFilter === '' || selectedFilter !== 'club'">
                        <img class="filter-icon-big" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/home-4-fill-white.png" alt=""
                            *ngIf="selectedFilter === 'club'">
                    </button>
                    <div class="filter-label" [ngClass]="{
                        'regular': selectedFilter === '' || selectedFilter !== 'club' ,
                        'bold': selectedFilter === 'club'
                      }">
                      {{ 'search.club' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="d-flex flex-column filters-div mx-3">

        <div *ngIf="isLoadingSearch" style ="margin :auto" class="spinner-border text-warning" role="status">
            <span class="sr-only"></span>
        </div>

        <div *ngIf="isResults === false" class="w-100 d-flex justify-content-center mt-5 pt-5">
        <span> {{ 'search.no_results' | translate }} </span>
        </div>

        <table class="filter-div mx-3 mt-5" *ngIf="selectedFilter === '' || selectedFilter === 'sport'">
            <div class="d-flex filter-title mb-2" *ngIf="sport.length>0">
                {{ 'search.sport' | translate }}
            </div>
            <tbody>
                <tr class="d-flex filter-row align-items-center" *ngFor="let sportInfo of sport">
                    <button class="custom-btn btn button-icon d-flex align-items-center" (click)="searchResult(sportInfo)">
                        <img class="filter-icon me-3" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/ball-icon-grey.png" alt="">
                        {{sportInfo.name}}
                    </button>
                </tr>
            </tbody>
        </table>
        <table class="filter-div mx-3 mt-5" *ngIf="selectedFilter === '' || selectedFilter === 'city'">
            <div class="d-flex filter-title mb-2" *ngIf="cityArray.length>0">
                {{ 'search.city' | translate }}
            </div>
            <tbody>
                <tr class="d-flex filter-row align-items-center" *ngFor="let sportInfo of cityArray">
                    <button class="custom-btn btn button-icon d-flex align-items-center" (click)="searchResultCity(sportInfo.slug)">
                        <img class="filter-icon me-3" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/ball-icon-grey.png" alt="">
                        {{sportInfo.name}}
                    </button>
                </tr>
            </tbody>
        </table>
        <table class="filter-div mx-3 mt-5" *ngIf="selectedFilter === '' || selectedFilter === 'club'">
            <div class="d-flex filter-title mb-2" *ngIf="club.length>0">
                  {{ 'search.club' | translate }}
            </div>
            <tbody>
                <tr class="d-flex filter-row align-items-center" *ngFor="let clubInfo of club">
                    <a [routerLink]="[clubInfo.slug]" >
                        <button class="custom-btn btn button-icon d-flex align-items-center" >
                            <img class="filter-icon me-3" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/home-4-line-grey.png" alt="">
                            {{clubInfo.name}}
                        </button>
                    </a>
                </tr>
            </tbody>
        </table>
    </div>

</div>
