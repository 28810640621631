<!-- LOADER -->
<app-loader *ngIf="!isLoaded"></app-loader>

<app-menu
    [isMobileResolution]="isMobileResolution"
    [goDownloadInfo]="goDownloadInfo"
    [logoYellow]="logoYellow"
    (closeModal)="closeDownload($event)">
</app-menu>

<nav class="custom-navbar navbar navbar-light bg-light justify-content-between" *ngIf="isScroll">
    <img class="logo-anybuddy" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677227178/assets/logo/logo-white.png" alt="Anybuddy">
    <div >
    </div>
    <div class="d-flex justify-content-between ms-auto me-3" *ngIf="!isMobileResolution">
        <button class="navbar-btn" (click)="goDownload()">
            {{'menuComponent.download_app' | translate}}
        </button>
        <button class="navbar-btn">
            <a href="https://pro.anybuddyapp.com/inscription">
                {{'menuComponent.add_your_center' | translate}}
            </a>
        </button>
        <button class="navbar-btn" [routerLink]="['/login']">
            {{'menuComponent.my_account' | translate}}
        </button>
    </div>
    <div class="me-5" *ngIf="!isMobileResolution">
        <app-language></app-language>
    </div>
</nav>

<div class="container">
    <h1 class="title mt-5 mb-5">{{ 'clublist.club_sport' | translate: {sport: sport } }}</h1>
    <div class="club-card-item">
        <div class="card-item" *ngFor="let state of states">
            <div class="state">
                <svg class="icon" style="transform: translateY(-2px);vertical-align: middle;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2483"><path d="M834.7648 319.6928l58.1632-139.8784A55.9104 55.9104 0 0 0 841.3184 102.4H331.3664V51.2A51.2 51.2 0 0 0 280.1664 0 51.2 51.2 0 0 0 228.9664 51.2v737.28A51.2 51.2 0 0 0 280.1664 839.68a51.2 51.2 0 0 0 51.2-51.2V593.92h509.952a55.9104 55.9104 0 0 0 51.6096-77.4144l-58.1632-139.8784a74.1376 74.1376 0 0 1 0-56.9344z m-81.92 171.8272H331.3664V204.8h421.6832a12.4928 12.4928 0 0 1 11.4688 17.2032l-40.96 97.6896a74.1376 74.1376 0 0 0 0 56.9344l40.96 97.6896a12.4928 12.4928 0 0 1-11.4688 17.2032z" fill="#111111" p-id="2484"></path><path d="M382.5664 921.6h-204.8a51.2 51.2 0 0 0 0 102.4h204.8a51.2 51.2 0 0 0 0-102.4z" fill="#2c2c2c" p-id="2485"></path></svg>
                <h2>{{ state }}</h2>
            </div>
            <ul class="list-center">
                <li *ngFor="let center of centerByState[state]" style="font-size: 21px;margin: 10px 0;">
                    <a class="center" [routerLink]="['/' + getPath(center)]"> {{center.name}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="d-flex flex-column section-footer"
    [ngClass]="{'mt-5': isMobileResolution, 'mt-2 mb-3 mx-5 px-5 ': !isMobileResolution}">
    <app-footer [isMobileResolution]="isMobileResolution"></app-footer>
</div>
