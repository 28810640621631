<div class="d-flex flex-column mt-5 calendar-div">
  <div *ngIf="!sportSelected" class="d-flex mt-4">
    <h4 class="m-auto">{{'calendar.select_sport' | translate}}</h4>
  </div>

  <div [class.d-none]="!sportSelected">
    <h2 class="expansion-header card-title" [ngClass]="{'mt-4 mb-5': !isMobileResolution, 'mb-4': isMobileResolution}">
        {{'calendar.disponibility' | translate}}
    </h2>

    <div class="container-fluid calendar-scroll-div mb-3" [ngClass]="{'mt-3': !isMobileResolution}">
        <div class="d-flex flex-row flex-nowrap pb-4">
            <ng-container *ngFor="let date of dates; let i = index">
                <div class="date-col" (click)="onDateSelected(date)"
                    [ngClass]="{'col-1': !isMobileResolution,  'col-2 ': isMobileResolution, 'mx-3':i !=0 && i!= dates.length, 'me-3': i ==0, 'ms-3': i == dates.length }">
                    <button class="day-btn custom-img" [disabled]="false">
                        <div class="d-flex flex-column align-items-center">
                            <p class="day"
                                [ngClass]="{'selected-date': this.isSelectedDate(date), 'selected-date-decathlon': this.isSelectedDate(date), 'mb-2': !isMobileResolution }">
                                {{getDayFrom(date)}}</p>
                            <p class="day-number"
                                [ngClass]="{'selected-date-day': this.isSelectedDate(date), 'selected-date-day-decathlon': this.isSelectedDate(date), 'mb-2': !isMobileResolution  }">
                                {{date | date: 'd' }}
                            </p>
                            <p class="month"
                                [ngClass]="{'selected-date': this.isSelectedDate(date) , 'selected-date-decathlon': this.isSelectedDate(date)}">
                                {{getMonthFrom(date)}}.</p>
                        </div>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>

    <!-- VERSION des slots séparés en 'matin, midi, après midi, soir'  -->
    <ng-container *ngIf="false">
        <div class="d-flex mx-4 mt-2 justify-content-between align-items-center">
            <div class="expansion-title grey-text" style="padding-bottom: 2px;">
                {{'calendar.display_all_slots' | translate}}
            </div>
            <div>
                <form>
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="switch1">
                        <label class="custom-control-label" for="switch1"></label>
                    </div>
                </form>

            </div>
        </div>

        <div class="d-flex flex-column expansion-header justify-content-between mx-4 mt-3 mb-5" *ngIf="selectedDate">
            <ng-container>
                <div class="d-flex justify-content-between">
                    <div class="slot-col">
                        <button class="slot-button" (click)="onSlotSelected('matin')">
                            {{'calendar.morning' | translate}}
                        </button>
                    </div>
                    <div class="slot-col">
                        <button class="slot-button" (click)="onSlotSelected('midi')">
                            {{'calendar.noon' | translate}}
                        </button>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-3">
                    <div class="slot-col">
                        <button class="slot-button slot-button-active" (click)="onSlotSelected('ap')">
                            {{'calendar.afternoon' | translate}}
                        </button>
                    </div>
                    <div class="slot-col">
                        <button class="slot-button" (click)="onSlotSelected('soir')">
                            {{'calendar.evening' | translate}}
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>


    <div *ngIf="selectedDate && lookingForSlot == false">
        <!-- VERSION slots affichés par heure -->
        <div>
            <ng-container *ngIf="slots && slots.length > 0">
                <!-- <div class="d-flex mx-4 mt-2 justify-content-between align-items-center" *ngIf="isMobileResolution">
                    <div class="expansion-title grey-text" style="padding-bottom: 2px;">
                        {{'calendar.display_all_hours' | translate}}
                    </div>
                    <form>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="switch2">
                            <label class="custom-control-label" for="switch2"></label>
                        </div>
                    </form>
                </div> -->
                <div class="d-flex flex-column justify-content-center expansion-header mb-4"
                    *ngIf="slots.length > 0 && !isMobileResolution"
                    [ngClass]="{'mt-5': !isMobileResolution,  'mt-3': isMobileResolution}">
                    <div class="row slot-row " [ngClass]="{'mt-5': !isMobileResolution}">

                        <ng-container>

                            <div class="slot-col">
                                <button class="slot-button-all slot-button-active"
                                    [ngClass]="{'slot-button-active': slotSelected === null}"
                                    (click)="selectAllSlot()">
                                    <div class="d-flex flex-column">
                                      {{ 'calendar.all_disponibility' | translate}}
                                    </div>
                                </button>
                            </div>
                        </ng-container>

                        <ng-container *ngFor="let slot of slots; let i = index">
                            <!--
                            <div class="w-100" *ngIf="isMobileResolution && i % 4 == 0"></div>
                            <div class="w-100" *ngIf="!isMobileResolution && i % 7 == 0"></div>
                            -->
                            <div class="slot-col" *ngIf="slot !== null"
                                [style.visibility]="slot === null? 'hidden': 'visible'">
                                <button class="slot-button-small slot-button-active"
                                    [ngClass]="{'slot-button-active': compareSlot(slotSelected,slot),'slot-button-active': compareSlot(slotSelected,slot)}"
                                    [disabled]="slot === null" (click)="onSlotSelected(slot)">
                                    <div class="d-flex flex-column" *ngIf="slot !== null">
                                        {{slot.startDateTime | date:'shortTime' }}
                                    </div>
                                </button>
                            </div>

                        </ng-container>
                        <div *ngIf="slots.length === 0">{{'calendar.dispoEmpty' | translate}}</div>
                    </div>
                </div>
            </ng-container>
        </div>

        <!-- VERSION des slots affichés par heure dans des onglets 'matin, midi, apres midi, soir' PAS DYNAMIQUE POUR L'INSTANT -->
        <ng-template #golf>
            <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="activeNav"
                [ngClass]="{'mt-5': !isMobileResolution,  'mt-3': isMobileResolution}">

                <li [ngbNavItem]="1" class="center-nav-item">
                    <a ngbNavLink class="center-nav-link">{{'calendar.morning' | translate}}</a>
                    <ng-template ngbNavContent>
                        <ng-container *ngIf="slots.length > 0">
                            <!-- <div class="d-flex mx-4 mt-2 justify-content-between align-items-center"
                                *ngIf="isMobileResolution">
                                <div class="expansion-title grey-text" style="padding-bottom: 2px;">
                                    {{'calendar.display_all_hours' | translate}}
                                </div>
                                <form>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="switch2">
                                        <label class="custom-control-label" for="switch2"></label>
                                    </div>
                                </form>
                            </div> -->
                            <div class="d-flex flex-column justify-content-center expansion-header mx-4 mb-4"
                                *ngIf="slots.length > 0"
                                [ngClass]="{'mt-5': !isMobileResolution,  'mt-3': isMobileResolution}">
                                <div class="row slot-row justify-content-between"
                                    [ngClass]="{'mt-5': !isMobileResolution}">
                                    <ng-container *ngFor="let slot of slots; let i = index">
                                        <div class="w-100" *ngIf="isMobileResolution && i % 4 == 0"></div>
                                        <div class="w-100" *ngIf="!isMobileResolution && i % 7 == 0"></div>
                                        <div class="slot-col" [style.visibility]="slot === null? 'hidden': 'visible'">
                                            <button class="slot-button-small"
                                                [ngClass]="{'slot-button-active': compareSlot(slotSelected,slot),'slot-button-active': compareSlot(slotSelected,slot)}"
                                                [disabled]="slot === null" (click)="onSlotSelected(slot)">
                                                <div class="d-flex flex-column" *ngIf="slot !== null">
                                                    {{slot.startDateTime | date:'shortTime' }}
                                                </div>
                                            </button>
                                        </div>

                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>

                    </ng-template>
                </li>

                <li [ngbNavItem]="2" class="center-nav-item">
                    <a ngbNavLink class="center-nav-link">{{'calendar.noon' | translate}}</a>
                    <ng-template ngbNavContent>
                        <ng-container *ngIf="slots.length > 0">
                            <!-- <div class="d-flex mx-4 mt-2 justify-content-between align-items-center"
                                *ngIf="isMobileResolution">
                                <div class="expansion-title grey-text" style="padding-bottom: 2px;">
                                    {{'calendar.display_all_hours' | translate}}
                                </div>
                                <form>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="switch2">
                                        <label class="custom-control-label" for="switch2"></label>
                                    </div>
                                </form>
                            </div> -->
                            <div class="d-flex flex-column justify-content-center expansion-header mx-4 mb-4"
                                *ngIf="slots.length > 0"
                                [ngClass]="{'mt-5': !isMobileResolution,  'mt-3': isMobileResolution}">
                                <div class="row slot-row justify-content-between"
                                    [ngClass]="{'mt-5': !isMobileResolution}">
                                    <ng-container *ngFor="let slot of slots; let i = index">

                                        <div class="w-100" *ngIf="isMobileResolution && i % 4 == 0"></div>
                                        <div class="w-100" *ngIf="!isMobileResolution && i % 7 == 0"></div>

                                        <div class="slot-col" [style.visibility]="slot === null? 'hidden': 'visible'">
                                            <button class="slot-button-small"
                                                [ngClass]="{'slot-button-active': compareSlot(slotSelected,slot),'slot-button-active': compareSlot(slotSelected,slot) }"
                                                [disabled]="slot === null" (click)="onSlotSelected(slot)">
                                                <div class="d-flex flex-column" *ngIf="slot !== null">
                                                    {{slot.startDateTime | date:'shortTime' }}
                                                </div>
                                            </button>
                                        </div>

                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-template>
                </li>

                <li [ngbNavItem]="3" class="center-nav-item">
                    <a ngbNavLink class="center-nav-link">{{'calendar.afternoon' | translate}}</a>
                    <ng-template ngbNavContent>
                        <ng-container *ngIf="slots.length > 0">
                            <!-- <div class="d-flex mx-4 mt-2 justify-content-between align-items-center"
                                *ngIf="isMobileResolution">
                                <div class="expansion-title grey-text" style="padding-bottom: 2px;">
                                    {{'calendar.display_all_hours' | translate}}
                                </div>
                                <form>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="switch2">
                                        <label class="custom-control-label" for="switch2"></label>
                                    </div>
                                </form>
                            </div> -->
                            <div class="d-flex flex-column justify-content-center expansion-header mx-4 mb-4"
                                *ngIf="slots.length > 0"
                                [ngClass]="{'mt-5': !isMobileResolution,  'mt-3': isMobileResolution}">
                                <div class="row slot-row justify-content-between"
                                    [ngClass]="{'mt-5': !isMobileResolution}">
                                    <ng-container *ngFor="let slot of slots; let i = index">
                                        <div class="w-100" *ngIf="isMobileResolution && i % 4 == 0"></div>
                                        <div class="w-100" *ngIf="!isMobileResolution && i % 7 == 0"></div>
                                        <div class="slot-col" [style.visibility]="slot === null? 'hidden': 'visible'">
                                            <button class="slot-button-small"
                                                [ngClass]="{'slot-button-active': compareSlot(slotSelected,slot),'slot-button-active': compareSlot(slotSelected,slot)}"
                                                [disabled]="slot === null" (click)="onSlotSelected(slot)">
                                                <div class="d-flex flex-column" *ngIf="slot !== null">
                                                    {{slot.startDateTime | date:'shortTime' }}
                                                </div>
                                            </button>
                                        </div>

                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-template>
                </li>

                <li [ngbNavItem]="4" class="center-nav-item">
                    <a ngbNavLink class="center-nav-link">{{'calendar.night' | translate}}</a>
                    <ng-template ngbNavContent>

                        <ng-container *ngIf="slots.length > 0">
                            <!-- <div class="d-flex mx-4 mt-2 justify-content-between align-items-center"
                                *ngIf="isMobileResolution">
                                <div class="expansion-title grey-text" style="padding-bottom: 2px;">
                                    {{'calendar.display_all_hours' | translate}}
                                </div>
                                <form>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="switch2">
                                        <label class="custom-control-label" for="switch2"></label>
                                    </div>
                                </form>
                            </div> -->
                            <div class="d-flex flex-column justify-content-center expansion-header mx-4 mb-4"
                                *ngIf="slots.length > 0"
                                [ngClass]="{'mt-5': !isMobileResolution,  'mt-3': isMobileResolution}">
                                <div class="row slot-row justify-content-between"
                                    [ngClass]="{'mt-5': !isMobileResolution}">
                                    <ng-container *ngFor="let slot of slots; let i = index">
                                        <div class="w-100" *ngIf="isMobileResolution && i % 4 == 0"></div>
                                        <div class="w-100" *ngIf="!isMobileResolution && i % 7 == 0"></div>
                                        <div class="slot-col" [style.visibility]="slot === null? 'hidden': 'visible'">
                                            <button class="slot-button-small"
                                                [ngClass]="{'slot-button-active': compareSlot(slotSelected,slot),'slot-button-active': compareSlot(slotSelected,slot) }"
                                                [disabled]="slot === null" (click)="onSlotSelected(slot)">
                                                <div class="d-flex flex-column" *ngIf="slot !== null">
                                                    {{slot.startDateTime | date:'shortTime' }}
                                                </div>
                                            </button>
                                        </div>

                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>

                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </ng-template>
    </div>
  </div>
</div>
