import { Component, ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-my-preferences',
  templateUrl: './my-preferences.component.html',
  styleUrls: ['./my-preferences.component.scss']
})

@Injectable({
  providedIn: 'root',
})
export class MyPreferencesComponent implements OnInit {

  errorMsg: string;
  msgMarketing: string;
  errorMsgMarketing = false;
  marketingForm: UntypedFormGroup;
  marketingType: Array<any> = [
    { id: 'sms', text: 'by_sms' },
    { id: 'mail', text: 'by_mail' },
    { id: 'pushnotif', text: 'by_push' },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private modal: NgbModal,
    private route: Router,
    private translate: TranslateService,
  ) { }

  @ViewChild('deleteAccountModal') deleteAccountModal: ElementRef;
  @ViewChild('deleteAwaitingAccount') deleteAwaitingAccount: ElementRef;
  @ViewChild('deleteAccountResult') deleteAccountResult: ElementRef;

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.marketingForm = this.fb.group({
      selectedMarketingCommunication:  new UntypedFormArray([])
    });

    this.userService.getUserPreferences().subscribe(
      preferences =>  {
        this.initPreferences(preferences);
      },
      err => console.error('Observer got an error: ' + err),
      () => console.log('Observer got a complete notification')
    );
  }

  initPreferences(preferences: any): void{
    const selectedMarketing = (this.marketingForm.controls.selectedMarketingCommunication as UntypedFormArray);
    this.addInitPreference(selectedMarketing, preferences.mailEnabled, 'mail');
    this.addInitPreference(selectedMarketing, preferences.pushEnabled, 'pushnotif');
    this.addInitPreference(selectedMarketing, preferences.smsEnabled, 'sms');
  }

  updatePreferences(preferences: any): void{
    const selectedMarketing = (this.marketingForm.controls.selectedMarketingCommunication as UntypedFormArray);
    selectedMarketing.clear();
    this.initPreferences(preferences);
  }

  addInitPreference(marketingForm: UntypedFormArray, preference: boolean, value: string): void{
    if (preference === true){
      marketingForm.push(new UntypedFormControl(value));
    }
  }

  onCheckboxChange(event: any): void {
    const selectedMarketing = (this.marketingForm.controls.selectedMarketingCommunication as UntypedFormArray);
    if (event.target.checked) {
      selectedMarketing.push(new UntypedFormControl(event.target.value));
    } else {
      const index = selectedMarketing.controls
        .findIndex(x => x.value === event.target.value);
      selectedMarketing.removeAt(index);
    }
  }

  isChecked(id: any, control: AbstractControl): boolean {
    const formArray = control as UntypedFormArray;
    return formArray.value.includes(id);
  }

  saveMarketingData(): void {
    const data = { smsEnabled: this.marketingForm.controls.selectedMarketingCommunication.value.includes('sms'),
      mailEnabled: this.marketingForm.controls.selectedMarketingCommunication.value.includes('mail'),
      pushEnabled: this.marketingForm.controls.selectedMarketingCommunication.value.includes('pushnotif') };
    this.userService.updateUserPreferences(data).subscribe(
      preferences => {
        this.updatePreferences(preferences);
        this.msgMarketing = this.translate.instant('my_preferences.change_save');
        this.errorMsgMarketing =  false;
      },
      err => {console.error('Observer got an error: ' + err);
        this.msgMarketing =  err.message;
        this.errorMsgMarketing =  true;
      },
    );
  }

  askAccountDeletion(): void {
    this.modal.open(this.deleteAccountModal);
  }

  openModal(template): void {
    this.closeModal();
    this.modal.open(template, {centered: true, windowClass: 'my-modal-content'});
  }

  closeModal(): void {
    this.modal.dismissAll();
  }

  deleteAccount(): void {
    this.userService.deleteUser().subscribe(_ => {
      this.openModal(this.deleteAccountResult);
    },
    error => {
      this.errorMsg = error.message;
      this.openModal(this.deleteAccountResult);
    });
  }

  closeResultModal(): void {
    if (this.errorMsg){
      this.closeModal();
      setTimeout(() => {
        this.errorMsg = '';
      }, 500);
    } else{
      this.closeModal();
      this.logout();
    }
  }

  logout(): void{
    this.route.navigate(['/logout']);
  }
}
