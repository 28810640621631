<div class="d-flex flex-column login-div">

    <nav class="custom-navbar" [ngClass]="{'mt-4 ms-2 mb-3': isMobileResolution, 'my-5': !isMobileResolution}">
        <div class="row">

            <div class="col" (click)="onPreviousPage()">
                <img class="navbar-icon" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677168593/assets/arrow/arrow-left-line.png" alt="Menu">
                <i class="fas fa-arrow-left"></i>
            </div>
        </div>
    </nav>

    <div class="d-flex"
        [ngClass]="{'mt-4 mb-3 justify-content-left': isMobileResolution, 'mt-4 mb-3 justify-content-center': !isMobileResolution}">
        <div class="title" [ngClass]="{'ms-4 mb-4': isMobileResolution, 'mb-5': !isMobileResolution}">
            {{ 'forgotpw.fpw_title' | translate }}
        </div>
    </div>
    <h2 [ngClass]="{'align-self-center': !isMobileResolution}"></h2>
    <p class="link-text">
        {{ 'forgotpw.fpw_mail_text' | translate }}
        {{ 'forgotpw.fpw_link_text' | translate }}
    </p>
    <form class="d-flex flex-column">

        <input class="form-control form-control-lg custom-input"
            [ngClass]="{'my-2': isMobileResolution, 'my-4': !isMobileResolution}"
            placeholder="{{ 'forgotpw.email' | translate }}" id="email" type="email" [formControl]="email" required>
                    <div class="error align-self-center" *ngIf="email.invalid && (email.dirty || email.touched)">{{ 'forgotpw.email-required' | translate }}</div>
                    <div class="error align-self-center" *ngIf="this.errorMessage && !this.email.hasError('required') "> {{ 'forgotpw.error_mail' | translate }}</div>
                    <button (click)="open()" [ngStyle]="{'background-color':'#ffc300'}" class="fill-btn" [ngClass]="{'mb-2': isMobileResolution, 'mb-4': !isMobileResolution} " >
            {{ 'forgotpw.fpw_send_mail' | translate }}</button>
    </form>

    <div *ngIf="false">
</div>
    <h2 [ngClass]="{'my-5 align-self-center': !isMobileResolution}"></h2>

    <app-assistance style="
    /* align-items: center; */
    margin-left: 9%;
" [isMobileResolution]="isMobileResolution"></app-assistance>
</div>

<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-body">
        <div class="title-popup">
            {{ 'forgotpw.send_link_succes' | translate }}
    </div>
    </div>
    <p class="link-text-popup" (click)="d('Cross click')">
        <span><a class="bold" (click)="open()" >{{ 'forgotpw.popup_link' | translate }}</a></span>
    </p>
    <p class="link-text-popup" (click)="d('Cross click')">
        <span><a class="bold" [routerLink]="['/' + country, 'login']">{{ 'forgotpw.popup_login_link' | translate }}</a></span>
    </p>
</ng-template>
