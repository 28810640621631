import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from '../auth.service';
import { User } from '../models/user';
import { AuthFirebaseService } from '../service/auth-firebase.service';
import { SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  isMobileResolution = false;
  firstConnexion = false;
  hasLogin = true;
  user: User;
  listReservation: any;
  country = '';

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private userPreferenceService: UserPreferenceService,
    private authFirebaseService: AuthFirebaseService,
  ) {
    if (this.authService.getRedirectUrl()) {
      const redirectUrl = this.authService.getRedirectUrl();
      this.authService.cleanRedirectUrl();
      this.router.navigate([redirectUrl]);
    }
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
          this.isMobileResolution = isMobileResolution;
      });
  }

  ngOnInit(): void {
    this.firstConnexion = false;
    if (!this.authFirebaseService.getIsLoggedIn) {
      this.router.navigate(['/login']);
    }else{
      this.hasLogin = true;
      this.getUser();
    }
    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.country = this.userPreferenceService.getCountry();
    }
  }

  getUser(): void{
    this.userService.getMe().subscribe(
      res => {
        this.user = res;
      },
      error => { console.log(error); }
    );
  }
}
