<!-- social networks  -->
<div class="d-flex" [ngClass]="{'mt-3 mx-3': isMobileResolution, 'mb-2 mt-3 mx-5': !isMobileResolution}">
    <div class="me-auto logo"  >
        <a class="section-5-title" [routerLink]="['/']">ANYBUDDY</a>
    </div>

    <div class="d-flex flex-column">
        <div>
            <a href="https://www.instagram.com/anybuddy_app" *ngIf="languageFooter==='fr'" target="_blank" rel="noopener noreferrer" >
                <img class="social-network-icon" loading="lazy" alt="Instagram" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677168593/assets/social/instagram-icon.png">
            </a>
            <a href="https://www.instagram.com/anybuddy_espana/" *ngIf="languageFooter==='es'" target="_blank" rel="noopener noreferrer" >
                <img class="social-network-icon" loading="lazy" alt="Instagram" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677168593/assets/social/instagram-icon.png">
            </a>
            <a href="https://www.facebook.com/anybuddyapp" *ngIf="languageFooter==='fr'"  target="_blank" rel="noopener noreferrer">
                <img class="social-network-icon" loading="lazy" alt="Facebook" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677168593/assets/social/facebook-icon.png">
            </a>
            <a href="https://www.facebook.com/anybuddyapp.es" *ngIf="languageFooter==='es'"  target="_blank" rel="noopener noreferrer">
                <img class="social-network-icon" loading="lazy" alt="Facebook" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677168593/assets/social/facebook-icon.png">
            </a>
            <a href="https://www.linkedin.com/company/anybuddy" *ngIf="languageFooter==='fr'" target="_blank" rel="noopener noreferrer">
                <img class="social-network-icon" loading="lazy" alt="Linkedin" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677168593/assets/social/linkedin-icon.png">
            </a>
            <a href="https://twitter.com/anybuddy_app" *ngIf="languageFooter==='fr'" target="_blank" rel="noopener noreferrer">
                <img class="social-network-icon" loading="lazy" alt="Twitter" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677168593/assets/social/twitter-icon.png">
            </a>
        </div>
        <div class="align-self-end follow-us-text me-1 mt-2">
            {{ 'footerComponent.follow_us' | translate }}
        </div>
    </div>
</div>

<div *ngIf="isMobileResolution" class="mx-3" (click)="openLangageModal(content)">
  <div class="d-flex" style="text-align: left">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="35" height="35"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z"/></svg>
    <div class="my-auto">
      <span class="font-weight-bold" style="font-size: 1.3rem">
      {{('languagesCountriesLang.' + languageChoosed.languageCode) | translate}} ({{ languageChoosed.trueLanguageCode | uppercase }})
    </span>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
      <button type="button" class="btn-close p-0" (click)="modal.dismiss('Cross click')">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/></svg>
      </button>
  </div>
  <div class="modal-body">
    <app-language [footer]="true"></app-language>
  </div>
</ng-template>

<!-- accordeon  -->
<div class="d-flex flex-column mt-2 mx-2 mb-3" *ngIf="isMobileResolution; else footerDesktop">
    <ngb-accordion #acc="ngbAccordion" activeIds="toggle-1, toggle-2, toggle-3">
        <ngb-panel id="toggle-1">

            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between expansion-header">
                    <div class="m-0 expansion-title">{{ 'footerComponent.about_us' | translate }}</div>
                </div>
            </ng-template>

            <ng-template ngbPanelContent>
                <div class="row expansion-content-div" style="flex-wrap: wrap;">
                    <div class="col expansion-contient-col">
                        <a class="footer-link" *ngIf="languageFooter==='fr'" href="https://www.anybuddyapp.com/a-propos/" target="_blank">
                            {{ 'footerComponent.who_are_we' | translate }}
                        </a>
                    </div>
                    <div class="w-100" *ngIf="isMobileResolution"></div>
                    <div class="col expansion-contient-col">
                        <a class="footer-link" *ngIf="languageFooter==='fr'" href="https://support.anybuddyapp.com/" target="_blank">
                            {{ 'footerComponent.contact' | translate }}
                        </a>
                        <a href="https://support.anybuddyapp.com/es/" *ngIf="languageFooter==='es'" class="footer-link" target="_blank">{{ 'footerComponent.contact' | translate }}</a>
                    </div>
                    <div class="w-100"></div>
                    <div class="col expansion-contient-col">
                        <a class="footer-link" *ngIf="languageFooter==='fr'" href="https://www.anybuddyapp.com/conditions-generales/" target="_blank">
                            {{ 'footerComponent.general_terms' | translate }}
                        </a>
                        <a class="footer-link" *ngIf="languageFooter==='es'" href="https://www.anybuddyapp.com/es/condiciones-generales/" target="_blank">
                            {{ 'footerComponent.general_terms' | translate }}
                        </a>
                    </div>
                    <div class="w-100" *ngIf="isMobileResolution"></div>
                    <div class="col expansion-contient-col">
                        <a class="footer-link" *ngIf="languageFooter==='fr'" href="https://www.anybuddyapp.com/espace-presse/" target="_blank">
                            {{ 'footerComponent.press_release' | translate }}
                        </a>
                    </div>
                    <div class="w-100"></div>
                    <div class="col expansion-contient-col">
                        <a class="footer-link" *ngIf="languageFooter==='fr'" href="https://support.anybuddyapp.com/fr/" target="_blank">
                            {{ 'footerComponent.faq' | translate }}
                        </a>
                        <a class="footer-link" *ngIf="languageFooter==='es'" href="https://support.anybuddyapp.com/es/" target="_blank">
                            {{ 'footerComponent.faq' | translate }}
                        </a>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="toggle-2">

            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between expansion-header">
                    <div class="m-0 expansion-title">{{ 'footerComponent.become_partner' | translate }}</div>
                </div>
            </ng-template>

            <ng-template ngbPanelContent>
                <div class="row expansion-content-div" style="flex-wrap: wrap;">
                    <div class="col expansion-contient-col">
                        <a class="footer-link" href="https://pro.anybuddyapp.com/inscription">
                            {{ 'footerComponent.add_your_center' | translate }}
                        </a>
                    </div>
                    <div class="w-100" *ngIf="isMobileResolution"></div>
                    <div class="col expansion-contient-col">
                        <a class="footer-link" *ngIf="languageFooter==='fr'" href="https://www.anybuddyapp.com/clubs-partenaires/">
                            {{ 'footerComponent.our_partnairs' | translate }}
                        </a>
                    </div>
                    <div class="w-100"></div>
                    <div class="col expansion-contient-col" *ngIf="languageFooter==='fr'">
                        <a class="footer-link" href="https://www.anybuddyapp.com/top-7-des-logiciels-de-reservations/">
                            {{ 'footerComponent.top_systems' | translate }}
                        </a>
                    </div>
                    <div class="w-100" *ngIf="isMobileResolution"></div>
                    <div class="col expansion-contient-col" *ngIf="languageFooter==='fr'">
                        <a class="footer-link" href="https://www.anybuddyapp.com/les-controles-dacces-pour-votre-club/">
                            {{ 'footerComponent.access_control' | translate }}
                        </a>
                    </div>
                </div>

            </ng-template>
        </ngb-panel>

        <ngb-panel id="toggle-4" *ngIf="languageFooter==='fr'">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between expansion-header">
                    <div class="m-0">
                        <a class="expansion-title text-dark" href="https://www.anybuddyapp.com/blog/">
                            {{ 'footerComponent.blog' | translate }}
                        </a>
                    </div>
                </div>
            </ng-template>

            <ng-template ngbPanelContent>
                <div class="row expansion-content-div">
                    <div class="col expansion-contient-col">
                        <a class="footer-link" href="https://www.anybuddyapp.com/blog/">
                            {{ 'footerComponent.blog' | translate }}
                        </a>
                    </div>
                </div>

            </ng-template>
        </ngb-panel>

      <ngb-panel id="toggle-3" *ngIf="languageFooter==='fr'">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-between expansion-header">
            <div class="m-0 expansion-title">{{ 'footerComponent.we_hire' | translate }}</div>
          </div>
        </ng-template>

        <ng-template ngbPanelContent >
          <div class="row expansion-content-div" style="flex-wrap: wrap;">
            <div class="col expansion-contient-col">
              <a class="footer-link" href="https://www.indeedjobs.com/anybuddy">
                {{ 'footerComponent.we_hire' | translate }}
              </a>
            </div>
          </div>

        </ng-template>
      </ngb-panel>
    </ngb-accordion>
</div>

<ng-template #footerDesktop>
    <div class="d-flex flex-column mx-3 mt-5 mb-5">
        <div class="row">
            <div class="col-12">
                <div class="column-stack-footer">
                    <ul class="ul-footer">
                        <li class="li-header-footer">{{ 'footerComponent.about_us' | translate }}</li>
                        <li><a href="https://www.anybuddyapp.com/a-propos/" *ngIf="languageFooter==='fr'" class="footer-link">{{ 'footerComponent.who_are_we' | translate }}</a></li>
                        <li><a href="https://support.anybuddyapp.com/fr/" *ngIf="languageFooter==='fr'" class="footer-link">{{ 'footerComponent.contact' | translate }}</a>
                            <a href="https://support.anybuddyapp.com/es/" *ngIf="languageFooter==='es'" class="footer-link">{{ 'footerComponent.contact' | translate }}</a></li>
                        <li>
                            <a href="https://www.anybuddyapp.com/conditions-generales/" *ngIf="languageFooter==='fr'" class="footer-link">{{ 'footerComponent.general_terms' | translate }}</a>
                            <a href="https://www.anybuddyapp.com/es/condiciones-generales/" *ngIf="languageFooter==='es'" class="footer-link">{{ 'footerComponent.general_terms' | translate }}</a>
                        </li>
                        <li><a href="https://www.anybuddyapp.com/espace-presse/" *ngIf="languageFooter==='fr'" class="footer-link">{{ 'footerComponent.press_release' | translate }}</a></li>
                        <li>
                            <a href="https://support.anybuddyapp.com/fr/" *ngIf="languageFooter==='fr'" class="footer-link">{{ 'footerComponent.faq' | translate }}</a>
                            <a href="https://support.anybuddyapp.com/es/" *ngIf="languageFooter==='es'" class="footer-link">{{ 'footerComponent.faq' | translate }}</a>
                        </li>
                    </ul>
                    <ul class="ul-footer">
                        <li class="li-header-footer">{{ 'footerComponent.become_partner' | translate }}</li>
                        <li><a href="https://pro.anybuddyapp.com/inscription" class="footer-link">{{ 'footerComponent.add_your_center' | translate }}</a></li>
                        <li><a href="https://www.anybuddyapp.com/clubs-partenaires/" *ngIf="languageFooter==='fr'" class="footer-link">{{ 'footerComponent.our_partnairs' | translate }}</a></li>
                        <li><a href="https://www.anybuddyapp.com/top-7-des-logiciels-de-reservations/" *ngIf="languageFooter==='fr'" class="footer-link">{{ 'footerComponent.top_systems' | translate }}</a></li>
                        <li><a href="https://www.anybuddyapp.com/les-controles-dacces-pour-votre-club/" *ngIf="languageFooter==='fr'" class="footer-link">{{ 'footerComponent.access_control' | translate }}</a></li>
                    </ul>
                    <ul class="ul-footer" *ngIf="languageFooter==='fr'">
                      <li class="li-header-footer">{{ 'footerComponent.blog' | translate }}</li>
                      <li><a href="https://www.anybuddyapp.com/blog/" class="footer-link">{{ 'footerComponent.blog' | translate }}</a></li>
                    </ul>
                    <ul class="ul-footer" *ngIf="languageFooter==='fr'">
                        <li class="li-header-footer">{{ 'footerComponent.we_hire' | translate }}</li>
                        <li><a href="https://www.indeedjobs.com/anybuddy" target="_blank" class="footer-link">{{ 'footerComponent.we_hire' | translate }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col"></div>
        </div>
    </div>
</ng-template>

<!-- download app  -->
<div class="d-flex flex-column" [ngClass]="{'mx-2 my-5': isMobileResolution, 'mx-5 mb-3': !isMobileResolution}">

    <div class="d-flex download-app-text" [ngClass]="{'mb-2': isMobileResolution, 'mb-3': !isMobileResolution}">
        {{ 'footerComponent.download_the_app' | translate }}
    </div>

    <div class="d-flex" [ngClass]="{'justify-content-around': isMobileResolution}"  style="margin-bottom: 9%;">
        <a href="{{'store.app_store_link' | translate}}" target="_blank" rel="noopener noreferrer"
            >
            <img class="store-img-footer" alt="App Store" loading="lazy" src="{{'store.app_store_badge' | translate}}">
        </a>
        <a href="{{'store.play_store_link' | translate}}" target="_blank"
            rel="noopener noreferrer">
            <img class="store-img-footer" alt="Play Store" loading="lazy" src="{{'store.play_store_badge' | translate}}">
        </a>
    </div>
</div>
