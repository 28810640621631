import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-mailing-list',
  templateUrl: './mailing-list.component.html',
  styleUrls: ['./mailing-list.component.css']
})
export class MailingListComponent implements OnInit {

  email: string;
  isDeleted: boolean = null;
  unsubscribeForm = this.formBuilder.group({
    email: ''
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    console.warn(this.activatedRoute.snapshot.queryParams.email);
    this.unsubscribeForm.patchValue({email : this.activatedRoute.snapshot.queryParams.email});
  }

  onSubmit(): void {
    console.warn('Your order has been submitted', this.unsubscribeForm.value);
    this.userService
    .removeEmailFromMailingList(this.unsubscribeForm.value)
    .subscribe(
      x => this.isDeleted = true,
      err => this.isDeleted = false,
      () => {
        this.isDeleted = null;
        this.unsubscribeForm.reset();
      }
    );
  }

}
