<carousel *ngIf="isBrowser" [arrowsOutside]="arrowOutside" [cellsToShow]="cardShown" class="carousel-custom">
  <div class="carousel-cell" *ngFor="let review of presseReviews">
    <div class="cell">
      <div class="d-flex header-img">
        <img [src]="review.icon" alt="{{review.author}}">
      </div>
      <div class="d-flex content">
        <div class="d-flex quote">
          <img src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/Icone_guillemets.svg">
        </div>
        <div>
          <p class="d-flex caption-review-content">{{review.review}}</p>
        </div>
      </div>
    </div>
  </div>
</carousel>
