import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Feedback } from '../models/feedback';
import { FeedbackService } from '../service/feedback.service.';
import { UserPreferenceService } from '../service/user-preferences.service';

@Component({
  selector: 'app-feedback',
  templateUrl: 'feedback.component.html',
  styleUrls: ['feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  feedback: Feedback;
  id: string;
  hasSavingPhasePassed = false;
  hasFullFiveStars = false;
  selectedLanguage: string;
  error = false;

  constructor(
    private feedbackService: FeedbackService,
    private route: ActivatedRoute,
    private userPreferenceService: UserPreferenceService
  ) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.feedbackService.getFeedback(id).subscribe(
        (feedback: Feedback) => {
          this.feedback = feedback;
        },
        error => {
          this.feedback = new Feedback(id, null, null, null, null);
        }
      );
    }
    this.selectedLanguage = this.userPreferenceService.getLang();
  }

  hasFullFiveStarsFeedback(): void {
    if (this.feedback.serviceRating === 5 && this.feedback.clubRating === 5 && this.feedback.anybuddyRating === 5) {
      this.hasFullFiveStars = true;
    }
  }

  publish(currentId: string): void {
    this.hasFullFiveStarsFeedback();
    this.saveFeedback(currentId);
    this.setEndStyle();
  }

  saveFeedback(elementToHide: string): void {
    this.feedbackService.create(this.feedback).subscribe(
      value => {
        this.hasSavingPhasePassed = true;
        document.getElementById(elementToHide).hidden = true;
      },
      err => {
        this.hasSavingPhasePassed = true;
        this.error = true;
        document.getElementById(elementToHide).hidden = true;
        document.getElementById('error').hidden = false;

      }
    );
  }

  setEndStyle(): void {
    document.getElementById('page').classList.add('pageFinal');
    document.getElementById('card').classList.add('cardFinal');
  }

  showNext(currentId: string, nextId: string): void{
    document.getElementById(currentId).hidden = true;
    document.getElementById(nextId).hidden = false;
  }

  showPrevious(currentId: string, previousId: string): void{
    document.getElementById(currentId).hidden = true;
    document.getElementById(previousId).hidden = false;
  }
}
