<div class="d-flex flex-column mx-4" [ngClass]="{'mt-4 mb-5': !isMobileResolution,  'mb-4 ': isMobileResolution}">
    <h2 class="expansion-header card-title"
        [ngClass]="{'mb-1': !isMobileResolution,  'mb-3 ': isMobileResolution}" *ngIf="!hideTitle">
        {{'center.location' | translate}}
    </h2>

  <a href="{{googleMapLink}}" target="_blank" class="d-flex grey-text underline"
     [ngClass]="{'mt-4': !isMobileResolution,  'mt-1 ': isMobileResolution}">
    <div class="d-flex justify-content-between mb-3">
      <img class="map-icon-img custom-img align-self-center"
           [ngClass]="{'me-3': !isMobileResolution,  'me-2 ': isMobileResolution}"
           src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/icon/map-pin-line.png" alt="Google Maps">
      <h5 class="card-subtitle align-self-center">
        {{center.address.line1 | titlecase }} {{center.address.postalCode}}
        {{center.address.city | titlecase}} </h5>
    </div>
  </a>

    <div class="map-div">
        <div class="map-container">
            <div class="map-border">
                <div id="map"></div>
            </div>
        </div>
    </div>


</div>
