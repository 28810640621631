import { Promotion } from '../models/promotion';
import { Places } from './places';

export class NewReservation {
  public paymentMethodType: string;
  constructor(
    public centerId: string,
    public slotId: string,
    public activityId: string,
    public serviceId: string,
    public price: number,
    public currency: string,
    public fullPrice: number,
    public vouchers: Promotion,
    public places: Places[],
    public serviceFee: number,
    public automaticPayment= true
  ) { }
}
