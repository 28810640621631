import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthFirebaseService } from '../service/auth-firebase.service';
import { SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';

export class PhoneNumber {
  country = '33';
  line: string;
  // format phone numbers as E.164
  get e164(): string {
    const num = this.line;
    return `${num}`.replace(/ /g, '');
  }
}
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  isMobileResolution = false;

  form = this.formBuilder.group({
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      newletterBox: new UntypedFormControl(null)
    });

  phone = new UntypedFormControl({ value: '', disabled: false }, [Validators.required]);

  errorMessage: any;
  error = false;
  errorPhone = false;
  errorFirstname = false;
  errorName = false;
  errorMail = false;
  errorPassword = false;
  errorPasswordServer = false;
  errorPasswordMismatch = false;
  step1 = true;
  step2 = false;

  hasError: boolean;
  isCodeTyped: boolean;
  isValid: boolean;
  sendCode: boolean;
  country: string;

  constructor(
    private deviceService: DeviceDetectorService,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private userPreferenceService: UserPreferenceService,
    private authFirebaseService: AuthFirebaseService,
  ) {
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();

    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
      });

  }

  ngOnInit(): void {
    this.country = '';
    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.country = this.userPreferenceService.getCountry();
    }
  }

  signup(): void {
    this.error = false;
    this.errorMail = false;

    if (this.form.controls.email.hasError('email') ||
      this.form.controls.email.hasError('required')
    ) {
      this.error = true;
      this.errorMail = true;
    }

    if (this.error) { return; }

    this.authFirebaseService.SignUpWithMail(this.form.controls.email.value)
      .then((r) => {
        this.step1 = false;
        this.step2 = true;
      })
      .catch((e) => {
        this.error = true;
        this.errorMessage = e.message;
      });
  }
}
