import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() isMobileResolution: boolean;

  languageChoosed;

  public languageFooter: string;
  constructor(
    private sharedService: SharedService,
    private userPreferenceService: UserPreferenceService,
    private ngbModale: NgbModal,
  ) {
  //  this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
  this.languageFooter = this.userPreferenceService.getCountry();
  }

  ngOnInit(): void {
   this.languageFooter = this.userPreferenceService.getCountry();
   this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
          this.isMobileResolution = isMobileResolution;
      });
   this.userPreferenceService.getChoosedLanguage().subscribe(l => {
      this.languageChoosed = l;
    });
  }

  openLangageModal(modal: TemplateRef<any>): void {
    this.ngbModale.open(modal, {windowClass: 'custom-modal'});
  }
}
