import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Center } from '../models/center';
import { SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';
@Component({
  selector: 'app-availabilities',
  templateUrl: './availabilities.component.html',
  styleUrls: ['./availabilities.component.scss']
})
/* eslint-disable */
export class AvailabilitiesComponent implements OnInit, AfterViewInit {

  @ViewChild('slider', { static: false }) content;

  @Input() isMobileResolution: boolean;
  @Input() slots;
  @Input() center: Center;
  @Input() sportSelected;
  @Input() centerService;
  @Input() currency;
  @Output() newDateEvent = new EventEmitter<string>();
  public dates: Date[] = [];
  public selectedDate = new Date();
  public isScrollable = false;
  public country: string;
  public translatePathBooking: string | any[];

  private currentDate = new Date();

  constructor(
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private localize: LocalizeRouterService,
    private userPreferenceService: UserPreferenceService
  ) {
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe(
      (isMobileResolution: boolean) => {
        this.isMobileResolution = isMobileResolution;
      });
  }
  getMinPrice(service:[]): number {
    const price =Math.min.apply(null, service.map(function(o) { return o['price']; }));
    return price
  }
  ngOnInit(): void {
    this.translatePathBooking = this.localize.translateRoute(`booking`);
    this.country = '';
    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.country = this.userPreferenceService.getCountry();
    }
    if (this.slots !== undefined) {
      this.createDateTabs();
    }
  }

  ngAfterViewInit(): void {
    if (this.content) {
      if (this.content.nativeElement.scrollWidth > this.content.nativeElement.clientWidth) {
        this.isScrollable = true;
      }
    }
  }

  getHours(date: string): string {
    if (date !== undefined) {
      const today = date.slice(11, date.length);
      return today;
    }
  }
  private createDateTabs(): void {
    const date = this.currentDate;
    this.dates.push(new Date(date));
    // pour un affiche justifié
    for (let i = 0; i < 10; i++) {
      this.dates.push(new Date(date.setDate(date.getDate() + 1)));
    }
  }
  getPrice(slotId): string {
    const serviceElement = this.centerService.find(r => r.id === slotId.id);
    return serviceElement.price + '' + this.currency;
  }

  onPrev(): void {
    const element = this.content.nativeElement;
    element.scrollLeft = element.scrollLeft - (this.content.nativeElement.clientWidth + 20);
  }

  onNext(): void {
    const element = this.content.nativeElement;
    element.scrollLeft = element.scrollLeft + (this.content.nativeElement.clientWidth - 20);
  }
}
