import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AllSessionForm } from '../models/allsessionform';
import { AllSessionFormService } from '../service/allsessionform.service';

@Component({
  selector: 'app-allsessionform',
  templateUrl: 'allsessionform.component.html',
  styleUrls: ['allsessionform.component.scss']
})
export class AllsessionformComponent implements OnInit {

  allSessionForm: AllSessionForm;
  reservationToken: string;
  isSaved = false;
  urlCenter = '';
  constructor(
    private allSessionFormService: AllSessionFormService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.reservationToken = this.route.snapshot.paramMap.get('reservationToken');
    this.getAllSessionForm(this.reservationToken);
  }

  getAllSessionForm(reservationToken: string): void {
    this.allSessionFormService.getAllSessionForm(reservationToken).subscribe(
      allSessionForm => {
        this.allSessionForm = allSessionForm;
        this.urlCenter = allSessionForm.urlCenter;
      }
    );
  }

  valide(): void {
    this.allSessionFormService.create(this.allSessionForm).subscribe(
      value => {
        this.allSessionForm = value;
        this.isSaved = true;
      },
      err => {
        console.log(err);
      }
    );
  }

}
