import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { UserPreferenceService } from './user-preferences.service';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private titleTxt: string;
  private descriptionTxt: string;

  constructor(
    private meta: Meta,
    private title: Title,
    private translate: TranslateService,
    private userPreferenceService: UserPreferenceService,
    @Inject(DOCUMENT) private dom
  ) {
    this.translate.get('page.title').subscribe((res: string) => {
      this.titleTxt = res;
    });
    this.translate.get('page.description').subscribe((res: string) => {
      this.descriptionTxt = res;
    });
  }

  generateTags(config): void {
    // default values
    config = {
      title: this.titleTxt,
      description: this.descriptionTxt,
      image: 'https://www.anybuddyapp.com/assets/img/anybuddy-logo.png',
      url: 'https://www.anybuddyapp.com',
      ...config
    };

    this.title.setTitle(config.title);
    this.meta.updateTag({ name: 'author', content: 'Anybuddy' });
    this.meta.updateTag({ name: 'description', content: config.description });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: '@anybuddy_app' });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    this.meta.updateTag({ property: 'og:locale', content: this.userPreferenceService.getCountry() });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:site_name', content: 'Anybuddy' });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: config.url });
    this.createCanonicalURL();
  }

  createCanonicalURL(): void{
     const link: HTMLLinkElement = this.dom.createElement('link');
     link.setAttribute('rel', 'canonical');
     this.dom.head.appendChild(link);
     link.setAttribute('href', this.dom.URL.split('?')[0].replace('http://', 'https://'));
  }
}
