<div id="page" *ngIf="feedback" class="container-fluid row mx-0 px-0 py-sm-5">

  <div id="card" class="container mx-auto my-0 card align-items-center col-lg-10 col-xl-8">
    <div class="col col-lg-10 col-xl-8">

      <div id="pageGlobalDecoration" *ngIf="hasSavingPhasePassed === false">
        <div id="anybuddyLogo">
          <div class="d-lg-none d-flex justify-content-center">
            <img src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677227178/assets/logo/LOGO_JAUNE.png" alt="logo anybuddy" style="width: 200px;">
          </div>
          <div class="d-none d-lg-block py-4">
            <img src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677227178/assets/logo/logo-yellow.png" alt="logo anybuddy" style="width: 230px;">
          </div>
        </div>
      </div>

      <div id="rating" [hidden]="false">
        <div id="ListRatingDecoration">
          <div class="d-flex align-items-center">
            <div id="heartLogo" class="d-inline-block" style="width: 70px;">
              <img src="../../assets/img/Etape1_coeur.svg" alt="logo heart" style="width: 70px;">
            </div>
            <div id="heartLogoText" class="heartLogoText d-inline-block">
              {{ 'feedback.experience' | translate }}
            </div>
          </div>
        </div>

        <div id="listRating" >
          <hr>
          <div class="question">
            <h2 class="titleFeedbackElement">{{ 'feedback.clubTitle' | translate }}</h2>
            <p class="descriptionFeedbackElement">{{ 'feedback.club' | translate }}</p>
            <app-star-bar-rating [starBarId]="'rateClub'" (starResult)="feedback.clubRating = $event" [starNumber]="feedback.clubRating"></app-star-bar-rating>
          </div>
          <hr>
          <div class="question">
            <h2 class="titleFeedbackElement">{{ 'feedback.facilityTitle' | translate }}</h2>
            <p class="descriptionFeedbackElement">{{ 'feedback.facility' | translate }}</p>
            <app-star-bar-rating [starBarId]="'rateService'" (starResult)="feedback.serviceRating = $event" [starNumber]="feedback.serviceRating"></app-star-bar-rating>
          </div>
          <hr>
          <div class="question">
            <h2 class="titleFeedbackElement">{{ 'feedback.anybuddyTitle' | translate }}</h2>
            <p class="descriptionFeedbackElement">{{ 'feedback.anybuddy' | translate }}</p>
            <app-star-bar-rating [starBarId]="'rateAnnybuddy'" (starResult)="feedback.anybuddyRating = $event" [starNumber]="feedback.anybuddyRating"></app-star-bar-rating>
          </div>
          <div class="actions ">
            <div id="buttonNextRating" class="d-flex justify-content-end">
              <button [disabled]="hasSavingPhasePassed" (touchend)="showNext('rating','comment')" (click)="showNext('rating','comment')" class="rounded-pill">{{ 'feedback.next' | translate }}</button>
            </div>
          </div>
        </div>
      </div>

      <div id="comment" [hidden]="true">
        <div id="commentDecoration">
          <div class="d-flex align-items-center">
            <div id="commentLogo" class="d-inline-block" style="width: 70px;">
              <img src="../../assets/img/Etape2_message.svg" alt="logo heart" style="width: 70px;">
            </div>
            <div id="commentText" class="heartLogoText d-inline-block">
              {{ 'feedback.commentTitle' | translate }}
            </div>
          </div>
        </div>
        <hr>
        <div class="col-lg-10">
          <p class="descriptionFeedbackElement">
            {{ 'feedback.commentDescription' | translate }}
          </p>
          <div style="margin: 15px 0;">
            <div id="s4">
              <textarea class="form-control mx-0" rows="3" cols="20" [(ngModel)]="feedback.comment" placeholder=""></textarea>
            </div>
          </div>
        </div>
        <div class="actions row">
          <div id="buttonPreviousComment" class="d-flex justify-content-start col-6">
            <button [disabled]="hasSavingPhasePassed" (touchend)="showPrevious('comment','rating')" (click)="showPrevious('comment','rating')" class="btnPrevious rounded-pill">{{ 'feedback.previous' | translate }}</button>
          </div>
          <div id="buttonNextComment" class="d-flex justify-content-end col-6">
            <button [disabled]="hasSavingPhasePassed" (touchend)="publish('comment')" (click)="publish('comment')" class="rounded-pill">{{ 'feedback.publish' | translate }}</button>
          </div>
        </div>
      </div>

      <div *ngIf="hasSavingPhasePassed">
        <div id="tankDecoration" class="d-flex justify-content-center" >
          <div *ngIf="hasFullFiveStars">
            <img class="thankDecorationImgFullFiveStart" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677227440/assets/buddy/buddy_amoureux.svg" alt="buddy in love">
          </div>
          <div *ngIf="!hasFullFiveStars">
            <img class="thankDecorationImg" src="../../assets/img/High_five.svg" alt="high five">
          </div>
        </div>
        <div *ngIf="!error" id="thankTexts">
          <div id="thankMainText" class="d-flex justify-content-center py-2">
            <h2 class="titleFeedbackElement">
              {{ 'feedback.thanks' | translate }}
            </h2>
          </div>
          <hr>
          <div *ngIf="!error" id="thankSecondaryText">
            <div  *ngIf="hasFullFiveStars">
              <h2 style="text-align: center;" class="descriptionFeedbackElement">
                {{'feedback.message1' | translate}}
              </h2>
              <div class="row">
                <div class="col-md-6 py-2">
                  <a href="{{ 'store.app_store_link' | translate: { selectedLanguage: selectedLanguage } }}">
                    <button class="storeBtn btn">
                      <div class="container">
                        <div class="row">
                          <div class="col-3 storeIcon">
                        <span class="align-middle">
                        <div id="appleStoreIcon" class="h-100">
                            <img src="../../assets/img/apple-fill.svg" class="playBadge" alt="app store">
                        </div>
                          </span>
                          </div>
                          <div id="appleStoreMsg" class="col-9">
                        <span class="descriptionFeedbackElement">
                          {{'feedback.giveFeedBackOn' | translate}}
                        </span>
                          <a href="{{ 'store.app_store_link' | translate: { selectedLanguage: selectedLanguage } }}" class="storeTitle">
                            <h3>
                              {{'store.app_store' | translate}}
                            </h3>
                          </a>
                          </div>
                        </div>
                      </div>
                    </button>
                  </a>
                </div>
                <div class="col-md-6 py-2">
                  <a href="{{ 'store.play_store_link' | translate: { selectedLanguage: selectedLanguage } }}">
                    <button class="storeBtn btn">
                      <div class="container">
                        <div class="row">
                          <div class="col-3 storeIcon">
                            <div id="googlePlayStoreIcon" class="h-100">
                              <img src="../../assets/img/google-play-fill.svg" class="playBadge">
                            </div>
                          </div>
                          <div id="googlePlayMsg" class="col-9">
                        <span class="descriptionFeedbackElement">
                         {{'feedback.giveFeedBackOn' | translate}}
                        </span>
                          <a href="{{ 'store.play_store_link' | translate: { selectedLanguage: selectedLanguage } }}" class="storeTitle">
                            <h2 class="titleFeedbackElement">
                              {{'store.play_store' | translate}}
                            </h2>
                          </a>
                          </div>
                        </div>
                      </div>
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div *ngIf="!hasFullFiveStars">
              <h2 style="text-align: center;" class="descriptionFeedbackElement">
                {{ 'feedback.message2' | translate }}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div id="error" [hidden]="true">
        <div id="errorDecoration" class="d-flex justify-content-center" >
          <img class="thankDecorationImgFullFiveStart" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677227525/assets/buddy/android-icon.png" alt="buddy">
        </div>
        <div id="errorTexts">
          <div id="errorMainText" class="d-flex justify-content-center py-2">
            <h2 class="titleFeedbackElement">
              {{ 'feedback.errorTitle' | translate }}
            </h2>
          </div>
          <div id="errorText">
            <h2 style="text-align: center;" class="descriptionFeedbackElement">
              {{ 'feedback.error' | translate }}
            </h2>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
