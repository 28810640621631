// partie "activités proposées" de l'obglet 'infos du club'
import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ACTIVITY_LIST } from '../../models/activity-list';
import { ActivityType } from '../../models/activity-type';
import { Center } from '../../models/center';
import { Chracteristics } from '../../models/characteristic';
import { Resource } from '../../models/resource';
import { Service } from '../../models/service';
import { ServiceTypes } from '../../models/service-types';
import { Sites } from '../../models/sites';
import { SharedService } from '../../service/shared.service';

interface IServiceWithCharacteristics extends Service {
  characteristics: Chracteristics[];
}
@Component({
  selector: 'app-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss']
})
export class ActivityDetailsComponent implements OnInit {

  @Input() center: Center;

  activityList: any = ACTIVITY_LIST.filter(menuItem => menuItem);

  public isMobileResolution: boolean;
  public serviceTypeIds: string[];
  public services;
  public sitesIds: string[] = [];
  public activitiesIds: string[];

  constructor(
    private sharedService: SharedService,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
          this.isMobileResolution = isMobileResolution;
      });
  }

  ngOnInit(): void {
    this.serviceTypeIds = this.getAllServicesId(this.center);
    this.activitiesIds = this.getAllActivitiesIds(this.center);
    if (this.center.sites && this.center.sites.length) {
      this.sitesIds = this.getAllSitesIds(this.center);
      this.services = this.initAllServicesBySite(this.center, this.sitesIds);
    } else {
      this.services = this.initAllServicesByCenter(this.center, this.center.services);
    }
  }

  private getAllServicesId(center: Center): string[] {
    const serviceTypeIds: string[] = [];
    center.services.forEach((service: Service) => {
      const {serviceTypeId} = service;
      if (!serviceTypeIds.includes(serviceTypeId)) {
        serviceTypeIds.push(serviceTypeId);
      }
    });
    return serviceTypeIds;
  }

  private getAllSitesIds(center: Center): string[] {
    const sitesIds: string[] = [];
    center.sites.forEach(site => {
      const {id} = site;
      if (!sitesIds.includes(id)) {
        sitesIds.push(id);
      }
    });
    return sitesIds;
  }

  private getAllActivitiesIds(center: Center): string[] {
    const activitiesIds: string[] = [];
    center.activities.forEach(activity => {
      activitiesIds.push(activity.id);
    });
    return activitiesIds;
  }

  private initAllServicesBySite(center: Center, siteIds: string[]): any {
    const allServices = siteIds.reduce((acc, id) => {
      const services = this.getAllServices(center, id);
      const site: Sites = this.center.sites.filter(s => s.id === id)[0];
      const {name, address} = site;
      const servicesBySport = this.sortServiceBySport(center, services);
      return {
        ...acc,
        [id]: {
          name,
          address,
          res: servicesBySport
        }
      };
    }, {});
    return allServices;
  }

  private initAllServicesByCenter(center: Center, services: Service[]): any {
    const servicesWithCharacteristics = this.mapCharacteristicsWithService(center, services);
    const servicesBySport = this.sortServiceBySport(center, servicesWithCharacteristics);
    return {
        name: center.name,
        address: center.address,
        res: servicesBySport
      };
  }

  private getAllServices(center: Center, siteId: string): any {
    const {services} = center;
    const servicesBySite = services.filter(service => {
      const resource: Resource = this.getResourceByService(center, service);
      if (resource.siteId === siteId) {
        return service;
      }
    });
    const servicesWithCharacteristics = this.mapCharacteristicsWithService(center, servicesBySite);
    return servicesWithCharacteristics;
  }

  private sortServiceBySport(center: Center, services: Service[]): any {
    const servicesBySport = services.reduce((acc, service) => {
      const serviceType: ServiceTypes = center.serviceTypes.filter(s => s.id === service.serviceTypeId)[0];
      const sport: ActivityType = center.activities.filter(s => s.id === serviceType.activityId)[0];
      if (acc.hasOwnProperty(sport.id)) {
        return {
          ...acc,
          [sport.id]: {
            activity: sport.name,
            services : [...acc[sport.id].services, service]
          }
        };
      }
      return {
        ...acc,
        [sport.id]: {
          activity: sport.name,
          services : [service]
        }
      };
    }, []);
    return servicesBySport;
  }

  private getResourceByService(center: Center, service: Service): Resource {
    const {resourceId} = service;
    const resource: Resource = center.resources.filter(r => r.id === resourceId)[0];
    return resource;
  }

  private mapCharacteristicsWithService(center: Center, services: Service[]): IServiceWithCharacteristics[] {
    let servicesWithCharacteristics: IServiceWithCharacteristics[];
    servicesWithCharacteristics = services.map(service => {
      const resource: Resource = this.getResourceByService(center, service);
      const characteristics = resource !== undefined ? resource.characteristics : [];
      return {
        ...service,
        characteristics
      };
    });
    return servicesWithCharacteristics;
  }


}
