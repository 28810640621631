<div class="d-flex flex-column login-div">
  <app-top-navbar *ngIf="!isMobileResolution" [country]="country">
    <div class="row" app-top-navbar-right>
      <app-button-account></app-button-account>
    </div>
  </app-top-navbar>

  <app-top-navbar *ngIf="isMobileResolution"
                  [idSelector1Langage]="'dropdownCountry20'"
                  [idSelector2Langage]="'dropdownLangage20'"
                  [isTopBarFixed]="false"
                  [isMobileResolution]="isMobileResolution"
                  [country]="country"
                  [yellow]="false">

    <div class="d-flex top-nav-bar" *ngIf="isMobileResolution" app-top-navbar-right>
      <app-button-account [yellow]="false" [border]="false"></app-button-account>
    </div>
  </app-top-navbar>

    <router-outlet></router-outlet>

    <!-- Page succes confirmation mail -->
    <ng-container *ngIf="firstConnexion">
        <div class="d-flex flex-column justify-content-center success-send-mail success-confirmation-mail">

            <img class="buddy  align-self-center" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/buddy/buddy_clin_doeil_165x165.png" alt="Buddy">

            <p class="check-title align-self-center">
                {{ 'account.welcome' | translate }}
            </p>
            <p class="sublabel sublabel-medium mx-3">
                {{ 'account.subwelcome' | translate }}
            </p>
        </div>
    </ng-container>
    <!-- Fin page succes confirmation mail -->


    <!-- footer -->
    <ng-container *ngIf="!firstConnexion">
        <app-assistance [isMobileResolution]="isMobileResolution"></app-assistance>
    </ng-container>
    <!-- fin footer -->
</div>

<ng-template #profile>
    <div class="col text-right">
        <div ngbDropdown class="d-inline-block" placement="bottom-right">
            <button class="badge-account" id="dropdownBasic2" ngbDropdownToggle>
                <img class="navbar-icon" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/icon/account-icon.png"
                    alt="{{ 'account.my_account' | translate }}" />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" fill="rgba(113,113,113,1)"/></svg>
            </button>
            <div ngbDropdownMenu class="dropdown-toggle" aria-labelledby="dropdownBasic2">
                <button ngbDropdownItem (click)='showFav = false;'>{{ 'my_bookings.my_reservations' | translate }}</button>
                <button ngbDropdownItem (click)='showFav = true;'>{{ 'my_favorite.my_favorite_title' | translate }}</button>
                <button ngbDropdownItem [routerLink]="['/']">{{ 'menuComponent.home' | translate }}
                </button>
                <button ngbDropdownItem [routerLink]="['/logout']">
                    {{ 'menuComponent.logout' | translate}}
                </button>
            </div>
        </div>
    </div>
</ng-template>
