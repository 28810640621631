<div class="main-container">
    <div class="container">
        <div class="header-container">
            <div class="club-icon-container">
                <img src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/promotion/{{imgPartner}}.png" alt="partner club">
            </div>
            <h4 class="title"> {{ 'voucher.have_code' | translate }} {{ partner }} ? </h4>
        </div>
        <div class="form-container">
            <form #f="ngForm" (ngSubmit)="onSubmit(f)" class="form">
                <input type="text" class="input" name="id" ngModel required placeholder="Votre pass">
                <input type="text" class="input" name="mail" ngModel required placeholder="e-mail">
                <input type="text" class="input" name="promo" ngModel  placeholder="{{'voucher.promoCode' | translate}}">
                <p *ngIf="error" class="text-danger">{{error}}</p>
                <button class="submit" data-dismiss="modal" >{{ 'voucher.confirm' | translate }}</button>
            </form>
        </div>

    </div>
</div>
