<div class="d-flex">
    <ng-container *ngFor="let num of numbers; let i = index">
        <img *ngIf="0 >= (rating - i)"
            src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/star-empty.png"
            class="star-icon custom-img align-self-center"
            alt="{{'reviews.star_empty' | translate}}">
        <img *ngIf="(rating - i) > 0 && 1 > (rating - i)"
            src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/star-half-fill.png"
            class="star-icon custom-img align-self-center"
            alt="{{'reviews.star' | translate}}">
        <img *ngIf="(rating - i) >= 1"
            src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/star-fill.png"
            class="star-icon custom-img align-self-center"
            alt="{{'reviews.star' | translate}}">
    </ng-container>
</div>
