<div *ngIf="!displayMobileSearchPage; else mobileSearchPage">
  <div #banner class="search search-back-ground">
    <div class="container mt-3">
      <app-top-navbar
        [idSelector1Langage]="'dropdownCountry15'"
        [idSelector2Langage]="'dropdownLangage15'"
        [isYellowLogo]="false"
        [isMobileResolution]="isMobileResolution"
        [country]="country"
        [yellow]="true">
        <div class="d-flex top-nav-bar" *ngIf="!isMobileResolution" app-top-navbar-right>
          <button class="navbar-btn navbar-btn-yellow border-0" (click)="downloadApp()">
            {{'menuComponent.download_app' | translate}}
          </button>
          <a [routerLink]="['/' + language, 'on-boarding']" class="no-link-underline">
            <button class="navbar-btn navbar-btn-yellow border-0 me-0" routerLinkActive="active">
              {{'menuComponent.add_your_center' | translate}}
            </button>
          </a>
          <app-button-account [border]="false" [yellow]="true"></app-button-account>
        </div>

        <div class="d-flex top-nav-bar" *ngIf="isMobileResolution" app-top-navbar-right>
          <app-button-account [border]="false" [yellow]="true"></app-button-account>
          <button class="navbar-btn navbar-btn-yellow border-0" (click)="downloadApp()">
            {{'menuComponent.download_app' | translate}}
          </button>
          <a [routerLink]="['/' + language, 'on-boarding']" class="no-link-underline">
            <button class="navbar-btn navbar-btn-yellow border-0 me-0" routerLinkActive="active">
              {{'menuComponent.add_your_center' | translate}}
              <img width="60px" class="ps-1" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/home/pro-img.PNG">
            </button>
          </a>
        </div>
      </app-top-navbar>
    </div>

    <app-top-navbar [class.opacity]="isScroll"
                    [idSelector1Langage]="'dropdownCountry20'"
                    [idSelector2Langage]="'dropdownLangage20'"
                    [hided]="isScroll"
                    [isTopBarFixed]="true"
                    [isMobileResolution]="isMobileResolution"
                    [country]="country"
                    [yellow]="false">
      <div class="d-flex" *ngIf="!isMobileResolution" app-top-navbar-right>
        <button class="navbar-btn navbar-btn-light" (click)="downloadApp()">
          {{'menuComponent.download_app' | translate}}
        </button>
        <a [routerLink]="['/' + language, 'on-boarding']" style="text-decoration: none">
          <button class="navbar-btn navbar-btn-light me-0" routerLinkActive="active">
            {{'menuComponent.add_your_center' | translate}}
          </button>
        </a>
        <app-button-account [yellow]="false"></app-button-account>
      </div>

      <div class="d-flex top-nav-bar" *ngIf="isMobileResolution" app-top-navbar-right>
        <app-button-account [yellow]="false"></app-button-account>
        <button class="navbar-btn navbar-btn-light" (click)="downloadApp()">
          {{'menuComponent.download_app' | translate}}
        </button>
        <a [routerLink]="['/' + language, 'on-boarding']">
          <button class="navbar-btn navbar-btn-light me-0" routerLinkActive="active">
            {{'menuComponent.add_your_center' | translate}}
            <img width="60px" class="ps-1" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/home/pro-img.PNG">
          </button>
        </a>
      </div>
    </app-top-navbar>

    <div class="container d-flex" style="flex-grow: 1">
      <div class="d-flex justify-content-center align-self-center section-1-form-div m-auto">
        <div class="align-self-center section-1-form">
          <div class="title-section-1"
               [ngClass]="{'mb-4': !isMobileResolution, 'mb-3 mx-2': isMobileResolution}">
            {{'homeComponent.title_form' | translate}}
          </div>
          <div class="d-flex search-div justify-content-center" [ngClass]="{'mx-2': isMobileResolution}">
            <label for="book-facility" style="color: #ffc10742; width: 0%;">.</label>
            <input id="book-facility" class="search-input"  autocomplete="off" name="book-facility" (focusout)="focusOutInput($event.target.value)" (focus)="onOpenFilters($event.target.value)"
                   (keyup)="onOpenFilters($event.target.value)" placeholder="{{Placeholder}}">
          </div>
          <div class="d-flex">
            <div class="filters-container overflow-auto mt-2 d-flex mx-auto" *ngIf="!isMobileResolution && displaySearchPage">
              <app-search  [querySearch]="querySearch" [lang]="translate.currentLang" [addressSearch]="addressSearch"></app-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="sports my-4">
      <h4 class="title my-5">{{ 'homeComponent.sports_title' | translate}}</h4>
      <div class="mt-3">
        <app-sports-home-carousel *ngIf="slug" [homePage]="homePage" [slug]="slug" [isMobileResolution]="isMobileResolution"></app-sports-home-carousel>
      </div>
    </div>

    <div class="row gift-card d-block d-md-none" *ngIf="country === 'fr'">
      <div class="col-12 d-flex">
        <img class="me-auto w-50" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/home/Carte_cadeau_desktop_carte.png">
      </div>
      <div class="col-12 text-container">
        <h4 class="title">{{ 'homeComponent.offer_card' | translate}}<span>{{ 'homeComponent.anybuddy' | translate}}</span></h4>
        <p class="text">{{ 'homeComponent.offer_card_p1' | translate}}</p>
        <div class="d-flex">
          <button class="offer-card me-auto" (click)="cardOffer()">{{ 'homeComponent.i_offer' | translate}}</button>
        </div>
      </div>
    </div>

    <div class="gift-card d-none d-md-block" *ngIf="country === 'fr'">
      <img (click)="cardOffer()" class="w-100" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/home/Carte_cadeau_desktop.png">
    </div>

    <div class="advantages">
      <div class="d-flex mb-3">
        <div class="bubble">{{ 'homeComponent.advantages' | translate}}</div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-3 mb-3 mb-lg-0">
          <h4 class="title">{{ 'homeComponent.advantages_title' | translate}}</h4>
          <img class="d-none d-lg-block w-100" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/home/spots_icones_desktop.png">
        </div>
        <div class="col-10 col-sm-6 col-lg-3 mb-3 mb-lg-0 mx-auto">
          <div class="box">
            <div class="d-flex">
              <img class="picture me-auto" src="assets/images/newHome/Cercle%20jaune%20icone%20maison.svg">
            </div>
            <div>
              <h4 class="subtitle">{{ 'homeComponent.advantages_t1' | translate}}</h4>
            </div>
            <p class="description">{{ 'homeComponent.advantages_p1' | translate}}</p>
          </div>
        </div>
        <div class="col-10 col-sm-6 col-lg-3 mb-3 mb-lg-0 mx-auto">
          <div class="box">
            <div class="d-flex">
              <img class="picture me-auto" src="assets/images/newHome/Cercle%20jaune%20icone%20valide.svg">
            </div>
            <div>
              <h4 class="subtitle">{{ 'homeComponent.advantages_t2' | translate}}</h4>
            </div>
            <p class="description">{{ 'homeComponent.advantages_p2' | translate}}</p>
          </div>
        </div>
        <div class="col-10 col-sm-6 col-lg-3 mx-auto" style="height: fit-content">
          <div class="box">
            <div class="d-flex">
              <img class="picture me-auto" src="assets/images/newHome/Cercle%20jaune%20icone%20euros%20.svg">
            </div>
            <div>
              <h4 class="subtitle">{{ 'homeComponent.advantages_t3' | translate}}</h4>
            </div>
            <p class="description">{{ 'homeComponent.advantages_p3' | translate}}</p>
          </div>
        </div>
        <div class="col-10 col-sm-6 col-lg-3 mx-auto">
          <img class="d-none d-sm-block d-lg-none m-auto" style="height: 350px" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/home/spots_icones_mobile.png">
          <img class="d-block d-sm-none w-100" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/home/spots_icones_desktop.png">
        </div>
      </div>

    </div>

    <div *ngIf="!isMobileResolution" class="row download mx-0">
      <div class="col-12 col-lg-6 img-container">
        <img #phone src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677228154/assets/home/Visuel_portable_choix_sports.png">
      </div>
      <div class="col-12 col-lg-6 text-container">
        <h4 class="title">{{ 'homeComponent.download_app_title' | translate}}</h4>
        <p class="m-0">{{ 'homeComponent.download_app_p1' | translate}}</p>
        <div class="mb-3">
          <div class="row m-0" style="flex-wrap: wrap;" >
            <span class="stars">
              <img src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677228154/assets/icon/star-fill.png" class="star" loading="lazy" alt="star">
              <img src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677228154/assets/icon/star-fill.png" class="star" loading="lazy" alt="star">
              <img src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677228154/assets/icon/star-fill.png" class="star" loading="lazy" alt="star">
              <img src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677228154/assets/icon/star-fill.png" class="star" loading="lazy" alt="star">
              <img src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677228154/assets/icon/star-fill.png" class="star" loading="lazy" alt="star">
            </span>
          </div>
        </div>
        <p class="mb-0">{{ 'homeComponent.download_app_p2' | translate}}</p>
        <p class="mb-0">{{ 'homeComponent.download_app_p3' | translate}}</p>
        <p class="mb-0">{{ 'homeComponent.download_app_p4' | translate}}</p>
        <div class="d-flex mt-3">
          <a href="{{'store.app_store_link' | translate}}" target="_blank" rel="noopener noreferrer"
             [ngClass]="{'me-3': isMobileResolution, 'me-4': !isMobileResolution}">
            <img class="store-img-footer" alt="App Store" loading="lazy" src="{{'store.app_store_badge' | translate}}">
          </a>
          <a href="{{'store.play_store_link' | translate}}" target="_blank"
             rel="noopener noreferrer">
            <img class="store-img-footer" alt="Play Store" loading="lazy" src="{{'store.play_store_badge' | translate}}">
          </a>
        </div>
      </div>
    </div>

    <div class="about row">
      <div class="col-12 col-lg-6 text-col mx-auto">
        <div class="d-flex mb-3">
          <div class="bubble">{{ 'homeComponent.about' | translate}}</div>
        </div>
        <h4 class="title">{{ 'homeComponent.about_title' | translate}}</h4>
        <p>{{ 'homeComponent.about_p1' | translate}}</p>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row boxes pb-5">
          <div class="col-6 col-md-4 mb-3">
            <div class="box">
              <div class="d-flex">
                <img class="img" src="assets/images/newHome/En%20chiffres%20-%20icone%20maison.svg">
              </div>
              <h6 class="title">{{ 'homeComponent.about_center' | translate  }}</h6>
            </div>
          </div>
          <div class="col-6 col-md-4 mb-3">
            <div class="box">
              <div class="d-flex">
                <img class="img" src="assets/images/newHome/En%20chiffres%20-%20icone%20joueur.svg">
              </div>
              <h6 class="title mb-0">{{ 'homeComponent.about_players' | translate  }}</h6>
              <h6 class="title mb-0">{{ 'homeComponent.about_players_players' | translate  }}</h6>
            </div>
          </div>
          <div class="col-6 col-md-4 mb-3">
            <div class="box">
              <div class="d-flex">
                <img class="img" src="assets/images/newHome/En%20chiffres%20-%20icone%20argent.svg">
              </div>
              <h6 class="title">{{ 'homeComponent.about_money' | translate  }}</h6>
              <p class="description">{{ 'homeComponent.about_money_text' | translate  }}</p>
            </div>
          </div>
          <div class="col-6 col-md-4 mb-3 mb-md-0">
            <div class="box">
              <div class="d-flex">
                <img class="img" src="assets/images/newHome/Quelques%20chiffres%20-%20icone%20etoile.svg">
              </div>
              <h6 class="title">{{ 'homeComponent.about_stars' | translate  }}</h6>
              <p class="description">{{ 'homeComponent.about_stars_text' | translate  }}</p>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div class="box">
              <div class="d-flex">
                <img class="img" src="assets/images/newHome/Quelques%20chiffres%20-%20icone%20coupe.svg">
              </div>
              <h6 class="title">{{ 'homeComponent.about_google' | translate  }}</h6>
              <p class="description">{{ 'homeComponent.about_google_text' | translate  }}</p>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div class="box">
              <div class="d-flex">
                <img class="img" src="assets/images/newHome/Quelques%20chiffres%20-%20icone%204%20sports.svg">
              </div>
              <h6 class="title">{{ 'homeComponent.about_sport' | translate  }}</h6>
              <p class="description">{{ 'homeComponent.about_sport_text' | translate  }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row fields" *ngIf="country ==='fr' && !isMobileResolution">
      <div class="col-12 header">
        <div class="d-flex mb-3">
          <div class="bubble d-flex me-auto me-md-0 ms-md-auto">
            <svg class="m-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M10.478 11.632L5.968 4.56l1.931-.518 6.951 6.42 5.262-1.41a1.5 1.5 0 0 1 .776 2.898L5.916 15.96l-.776-2.898.241-.065 2.467 2.445-2.626.704a1 1 0 0 1-1.133-.48L1.466 10.94l1.449-.388 2.466 2.445 5.097-1.366zM4 19h16v2H4v-2z" fill="rgba(255,195,0,1)"/></svg>
            <div class="ms-2">{{ 'homeComponent.anybuddy_world' | translate  }}</div>
          </div>
        </div>
        <h4 class="title">{{ 'homeComponent.anybuddy_world_title' | translate  }}</h4>
        <p class="text">{{ 'homeComponent.anybuddy_world_p1' | translate  }}</p>
      </div>
      <div class="col-12 mt-3">
        <app-town-carousel *ngIf="country ==='fr'" [isMobileResolution]="isMobileResolution"></app-town-carousel>
      </div>
    </div>

    <div class="presse row" *ngIf="country==='fr'">
      <div class="col-12 col-lg-4 my-auto">
        <div class="d-flex mb-3">
          <div class="bubble">{{ 'homeComponent.press' | translate  }}</div>
        </div>
        <h4 class="title">{{ 'homeComponent.press_title' | translate  }}</h4>
      </div>
      <div class="col-12 col-lg-8 my-carousel mt-5 mt-lg-0">
        <app-presse-carousel *ngIf="country!=='es'" style="height: 200px" [isMobileResolution]="isMobileResolution"></app-presse-carousel>
      </div>
    </div>

    <div class="row professional mx-0">
      <div class="col-12 col-lg-5 my-auto">
        <h4 class="title">{{ 'homeComponent.professional_title' | translate  }}</h4>
        <p class="text">{{ 'homeComponent.professional_p1' | translate  }}</p>
      </div>
      <div class="col-12 col-lg-7 d-flex mt-3 mt-lg-0">
        <div class="row d-block d-sm-flex buttons">
          <div class="col-12 col-sm-6 d-flex mb-3 mb-sm-0 mt-3 mt-sm-0">
            <button class="my-btn btn-partner m-auto" (click)="becomePartner()">{{ 'homeComponent.professional_partner' | translate  }}</button>
          </div>
          <div class="col-12 col-sm-6 d-flex">
            <button class="my-btn btn-rdv m-auto" (click)="help()">
              <p class="m-0">{{ 'homeComponent.professional_take_rdv' | translate  }}</p>
              <p class="m-0">{{ 'homeComponent.professional_take_rdv_team' | translate  }}</p>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="d-flex flex-column section-5-div"
       [ngClass]="{'mt-5': isMobileResolution, 'mt-2 mb-3 mx-5 px-5 ': !isMobileResolution}">
    <app-footer [isMobileResolution]="isMobileResolution"></app-footer>
  </div>
</div>

<ng-template #mobileSearchPage>
  <app-search [isMobileResolution]="isMobileResolution" [lang]="translate.currentLang"></app-search>
</ng-template>

<ng-template #downlodApp let-modal>
  <div class="modal-header">
    <div class="filtre"></div>
    <button type="button"style="
      background: white;
      border: aliceblue;
  " aria-label="Close" (click)="onCloseModal(modal)"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z"/></svg></button>
  </div>
  <div class="modal-body">
    <div class="buddyText">
      <img width="30%" src="assets/images/buddy_app.svg">
    </div>
    <div class='textPoup'>{{'menuComponent.download_app_store' | translate}}</div>
    <div class="d-flex" style="margin-bottom: 14%; margin-left: 5%;">
      <a href="{{'store.app_store_link' | translate}}" target="_blank" rel="noopener noreferrer"
         [ngClass]="{'me-3 ms-3': isMobileResolution, 'me-4': !isMobileResolution}">
        <img class="store-img-footer" alt="App Store" loading="lazy" src="{{'store.app_store_badge' | translate}}">
      </a>
      <a href="{{'store.play_store_link' | translate}}" target="_blank"
         rel="noopener noreferrer">
        <img class="store-img-footer" alt="Play Store" loading="lazy" src="{{'store.play_store_badge' | translate}}">
      </a>
    </div>
  </div>
</ng-template>
