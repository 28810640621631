import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { GoogleMapsModule } from '@angular/google-maps';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { AppRoutingModule } from './app-routing.module';

import { AccountComponent } from './account/account.component';
import { AppComponent } from './app.component';
import { CenterAvailabilityComponent } from './center-availability/center-availability.component';
import { CenterCalendarComponent } from './center-calendar/center-calendar.component';
import { CenterComponent } from './center/center.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { LanguageComponent } from './language/language.component';
import { LoaderComponent } from './loader/loader.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { MapComponent } from './map/map.component';
import { MenuBottomComponent } from './menu-bottom/menu-bottom.component';
import { MenuTopComponent } from './menu-top/menu-top.component';
import { MenuComponent } from './menu/menu.component';
import { MyBookingsComponent } from './my-bookings/my-bookings.component';
import { ExternalCodeComponent } from './paiement/external-code/external-code.component';
import { PromoCodeComponent } from './paiement/promo-code/promo-code.component';
import { PresseCarouselComponent } from './presse-carousel/presse-carousel.component';
import { SignupComponent } from './signup/signup.component';
import { SportsCarouselComponent } from './sports-carousel/sports-carousel.component';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFrBeExtra from '@angular/common/locales/extra/fr-BE';
import localeNlBeExtra from '@angular/common/locales/extra/nl-BE';
import localeFr from '@angular/common/locales/fr';
import localeFrBe from '@angular/common/locales/fr-BE';
import localeNlBe from '@angular/common/locales/nl-BE';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { environment } from '../environments/environment';
import { AllsessionformComponent } from './allsessionform/allsessionform.component';
import { AssistanceComponent } from './assistance/assistance.component';
import { AuthGuardService } from './auth-guard.service';
import { AuthService } from './auth.service';
import { AvailabilitiesComponent } from './availabilities/availabilities.component';
import { ButtonAccountComponent } from './button-account/button-account.component';
import { AmenitiesComponent } from './center-components//amenities/amenities.component';
import { LocalisationComponent } from './center-components//localisation/localisation.component';
import { ActivityDetailsComponent } from './center-components/activity-details/activity-details.component';
import { CalendarCarouselComponent } from './center-components/calendar-carousel/calendar-carousel.component';
import { CenterRecommendationsComponent } from './center-components/center-recommendations/center-recommendations.component';
import { CenterReviewsComponent } from './center-components/center-reviews/center-reviews.component';
import { MultiplePricesComponent } from './center-components/multiple-prices/multiple-prices.component';
import { ActivityAvailabilityDisplayerComponent } from './center-components/select-activity-form/activity-availability-displayer/activity-availability-displayer.component';
import { ActivityAvailabilityFilterComponent } from './center-components/select-activity-form/activity-availability-filter/activity-availability-filter.component';
import { SelectActivityFormComponent } from './center-components/select-activity-form/select-activity-form.component';
import { CenterListComponent } from './center-list/center-list.component';
import { InfosClubNoPartnerComponent } from './center/components/infos-club-no-partner/infos-club-no-partner.component';
import { CompleteAccountComponent } from './complete-account/complete-account.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FilterComponent } from './filter/filter.component';
import { FireBaseActionComponent } from './fire-base-action/fire-base-action.component';
import { FirebaseErrorGesture } from './firebase-error-gesture';
import { ForgotpwComponent } from './forgotpw/forgotpw.component';
import { AuthInterceptor } from './http-auth-interceptor';
import { InfoComponent } from './info/info.component';
import { JoinResaComponent } from './join-resa/join-resa.component';
import { NgxJsonLdComponent } from './jsonId/jsonld.component';
import { MailingListComponent } from './mailing-list/mailing-list.component';
import { MoreDetailsComponent } from './more-details/more-details.component';
import { MultipleStarsRatingComponent } from './multiple-stars-rating/multiple-stars-rating.component';
import { MyFavoriteComponent } from './my-favorite/my-favorite.component';
import { MyPreferencesComponent } from './my-preferences/my-preferences.component';
import { OnBoardingComponent } from './on-boarding/on-boarding.component';
import { PaiementComponent } from './paiement/paiement.component';
import { StripeComponentComponent } from './paiement/stripe-component/stripe-component.component';
import { ReservationComponent } from './reservation/reservation.component';
import { ResetpwformComponent } from './resetpwform/resetpwform.component';
import { SearchComponent } from './search/search.component';
import { ActivityService } from './service/activity.service';
import { AuthFirebaseService } from './service/auth-firebase.service';
import { FavoriteService } from './service/favorite.service';
import { BookService, CenterAvailabilitiesService, InfoPaiementService, SelectedSportService, SharedService, SlotService, StateService } from './service/shared.service';
import { UserPreferenceService } from './service/user-preferences.service';
import { VisitorsService } from './service/visitors.service';
import { SportsHomeCarouselComponent } from './sports-home-carousel/sports-home-carousel.component';
import { StarBarRatingComponent } from './star-bar-rating/star-bar-rating.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { SuccesResaComponent } from './succes-resa/succes-resa.component';
import { TopNavBarComponent } from './top-navbar/top-navbar.component';
import { TownCarouselComponent } from './town-carousel/town-carousel.component';

registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeFrBe, 'fr-BE', localeFrBeExtra);
registerLocaleData(localeNlBe, 'nl-BE', localeNlBeExtra);
registerLocaleData(localeEs, 'es', localeEsExtra);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + new Date().getTime());
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LanguageComponent,
    PresseCarouselComponent,
    SportsCarouselComponent,
    MenuComponent,
    FooterComponent,
    LoginComponent,
    SignupComponent,
    AccountComponent,
    MyBookingsComponent,
    LogoutComponent,
    PaiementComponent,
    PromoCodeComponent,
    ExternalCodeComponent,
    CenterComponent,
    StarRatingComponent,
    SelectActivityFormComponent,
    CalendarCarouselComponent,
    CenterReviewsComponent,
    CenterRecommendationsComponent,
    ActivityDetailsComponent,
    AssistanceComponent,
    AmenitiesComponent,
    LocalisationComponent,
    MultipleStarsRatingComponent,
    CenterAvailabilityComponent,
    FilterComponent,
    CenterCalendarComponent,
    MenuBottomComponent,
    MenuTopComponent,
    MapComponent,
    NgxJsonLdComponent,
    LoaderComponent,
    FeedbackComponent,
    AllsessionformComponent,
    SearchComponent,
    AvailabilitiesComponent,
    StripeComponentComponent,
    SuccesResaComponent,
    CenterListComponent,
    MoreDetailsComponent,
    ForgotpwComponent,
    ResetpwformComponent,
    InfoComponent,
    OnBoardingComponent,
    MultiplePricesComponent,
    StarBarRatingComponent,
    ReservationComponent,
    MyFavoriteComponent,
    TopNavBarComponent,
    ButtonAccountComponent,
    InfosClubNoPartnerComponent,
    MyPreferencesComponent,
    ActivityAvailabilityFilterComponent,
    ActivityAvailabilityDisplayerComponent,
    MailingListComponent,
    TownCarouselComponent,
    SportsHomeCarouselComponent,
    CompleteAccountComponent,
    FireBaseActionComponent,
    JoinResaComponent,
  ],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    BrowserModule.withServerTransition({ appId: 'bookingWeb' }),
    TransferHttpCacheModule,
    HammerModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    NgxBootstrapSliderModule,
    IvyCarouselModule
  ],
  providers: [
    AuthService,
    AuthFirebaseService,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'fr' },
    ActivityService,
    SharedService,
    SelectedSportService,
    SlotService,
    VisitorsService,
    BookService,
    CenterAvailabilitiesService,
    UserPreferenceService,
    StateService,
    InfoPaiementService,
    FavoriteService,
    { provide: ErrorHandler, useClass: FirebaseErrorGesture },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

