<h1>Marketing Preferences</h1>

<form [formGroup]="unsubscribeForm" (ngSubmit)="onSubmit()">

    <div>
        <label for="email">
          Email
        </label>
        <input id="email" type="text" formControlName="email">
      </div>
    <button class="button" type="submit">Unsubscribe</button>
  
  </form>


  <ngb-alert *ngIf="isDeleted != undefined && !isDeleted" type="danger">An error occured please contact support at <a href="https://support.anybuddyapp.com">support.anybuddyapp.com</a></ngb-alert>
  <ngb-alert *ngIf="isDeleted != undefined && isDeleted" type="success">You have been removed from database</ngb-alert>