import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Address } from '../models/address';
import { Center } from '../models/center';
import { InfoPaiement } from '../models/infoPaiment';
import { NewReservation } from '../models/new-reservation';
import { ServiceInformation } from '../models/serviceInformation';
import { User } from '../models/user';
import { AuthFirebaseService } from '../service/auth-firebase.service';
import { BookingService } from '../service/booking.service';
import { CentersService } from '../service/center.service';
import { DatetimeService, FormatDate } from '../service/datetime.service';
import { BookService, InfoPaiementService, PriceService, StateService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import { UserService } from '../service/user.service';


/* eslint-disable */
@Component({
  selector: 'app-paiement',
  templateUrl: './paiement.component.html',
  styleUrls: ['./paiement.component.scss'],
  providers: [PriceService]
})

export class PaiementComponent implements OnInit, AfterViewInit {
  @ViewChild('content') public content: ElementRef;
  @ViewChild('tarifContent') public tarifContent: ElementRef;
  @ViewChild('promoContent') public promoContent: ElementRef;
  @ViewChild('resaError') public resaError: ElementRef;
  @ViewChild('mymodal') public myModal: ElementRef;


  public service;
  public slotService;
  nbPlacesBooked: number = 0
  public startDate
  public center: Center;
  public price;
  public pricefull:number;
  public feePrice = 0;
  public feeText: string;
  public activity;
  public pricePromo = "Ajouter un Code Promo"
  public pricePromoValue: number = 0;
  public pricePromoPercent: number = 0;
  public PromoInfo
  public centerId
  public photoService;
  public serviceId
  public closeResult: string;
  public hour = 1;
  public freeCancellationHours: string;
  public cancellationCondition: string;
  public isCancel: boolean;
  public newReservationModel: NewReservation;
  public confirmationCheck: boolean;
  public isCE: boolean = false;
  public error: boolean;
  public mountDiscount: number;
  public loadVALUE: boolean = false
  public token: string;
  public showCondition: boolean = true
  public partner: string;
  public imgPartner: string;
  public siteAddress: Address;
  public dateDisplay: string;
  public hours: string;
  public currentLang: string;
  public linkCGV: string;
  public importantMsg: string;
  public isLoading: boolean = false;

  userEmail: string = '';
  emailValidatorForm: any;
  emailValidatorFormError: boolean = false;
  emailVerified: boolean = false;
  emailSent: boolean = false;
  resendEmail: boolean = false;
  // code pour tester en attendant l'api
  emailCode: string = "1234";
  codeVerificationGiven: string[] = ['', '', '', ''];
  codeVerification: number = 0;

  color: string = '#FFC300'
  infoPaiement: InfoPaiement;
  servPrice: ServiceInformation;
  initial: ServiceInformation;
  isChangeNbrFromPaiement: boolean = true;
  totalMulPrix: number;
  nbrBooked: number;
  isMultiprix: boolean = false;
  promoFees: boolean = false;
  paymentInClub: boolean = false;
  discountPrice: number = 0;
  isCenterMember = false;
  avaibilities;
  user;


  constructor(
    private modalService: NgbModal,
    private centersService: CentersService,
    private route: ActivatedRoute,
    private router: Router,
    private bookService: BookService,
    private bookingService: BookingService,
    private _location: Location,
    private translate: TranslateService,
    private datetimeService: DatetimeService,
    private userPreferenceService: UserPreferenceService,
    public stateService: StateService,
    public infoPaiementService: InfoPaiementService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private authFirebaseService: AuthFirebaseService

  ) {
    this.bookService.changeEmitted$.subscribe(
      slot => {
        this.slotService = slot;
        this.startDate = slot.startDateTime
        this.price = slot.services[0].price;
      });
    this.authFirebaseService.getUser().then(
      user => {
        this.user = user;
      }
    )
  }

  ngOnInit(): void {
    this.infoPaiement = this.infoPaiementService.servPrice
    if(!this.infoPaiement || this.infoPaiement == null || JSON.stringify(this.infoPaiement) === '{}'){
      this.infoPaiement = JSON.parse(sessionStorage.getItem('infoPaiement'))
    }else {
      sessionStorage.setItem('infoPaiement', JSON.stringify(this.infoPaiement))
    }

    if( (!this.stateService.servPrice || this.stateService.servPrice == null || JSON.stringify(this.stateService.servPrice) === '{}' ||
      !this.stateService.initial || this.stateService.initial == null  || JSON.stringify(this.stateService.initial) === '{}')
      && (!this.stateService.total || this.stateService.total == null || !this.stateService.nbrBooked || this.stateService.nbrBooked == null )
    ){
      if(sessionStorage.getItem('stateService')){
        this.stateService.init( JSON.parse(sessionStorage.getItem('stateService')))
      }
    }else{
      sessionStorage.setItem('stateService',JSON.stringify(this.stateService))
    }

    this.servPrice = this.stateService.servPrice;

    this.stateService.servPrice = undefined;

    this.initial = this.stateService.initial;
    this.stateService.initial = undefined;

    this.totalMulPrix = this.stateService.total;

    this.nbPlacesBooked = this.stateService.nbrBooked;
    this.currentLang = this.userPreferenceService.getCountry();
    if (this.currentLang === 'es') {
      this.linkCGV = "https://www.anybuddyapp.com/es/condiciones-generales/"
    } else {
      this.linkCGV = "https://www.anybuddyapp.com/conditions-generales/"
    }
    this.pricePromo = this.translate.instant("voucher.promoCode")
    if (this.authFirebaseService.getIsLoggedIn) {
      this.slotService = JSON.parse(sessionStorage.getItem("slots"));
      console.log(this.slotService)
      this.activity = sessionStorage.getItem("activities")
      this.freeCancellationHours = this.slotService.services[0].freeCancellationHours
      this.cancellationCondition = this.slotService.services[0].cancellationCondition
      this.isCancel = this.slotService.services[0].isCancellable
      this.discountPrice = this.slotService.services[0].discountPrice;
      this.startDate = this.slotService.startDateTime;
      console.log(this.infoPaiement)
      console.log(this.infoPaiementService.servPrice)
      this.price = this.infoPaiement.price / 100;
      this.pricefull = this.infoPaiement.price / 100;
      this.initDateToDisplay(this.startDate);
      if(this.slotService.services[0].serviceFee && this.slotService.services[0].serviceFee > 0){
        this.feePrice = this.slotService.services[0].serviceFee;
      }
      this.feeText = this.slotService.services[0].serviceFeeText;
      if (this.infoPaiement && this.infoPaiement.price != undefined && this.infoPaiement.places.length>0) {
        this.isMultiprix = true;
      } else {
        this.stateService.total = this.price * 100
        this.stateService.nbrBooked = 1
      }
      /*
      if(this.discountPrice && this.discountPrice != this.slotService.services[0].price && !this.isMultiprix){
        this.isCenterMember = true;
      }
      if(this.isCenterMember){
        this.applyCenterMemberDiscount()
      }
      this.pricefull = (this.isCenterMember ? this.discountPrice / 100 : this.pricefull)

       */
      this.centerId = this.route.snapshot.params.centerId;

      this.centersService.getUserCenterMember().subscribe(
        value => {
          value.data.map(
            m => {
              if(m.centerId == this.centerId && !this.isMultiprix){
                console.log(this.isCenterMember)
                this.isCenterMember = true
                this.applyCenterMemberDiscount();
                this.pricefull = this.discountPrice / 100;
              }
            }
          )
        }, error1 => {
          console.log(error1)
        }
      )



      if (this.feePrice){
        this.feeText = this.slotService.services[0].serviceFeeText;
        this.stateService.total += this.feePrice;
      }


      this.serviceId = this.route.snapshot.params.serviceId;
      this.token = this.route.snapshot.queryParams["token"]
      if (this.token !== undefined && this.token === "9aa637db-e3f1-46dc-9ed2-bf61bfd5024f") {
        this.showCondition = false;
        this.confirmationCheck = true;
      }
      this.getCenter(this.centerId, this.serviceId);

    }
    this.userEmail = this.user.email;
    this.emailVerified = this.user.emailVerified;
    this.emailValidatorForm = this.formBuilder.group({
      email: [this.userEmail, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });

  }

  getDiscountEmployee(res:any):void{
    if(res["employeeDiscount"] && res["employeeDiscount"]["amount"]>0){
      this.isCE=true
      if (res["employeeDiscount"]["operation"] === '%') {
        this.pricefull = this.infoPaiement.price - (this.infoPaiement.price * ( res["employeeDiscount"]["amount"] / 100));
        this.pricePromo = "" +res["employeeDiscount"]["amount"]+ " " + res["employeeDiscount"]["operation"];
        this.pricePromoPercent = res["employeeDiscount"]["amount"];
        this.pricePromoValue = (this.infoPaiement.price * ( res["employeeDiscount"]["amount"] / 100));
        if (this.pricefull === 0) {
          this.feePrice = 0;
          this.promoFees = true;
        } else {
          this.feePrice = this.slotService.services[0].serviceFee;
          this.promoFees = false;
        }
        this.stateService.total = this.pricefull
        if (this.feePrice){
          this.stateService.total += this.feePrice;
        }

      }
      else if (res["employeeDiscount"]["operation"]  === '-') {
        this.pricefull = this.infoPaiement.price - res["employeeDiscount"]["amount"];
        this.pricePromo = res["employeeDiscount"]["operation"] + " " + res["employeeDiscount"]["amount"]/ 100 + " " + this.center.currenciesAccepted;
        this.pricePromoValue = res["employeeDiscount"]["amount"];
        this.stateService.total = this.pricefull
        if (this.feePrice){
          this.stateService.total += this.feePrice;
        }
      } else { // '+' type
        this.pricefull = this.infoPaiement.price;
        this.pricePromo = res["employeeDiscount"]["operation"] + " " + res["employeeDiscount"]["amount"] / 100 + " " + this.center.currenciesAccepted;
        this.pricePromoValue = res["employeeDiscount"]["amount"];
        this.stateService.total = this.pricefull
        if (this.feePrice){
          this.stateService.total += this.feePrice;
        }
      }
      this.newReservationModel = new NewReservation(this.centerId, this.slotService.services[0].slotId, this.activity, this.serviceId, Math.ceil(this.pricefull), this.center.currenciesAccepted, Math.ceil(this.pricefull), null, this.infoPaiement.places,this.feePrice);
    }
  }

  ngAfterViewInit(): void { }

  private initDateToDisplay(date: string): void {
    this.dateDisplay = this.datetimeService.formatDateDisplay(date, this.userPreferenceService.getLocale(), FormatDate.full)
    const [, hours] = date.split('T');
    this.hours = hours;
  }
  public openModalPromo(content): void {
    this.modalService.open(content, { centered: true });
  }

  get email() {
    return this.emailValidatorForm.get('email');
  }

  onSubmitEmailValidatorForm() {
    this.sendMail();
    this.emailSent = true;
    this.resendEmail = false;
  }

  sendMail() {
    return this.authFirebaseService.SendVerificationMail()
  }

  resendCodeEmail(): void{
    this.resendEmail = true;
    this.sendMail();
  }

  verifyCode() {
    return this.userService.verifyCode(this.email.value, this.codeVerificationGiven.join(''));
  }


  verifyEmailCode(): void{
    if (this.codeVerificationGiven.indexOf("") === -1) {
      this.isLoading = true;
      this.verifyCode().subscribe(
        (user: User) => {
          this.isLoading = false;
          this.emailVerified = true;
          this.codeVerification = 1;
          this.codeVerificationGiven = ['', '', '', '']
          this.modalService.dismissAll();
          this.payer(this.myModal,this.emailVerified)

        },
        error => {
          this.isLoading = false;
          this.codeVerification = -1;
          this.emailVerified = false;
        }
      );
    }
  }

  getCenter(centerId, id): void {
    this.centersService.getCenter(centerId).subscribe(
      res => {
        this.center = res;
        this.service = res.services.find(val => val["id"] === id);
        this.importantMsg = this.center.importantMessage;
        const ressourceId = this.service.resourceId
        if (this.center.bookingType === "onsite") {
          this.paymentInClub = true;
        }
        if (ressourceId) {
          const siteId: string = res.resources.find(val => val["id"] === ressourceId).siteId
          if (siteId) {
            this.siteAddress = res.sites.find(val => val["id"] === siteId).address
          }
        }
        if (!this.siteAddress) {
          this.siteAddress = this.center.address;
        }
        if (this.service.photos.length > 0) {
          this.photoService = this.service.photos[0].small
        } else {
          this.photoService = this.center.profilePhoto['192x192']
        }
        this.newReservationModel = new NewReservation(centerId, this.slotService.services[0].slotId, this.activity, id,Math.ceil(this.pricefull*100), this.center.currenciesAccepted, Math.ceil(this.price * 100), null, this.infoPaiement.places,this.feePrice);
        this.centersService.listCe().subscribe(res=>{
          this.getDiscountEmployee(res)
        })
      }
    );
  }

  getDate(startDate) {
    return new Date(startDate).toLocaleDateString(this.translate.getBrowserCultureLang(), { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })
  }

  ngOnChanges(changes: SimpleChanges): void { }

  payer(content, emailVerification) {
    if (this.confirmationCheck) {
      if (this.error) {
        this.error = false;
      }
      if (!this.emailVerified) {
        this.emailSent = false;
        this.resendEmail = false;
        this.modalService.open(emailVerification, { size: 'lg', centered: true });
      } else {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop : 'static' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed`;
        });
      }
    } else {
      this.error = true
    }
  }

  closeEmailModal() {
    this.modalService.dismissAll();
  }

  openErrorModal(){
    this.modalService.open(this.resaError, { centered: true });
  }

  public back(): void {
    this._location.back()
  }

  isTarifPopup(tarifContent): void {
    this.modalService.open(tarifContent, { scrollable: true })
  }

  public openModal(event): void {
    this.partner = event.part;
    this.imgPartner = event.img;
    this.modalService.dismissAll();
    this.modalService.open(this.promoContent, { centered: true });
  }

  public openFeeInfoModal(feeInfo): void {

    this.modalService.open(feeInfo, { centered: true,windowClass: 'feemodal' });

  }

  public applyVoucher(event) {
    let voch = event.promo
    if (voch.code2 == "") {
      event.promo.code2 = null
    }
    this.PromoInfo = event.code.discountAmount
    if (event.code.discountOperation === '%') {
      this.pricefull = this.infoPaiement.price - (this.infoPaiement.price * event.code.discountAmount / 100);
      this.pricePromo = "" + event.code.discountAmount + " " + event.code.discountOperation;
    }
    else if (event.code.discountOperation === '-') {
      this.pricefull = this.infoPaiement.price - event.code.discountAmount;
      this.pricePromo = event.code.discountOperation + " " + event.code.discountAmount / 100 + " " + this.center.currenciesAccepted;
    } else { // '+' type
      this.pricefull = this.infoPaiement.price;
      this.pricePromo = event.code.discountOperation + " " + event.code.discountAmount / 100 + " " + this.center.currenciesAccepted;
    }
    if (this.pricefull === 0) {
      this.feePrice = 0;
      this.promoFees = true;
    } else {
      this.feePrice = this.slotService.services[0].serviceFee;
      this.promoFees = false;
    }
    this.stateService.total = this.pricefull
    if (this.feePrice){
      this.stateService.total += this.feePrice;
    }
    this.newReservationModel = new NewReservation(this.centerId, this.slotService.services[0].slotId, this.activity, this.serviceId, Math.ceil(this.pricefull), this.center.currenciesAccepted, Math.ceil(this.pricefull), event.promo, this.infoPaiement.places,this.feePrice);
    this.modalService.dismissAll();
  }

  public onCheckChange(event): void {
    this.confirmationCheck = event.target.checked;
  }

  public bookReservation(): void {
    if(this.stateService.nbrBooked < 1){
      this.openErrorModal()
    }else {
      if (this.confirmationCheck) {
        this.loadVALUE = true
        this.error = false;
        this.bookingService.checkout(this.newReservationModel).subscribe(
          (res: any) => {
            this.router.navigate(['/checkout/complete'],{queryParams: { awaitingValidation: true, eventId: res.eventId }});
          }
        )
      } else {
        this.error = true;
      }
    }
  }

  newInfoPaiement(infoPaiement: InfoPaiement) {
    console.log('newifopaiment')
    this.infoPaiement = infoPaiement
    this.pricefull = infoPaiement.price
    this.price = infoPaiement.price
    this.stateService.total = infoPaiement.price;
    if(this.feePrice){
      this.stateService.total += this.feePrice;
    }
    sessionStorage.setItem('infoPaiement',JSON.stringify(infoPaiement))
    this.updateInmemoryStateServiceTotal(infoPaiement.price);
    this.newReservationModel = new NewReservation(infoPaiement.centerId, this.slotService.services[0].slotId, this.activity, this.serviceId, Math.ceil(this.pricefull), this.center.currenciesAccepted, Math.ceil(this.pricefull), null, this.infoPaiement.places,this.feePrice);
  }

  updateInmemoryStateServiceTotal(newTotal: number): void{
    let stateService = JSON.parse(sessionStorage.getItem('stateService'))
    stateService.total = newTotal
    sessionStorage.setItem('stateService',JSON.stringify(stateService))
  }

  applyCenterMemberDiscount(): void {
    this.stateService.total = this.discountPrice
  }
}
