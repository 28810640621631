import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { UserService } from './service/user.service';

@Injectable()
export class AuthService {
  isLoggedIn = false;
  public role = 'pro';
  private loginUrl: string = environment.organiserApiUrl + '/v1/auth';
  private headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(@Inject(PLATFORM_ID) private platform, private http: HttpClient, private userService: UserService) {
    if (isPlatformBrowser(this.platform) && (sessionStorage.getItem('id_token') || localStorage.getItem('id_token'))) {
      this.isLoggedIn = true;
    }
  }


  authWithProvider(provider: string, token: string): Observable<any> {
    return this.http.post(`${this.loginUrl}`, JSON.stringify({ provider, token }), { headers: this.headers, observe: 'response' });
  }

  logout(): void {
    this.isLoggedIn = false;
    this.userService.removeUser();
  }

  getIsLoggedIn(): boolean {
    return this.isLoggedIn;
  }

  setRedirectUrl(url: string): void {
    if (isPlatformBrowser(this.platform)) {
      sessionStorage.setItem('redirect_url', url);
    }
  }

  cleanRedirectUrl(): void {
    if (isPlatformBrowser(this.platform)) {
      sessionStorage.removeItem('redirect_url');
    }
  }

  getRedirectUrl(): string {
    if (isPlatformBrowser(this.platform)) {
      return sessionStorage.getItem('redirect_url');
    }
  }

}
