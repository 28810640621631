import { isPlatformBrowser, Location } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from '../auth.service';
import { AuthFirebaseService } from '../service/auth-firebase.service';
import { SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';


@Component({
  selector: 'app-forgotpw',
  templateUrl: './forgotpw.component.html',
  styleUrls: ['./forgotpw.component.scss']
})
export class ForgotpwComponent implements OnInit {

  @ViewChild('content') content: ElementRef;

  isMobileResolution = false;
  notFound: false;
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  modalOptions: NgbModalOptions;
  fromWeb = true;
  errorMessage: any;
  error = false;
  email = new UntypedFormControl(null, [Validators.required, Validators.email]);
  isOk = true;
  country: string;

  constructor(
    @Inject(PLATFORM_ID) private platform,
    private deviceService: DeviceDetectorService,
    public authService: AuthService,
    public router: Router,
    private location: Location,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private sharedService: SharedService,
    private userPreferenceService: UserPreferenceService,
    private authFirebaseService: AuthFirebaseService,
    private route: ActivatedRoute
  ) {
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();

    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
      });
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
          if (params.email){
            this.email.setValue(params.email);
          }
        }
      );
    this.country = '';
    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.country = this.userPreferenceService.getCountry();
    }
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
  }

  onPreviousPage(): void{
    if (isPlatformBrowser(this.platform)) {
      this.location.back();
    }
  }

  open(): void {
    if (!this.email.hasError('required') && !this.email.hasError('email')) {
      const emailAddress = this.email.value;
      this.authFirebaseService.ForgotPassword(emailAddress)
        .then(() => {
          this.modalService.open(this.content);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.error = true;
        });
    }
  }
}


