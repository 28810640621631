<div class="container">
    <a [routerLink]="['/' + language, 'login']" *ngIf="isLoggedIn === false; else profile">
        <div class="btn-rounded " [class.no-border]="!border" [class.yellow]="yellow">
            {{'menuComponent.my_account' | translate}}
        </div>
    </a>
</div>

<ng-template #profile>
    <div class="col text-right">
        <div ngbDropdown class="d-inline-block" placement="bottom-right">
          <button class="badge-account d-flex" [class.no-border]="!border" [class.yellow]="yellow" id="dropdownBasic2" ngbDropdownToggle>
            <div class="m-auto d-flex">
              <div class="m-auto">
                {{'menuComponent.my_account' | translate}}
              </div>
              <svg *ngIf="!yellow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path class="arrow-down" d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" fill="rgba(0,0,0,1)"/></svg>
              <svg *ngIf="yellow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path class="arrow-down" d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" fill="rgba(255,255,255,1)"/></svg>
            </div>
          </button>
            <div ngbDropdownMenu class="dropdown-toggle" aria-labelledby="dropdownBasic2">
                <button ngbDropdownItem [routerLink]="['/']">{{ 'menuComponent.home' | translate }}</button>
                <button ngbDropdownItem [routerLink]="['/account']">{{ 'my_bookings.my_reservations' | translate }}</button>
                <button ngbDropdownItem [routerLink]="['/account/favoris']">{{ 'my_favorite.my_favorite_title' | translate }}</button>
                <button ngbDropdownItem [routerLink]="['/account/preferences']">{{ 'my_preferences.my_preferences_title' | translate }}</button>
                <button ngbDropdownItem [routerLink]="['/logout']">{{ 'menuComponent.logout' | translate}}</button>
            </div>
        </div>
    </div>
</ng-template>
