import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Reservation } from '../models/reservation';

@Injectable({
  providedIn: 'root'
})
export class ReservationsService {

  private headers = new HttpHeaders().set('Content-Type', 'application/json');
  private reservationsUrl = environment.bookingApiUrl + '/v1/reservations';

  constructor(private http: HttpClient) { }

  getReservations(): Observable<Reservation[]> {
    return this.http.get<Reservation[]>(`${environment.bookingApiUrl}/v1/users/me/reservations`)
      .pipe(
        map(res => res.map(reservation => Reservation.fromJson(reservation))),
        catchError(this.handleError('getReservations', []))
      );
  }

  getReservation(id: string): Observable<Reservation> {
    return this.http.get<Reservation>(`${this.reservationsUrl}/${id}`);
  }

  cancelReservation(id: string): any {
    return this.http.delete(`${this.reservationsUrl}/${id}`, { headers: this.headers });
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
