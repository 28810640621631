import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/user';
import { UserProfile } from '../../models/user-profile';
import { BookingService } from '../../service/booking.service';
import { BrowserStorageService } from '../../service/storage.service';
import { UserService } from '../../service/user.service';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserPreferenceService } from '../../service/user-preferences.service';
import { NewReservation } from '../../models/new-reservation';
import { TranslateService } from '@ngx-translate/core';
import {AuthFirebaseService} from '../../service/auth-firebase.service';
import {ReservationsService} from '../../service/reservations.service';

/* eslint-disable */
@Component({
  selector: 'app-stripe-component',
  templateUrl: './stripe-component.component.html',
  styleUrls: ['./stripe-component.component.scss']
})
export class StripeComponentComponent implements OnInit, OnDestroy {

  stripe: any;
  elements: any;
  card: any;
  hasPaid: boolean = false;
  isPaidSuccess: boolean;
  isDisabled: boolean = false;
  isUsedPromo: boolean = false;
  isLoad: boolean = false;
  errorPayment: string;
  clientSecret: string;
  slotId:string;
  cardElement: any;
  errorName: boolean = false;
  errorMail: boolean = false;
  error: boolean = false;
  acceptConditions: boolean = false;
  errorConditions: boolean = false;
  isShowCard: boolean = true;

  userProfile: UserProfile = new UserProfile('', '', null);
  paymentType: string = 'all';
  isBrowser: boolean = false;
  loader: boolean = false;
  svgContainer: any;
  animItem: any;
  reservationId: string;
  eventId: string;
  inPayment = false;
  @Input() newReservation: NewReservation;
  @Input() center;
  @Input() service;
  public lottieConfig: Object;
  constructor(
    private modalService: NgbModal,
    private bookingService: BookingService,
    private sessionStorageService: BrowserStorageService,
    private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private userPreferenceService: UserPreferenceService,
    private authFirebaseService: AuthFirebaseService,
    private reservationsService: ReservationsService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.userService.getMe().subscribe(
      user => {
        this.userProfile.firstName = user.firstName;
        this.userProfile.lastName = user.lastName;
      }
    );
    this.authFirebaseService.getUser().then(value => {
      this.userProfile.email = value.email
    })
  }

  loaded = false;

  ngOnInit(): void {
    let script = this.renderer2.createElement('script');
    script.src = "https://js.stripe.com/v3/"

    this.renderer2.appendChild(this.document.body, script);
  }

  ngOnDestroy(): void {
    if(!this.isPaidSuccess){
      this.deleteResa(this.reservationId)
    }
  }

  ngAfterViewInit() {
    this.newReservation.paymentMethodType = this.paymentType;
    this.bookingService.checkout(this.newReservation).subscribe(reservationIntent => {
      this.clientSecret = reservationIntent.clientSecret;
      this.reservationId = reservationIntent.id;
      this.eventId = reservationIntent.eventId;
      this.loaded = true;
      this.initStripeCreditCard();
    }, error => {
      console.log(error);
      this.isLoad = false;
      // has used code promo
      this.isPaidSuccess = false;
      this.errorPayment = error.error.message;
    });
  }

  deleteResa(resaId: string): void {
    this.reservationsService.cancelReservation(resaId).subscribe(
      (res) => {},
      (error) => {
      }
    );
  }


  initStripeCreditCard(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.stripe = Stripe(environment.stripeKey, { locale: this.userPreferenceService.getCountry() });
      // Create an instance of Elements.
      const appearance = {
        fonts: [
          {
            family: "Panton",
            src: "url('https://db.onlinewebfonts.com/t/5920187ef0bf42859293e1ea01545b96.woff2')"
          }
        ]
      };
      const clientSecret = this.clientSecret;
      this.elements = this.stripe.elements({appearance, clientSecret});
      this.cardElement = this.elements.create("payment");
      this.cardElement.mount("#payment-element");
    }
  }

  handleFormSubmission() {
    if (!this.userProfile.firstName || this.userProfile.firstName == '' || !this.userProfile.lastName || this.userProfile.lastName == '' ) {
      this.errorPayment = this.translate.instant('checkout.error_name');
      this.isPaidSuccess = false;
      this.errorName = true;
      this.error = true;
    } else if ( this.userProfile.email == null ||
      this.userProfile.email == ""  ||
      !this.userProfile.email.match('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')) {
      this.errorPayment = this.translate.instant('checkout.error_mail');
      this.isPaidSuccess = false;
      this.errorMail = true;
      this.error = true;
    }
    else {
      this.doPayment();
    }
  }

  doPayment() {
    const eventId = this.eventId;
    var stripe = this.stripe;
    this.updateProfile();
    var self = this;
    if (
      self.userProfile.firstName == null ||
      (self.userProfile.firstName == "" &&
        self.userProfile.lastName == null) ||
      self.userProfile.lastName == ""
    ) {
      self.errorName = true;
      self.error = true;
    } else if ( self.userProfile.email == null ||
      self.userProfile.email == ""  ||
      !self.userProfile.email.match('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')) {
      self.errorMail = true;
      self.error = true;
    } else {
      this.inPayment = true;
      self.errorName = false;
      self.errorMail = false;
      self.errorConditions = false;
      self.error = false;
      self.hasPaid = true;
      event.preventDefault();
      self.isDisabled = true;
      if (self.clientSecret) {
        stripe.confirmPayment({elements: self.elements,
          redirect: "if_required",
          payment_method_data: {
            billing_details: { name: `${self.userProfile.firstName} ${self.userProfile.lastName}`, email: this.userProfile.email }
          },
          confirmParams: {
            return_url: 'https://www.anybuddyapp.com/checkout/complete?eventId=' + eventId,
          },
        }).then(function (result) {
          if (result.error) {
            self.hasPaid = false;
            self.isPaidSuccess = false;
            self.errorPayment = result.error.message;
            self.deleteResa(self.reservationId);
            this.inPayment = false;
          } else {
            self.paymentAnalytics();
            self.hasPaid = true;
            self.isPaidSuccess = true;
            self.newReservation = null;
            self.sessionStorageService.removeItem('voucher');
            self.sessionStorageService.removeItem("cart");
            self.modalService.dismissAll();
            self.router.navigate(['/checkout/complete'],{queryParams: { eventId: eventId }});
          }
        }).catch( error =>{
          console.log(error)
          this.inPayment = false;
          });
      } else if (!self.clientSecret) {
        if (!self.error) {
          self.paymentAnalytics();
          self.hasPaid = true;
          self.isPaidSuccess = true;
          self.newReservation = null;
          self.sessionStorageService.removeItem('voucher');
          self.sessionStorageService.removeItem("cart");
          self.modalService.dismissAll();
          sessionStorage.removeItem('stateService')
          self.router.navigate(['/checkout/complete'],{queryParams: { eventId: eventId }});
        } else {
          self.hasPaid = false;
          self.isPaidSuccess = false;
          if (self.errorName) {
            this.errorPayment = this.translate.instant('checkout.error_name');
          } else if (self.errorMail) {
            this.errorPayment = this.translate.instant('checkout.error_mail');
          } else if(self.errorConditions) {
            self.errorPayment = "Error Conditions";
          }
          this.inPayment = false;
        }
      }
    }
  }

  paymentAnalytics() {
    const resaEvent = {
      "transaction_id": this.reservationId,
      "currency": this.newReservation.currency,
      "value": this.newReservation.price / 100,
      "items": [{
        "item_id": this.newReservation.centerId,
        "item_name": this.center.name,
        "price": this.newReservation.price / 100,
        "category": this.service.name,
        "item_brand": this.newReservation.activityId,
        "list_position": 0,
        "quantity": 1
      }]
    };

    (<any>window).gtag("event", "purchase", resaEvent);

    (<any>window).fbq('track', 'Purchase', {
      currency: this.newReservation.currency,
      value: this.newReservation.price / 100,
      contents: [
        {
          id: this.reservationId,
          quantity: this.service.name
        }
      ],
      content_type: 'product'
    });
  }

  updateProfile() {
    this.userService
      .updateProfile(
        this.userProfile.firstName,
        this.userProfile.lastName,
        this.userProfile.email
      )
      .subscribe(
        (_: User) => {}, // success path
        error => console.log(error) // error path
      );
  }

}


