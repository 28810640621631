<div class="container">
    <div class="d-flex flex-column p-O" id="top">
        <nav class="navbar navbar-light justify-content-between mt-1 p-0 mb-5">
        </nav>
        <div>
            <h4 class="title">{{ 'center_calendar.choose_date_and_time' | translate }}</h4>
        </div>

        <div class="mt-5">
            <ngb-datepicker #dp [(ngModel)]="model" (navigate)="date = $event.next" [minDate]="startDate" [startDate]="model"></ngb-datepicker>
        </div>

        <div class="d-flex mt-5 mb-3">
            <div class="d-flex flex-row-reverse align-items-center container-icon">
                <img class="me-3" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/icon/time-line.png">
            </div>
            <div class="dropdown">
                <div class="dropbtn">
                    <div ngbDropdown class="select-custom">
                        <span id="dropdownBasic1" ngbDropdownToggle class="date-picker">{{ hours }}</span>
                        <svg class="arrow-down" ngbDropdownToggle xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"/></svg>
                        <div class="items-container dropdown-container" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <div *ngFor="let hour of allHours"
                                ngbDropdownItem class="items"
                                (click)="onTimeChanged(hour)">
                                    {{ hour }}
                            </div>
                        </div>
                    </div>
                </div>

              </div>
        </div>

        <div class="p-3 d-flex justify-content-center footer">
            <button class="submit-filter" (click)="getDate()">{{ 'center_calendar.display_result' | translate }}</button>
        </div>
    </div>
</div>
