import {
  AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FeatureGroup, Icon, LeafletEvent, Map, Marker, Popup } from 'leaflet';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { MapService } from '../map.service';
import { Center } from '../models/center';
import { AvailabilitiesService } from '../service/availabilities.service';
import { CityService } from '../service/city.service';
import { SearchService } from '../service/search.service';
import { CenterAvailabilitiesService, SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';
/* eslint-disable */
enum IconMarker {
    Default = 'Ping',
    Selected = 'Ping-yellow',
    NoPartner = 'Ping_gris',
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit ,AfterViewChecked, OnDestroy, OnChanges {

    @ViewChild('filterContent') filterContent: TemplateRef<any>;

    @Output() isMapLoaded = new EventEmitter<boolean>();
    @Output() infoChanges = new EventEmitter();
    @Output() mapInit = new EventEmitter<boolean>();
    @Output() cityChange = new EventEmitter<string>();
    @Input() public displayMenu = true;
    @Input() data;
    @Input() charachterics=""
    @Input() price =""
    @Input() servicetype=""
    @Input() radius=''
    @Input() isPartner;
    @Input() hasSport;

    public filtercharchteristic = [];
    public isSession: boolean = false;
    public dataList: any = [];
    public sport :string='';
    public city: string='';
    public date: string='';
    public serviceTypeInfo='';
    public service: string = '';
    public layer = {
        default: {
            url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        }
    };
    public dataCenterData$: Observable<any>;
    public mapReady = false;
    public isMobileResolution: boolean;
    public isMobile: boolean = false;

    private map: Map;
    private sub: Subscription;
    private iconMarker = IconMarker;
    private location: {lat: number, lon: number};
    public mapHTML: any;
    public sourceFromAvailabilities: boolean = true;
    public isFitBoundTriggered: boolean = false;
    public displaySearch = false;
    private markers: Marker[] = [];

    constructor(
        private centerAvailabilitiesService: AvailabilitiesService,
        private router: Router,
        private mapService:MapService,
        private activateRoute: ActivatedRoute,
        private translate: TranslateService,
        private deviceService: DeviceDetectorService,
        private sharedService: SharedService,
        private cdr: ChangeDetectorRef,
        private modalService: NgbModal,
        private searchService:SearchService,
        private sharedCenterAvailabilities: CenterAvailabilitiesService,
        private cityService: CityService,
        private userPreferenceService: UserPreferenceService
    ) {
        this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
        this.sharedService.changeEmitted$.subscribe(
            (isMobileResolution: boolean) => {
              this.isMobileResolution = isMobileResolution;
        });
     }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes.hasSport != undefined){
        if(changes.hasSport.currentValue){
          this.hasSport = changes.hasSport.currentValue;
        }
      }
    }

    ngOnInit(): void {
        if(this.activateRoute.snapshot.data['city'] !== undefined){
            this.city = this.activateRoute.snapshot.data['city'].slug;
            this.location = this.activateRoute.snapshot.data['city'].location;
        }
        if (this.activateRoute.snapshot.queryParamMap.get('activity')) {
            this.sport = this.activateRoute.snapshot.queryParamMap.get('activity');
        } else if (this.activateRoute.snapshot.paramMap.get('sport')) {
            this.sport = this.activateRoute.snapshot.paramMap.get('sport');
        } else {
            this.sport = this.activateRoute.snapshot.url[0].path.split('-')[2];
        }
        if(this.activateRoute.snapshot.queryParams['date'] !== undefined){
            this.date = this.activateRoute.snapshot.queryParams['date'];
        }
        if (this.activateRoute.snapshot.url[1].path === 'fitness') {
            this.isSession = true;
        }
        if (this.activateRoute.snapshot.queryParams['servicetype'] !== undefined) {
            this.service = this.activateRoute.snapshot.queryParams['servicetype'];
        }
        if (this.activateRoute.snapshot.queryParams['characteristic'] !== undefined) {
            this.filtercharchteristic = this.activateRoute.snapshot.queryParams['characteristic'].split(',');
        }
    }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }

    ngAfterViewInit(): void {
        this.initMap();
        this.mapReady = true;
        if (this.activateRoute.snapshot.url[2] !== undefined && this.activateRoute.snapshot.url[2].path === 'map') {
            this.dataList = this.sharedCenterAvailabilities.getDataList();
            const markers = this.initMarker();
            this.putMarker(markers);
        } else {
            this.sub = this.sharedCenterAvailabilities.changeEmitted$.subscribe( res => {
                this.initMap();
                if (this.sourceFromAvailabilities && this.sharedCenterAvailabilities.getIsFromFilter()) {
                    this.dataList = [];
                }
                if (this.sourceFromAvailabilities) {
                    if (res && !res.length) {
                        this.dataList = [];
                    } else {
                        this.dataList = res;
                    }
                    const markers = this.initMarker();
                    this.putMarker(markers);
                }
                this.sourceFromAvailabilities = true;
            });
        }
        if(!this.hasSport){
          this.onSearch(false);
        }
    }

    private putMarker(markers: Marker<any>[]): void {
        this.markers.forEach(marker => {
            this.map.removeLayer(marker)
        })
        if (markers.length > 0) {
            this.markers = this.createGroupMarkers(markers);
            const groupe: FeatureGroup = this.mapService.L.featureGroup(this.markers).addTo(this.map);
            this.isFitBoundTriggered = true;
            //this.map.fitBounds(groupe.getBounds());
            this.mapService.L.featureGroup(this.markers).addTo(this.map);

            groupe.on('mouseout', (event: LeafletEvent) => {
                // if(environment.profile=='anybuddy'){
                //     event.propagatedFrom.setIcon(this.getIconMarker(this.iconMarker.Default));
                // }
                // else{
                //     event.propagatedFrom.setIcon(this.getIconMarker(this.iconMarker.dkt));
                // }

            });
        }

        this.isMapLoaded.emit(true);
        this.mapReady = true;
    }

    public onSearch(emiteChange = true) {
        const {lat, lng} = this.map.getCenter();
        const pos: string = `${lat},${lng}`;
        const NE = this.map.getBounds().getNorthEast();
        const radius = this.getDistanceFromLatLonInKm(lat, lng, NE.lat, NE.lng) * 1.5;
        this.radius = radius.toString();
        this.searchService.getGeoname(pos).pipe(
            map((res: any) => res.slug),
            tap((res: string) => {
                const city = this.cityService.formatCity(res);
                this.updateRadiusQueryParams(city);
            }),
            switchMap((slug: string) => {
                let price = '';
                if (this.price) {
                    price = (parseInt(this.price) * 100).toString();
                }
                this.cityChange.emit(slug)
                return this.getDataCenter(slug, this.sport, '', this.date, '', '', price, radius, true, emiteChange);
            })
        ).subscribe(
            (res: any) => {
                this.markers.forEach(marker => {
                    this.map.removeLayer(marker)
                })
                const markers = this.initMarker();
                this.putMarker(markers);
            }
        );
    }

    private getDataCenter(city,sport,serviceType,dateInfo,service,charachterics,price,radius,isPartner, emiteChange = true): Observable<any>{
      const sportTrad = (sport == "undefined" || sport == undefined) ? 'undefined' : this.translate.instant(`keySport.${sport}`);
      if(emiteChange){
        this.sharedCenterAvailabilities.emitTrigerred();
      }
      this.markers.forEach(marker => {
        this.map.removeLayer(marker)
      })
        return this.centerAvailabilitiesService.getCentersMap(city,sportTrad,serviceType,dateInfo,service,charachterics,price,radius, isPartner).pipe(
            tap((res) => {
                this.dataList = res;
                this.sourceFromAvailabilities = false;
                if(emiteChange){
                  this.sharedCenterAvailabilities.emitChange(this.dataList);
                }
            })
        );
    }

    private initMarker(): Marker[] {
        const markers = []
        this.dataList.map(item => {
            let showRating = "block";
            let showDescription = "block"
            if(item.rating === undefined){
                showRating="none"
            }
            if(item.shortDescription === undefined){
                showDescription="none"
            }
            if (!markers.find(el => el.id === item.id)) {
                markers.push({
                    coords: {...item.location},
                    title: item.name,
                    rating: (item.rating === undefined) ? '' : parseFloat(item.rating).toFixed(2),
                    ratingCount:(item.ratingCount === undefined) ? '' : parseInt(item.ratingCount),
                    centerName: item.name,
                    image: item.headerPhoto['280x140'],
                    id: item.id,
                    shortDescription: item.shortDescription,
                    showRating:showRating,
                    showDescription:showDescription,
                    dispoButton:this.translate.instant('detail.seeDispo')
                });
            }
        });
        return markers;
    }

    private initMap(): void {

        const parcCenter = {
            lat: this.location.lat,
            lng: this.location.lon
        };

        if (this.map || this.map != undefined || this.map != null) {
            this.map.off();
            this.map.remove();
        }

        const container = this.mapService.L.DomUtil.get('map');
        if (container != null) {
            container._leaflet_id = null;
        }

        this.map = new this.mapService.L.map('map', {
            center: [parcCenter.lat, parcCenter.lng],
            zoom: 11,
        });

        this.mapService.L.tileLayer(this.layer.default.url, {
            attribution: this.layer.default.attribution
        }).addTo(this.map);
    }

    private getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2): number {
        const R = 6371; // Radius of the earth in km
        const dLat = this.deg2rad(lat2-lat1);
        const dLon = this.deg2rad(lon2-lon1);
        const a =
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon/2) * Math.sin(dLon/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        const d = (R * c); // Distance in km
        return Math.round(d - 1);
      }

      private deg2rad(deg) {
        return deg * (Math.PI/180)
      }

    private getIconMarker(iconMarker: string): Icon {
        const icon = this.mapService.L.icon({
            iconUrl: `assets/images/${iconMarker}.png`,
            iconSize: [32, 32],
            iconAnchor: [36 / 2, 36],
            popupAnchor: [0, -32],
            tooltipAnchor: [0, -32]
        });
        return icon;
    }

    private createTemplatePopup(item: any): string {
        const template =
        `<div class="popup-container">
            <div class="popup-container-img">
                <img src="${item.image}" />
            </div>
            <div class="popup-container-content">
                <div class="popup-container-rating" style="display:${item.showRating}">
                    <img class="star-icon" src="/assets/images/star-fill.png" alt="rating">
                    <span class="rating">${item.rating}</span>
                    <span>(${item.ratingCount})</span>
                </div>
                <p class="title-card">${item.centerName}</p>
                <div class="content-text" style="display:${item.showDescription}">
                    <p>
                    ${item.shortDescription}
                    </p>
                </div>
            </div>
            <button class="button-rounded" name="${item.id}">${item.dispoButton}</button>
        </div>
        `;
        return template;
    }

    private createPopup(item: any): Popup {
        const templatePopup: string = this.createTemplatePopup(item);
        const popup: Popup = this.mapService.L.popup({
            maxWidth: 280,
            className: 'popupCustom',
        })
        .setLatLng([item.coords.lat, item.coords.lon])
        .setContent(templatePopup);
        return popup;
    }
    @HostListener('click', ['$event']) clickout(event): void  {
        if (event.target.classList.contains('button-rounded')){
         this.getCenter(event.target.name);
        }
    }
    @HostBinding('style.color') color: string;
    private createGroupMarkers(dataMarkers: any[]): Marker[] {
        const markers: Marker[] = [];
        let mark;
        dataMarkers.forEach(item => {
            mark = this.iconMarker.Default;
            if (item.isPartner === false) {
                mark = this.iconMarker.NoPartner;
            }
            const popup: Popup = this.createPopup(item);
            const marker: Marker = this.mapService.L.marker(item.coords, {
                icon: this.getIconMarker(mark),
            }).bindPopup(popup);
            marker.on('click', () => {
                marker.openPopup();
            });
            markers.push(marker);
        });
        return markers;
    }

    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    getCenter(idCenter: string): void{
        let centerSelected: Center;
        for (const center of this.dataList) {
            if (center.id === idCenter) {
                centerSelected = center;
                break;
            }
        }
        this.router.navigate([centerSelected.getPath(), 'reservation']);
    }

    isFilter(event): void {
        this.modalService.open(this.filterContent, { centered: true })
    }

    public submitFilter(filter): void {
        let charId = [];
        if (filter[0] !== undefined) {
            this.servicetype = filter[0].id
        }
        if (filter[1].length > 0) {
            for (const key of filter[1]) {
                charId.push(key.id)
            }
        }
        this.charachterics = charId.toString();
        this.price = filter[2];
        const searchPrice = (parseInt(this.price) * 100).toString();
        if (filter[3]) {
            this.radius = filter[3]
        }
      this.cityChange.emit(this.city)
        this.getDataCenter(this.city, this.sport, this.serviceTypeInfo, this.date, this.servicetype, this.charachterics, searchPrice, this.radius, this.isPartner).subscribe( res => {
            const markers = this.initMarker();
            this.putMarker(markers)
        }
    );
        this.updateRadiusQueryParams();
        this.modalService.dismissAll()
    }


      private updateRadiusQueryParams(newCity?: string): void {
        const url = this.activateRoute.snapshot.url;
        let pathUrl: string;
        let city = newCity ? newCity : this.city;
        if (url[0].path === "reservation") {
            const [path, sport, map] = url;
            pathUrl = `${path}/${sport}/${map}/${city}`
        } else {
            pathUrl = `${url[0]}/${city}`;
        }
        let price = '';
        if (this.price) {
           price = (parseInt(this.price) * 100).toString();
        }
        const activity = this.translate.instant(`keySport.${this.sport}`)
        const navigationExtras: NavigationExtras = {
          skipLocationChange: false,
          queryParams: {
              activity: activity,
              servicetype: this.servicetype,
              characteristic: this.charachterics,
              radius: this.radius,
              searchPrice: price,
        },
          queryParamsHandling: 'merge'
        };
        if (this.userPreferenceService.getCountry() !== 'fr') {
            this.router.navigate([`${this.userPreferenceService.getCountry()}/${pathUrl}`], navigationExtras)
        } else {
            this.router.navigate([`${pathUrl}`], navigationExtras)
        }
      }
}
