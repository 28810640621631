import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CountryData, COUNTRY_LIST, LanguageData, LANGUAGE_LIST, NewLanguageData, NEW_LANGUAGE_LIST } from '../models/language-data';

@Injectable({
  providedIn: 'root'
})

export class UserPreferenceService {

  private locale = 'fr-FR';
  private langs = [
    'fr-FR',
    'fr-CH',
    'fr-BE',
    'nl-BE',
    'es-ES',
    'en-FR',
    'en-ES',
    'en-CH',
    'en-BE',
  ];
  private langsList = LANGUAGE_LIST;
  private countryLits = COUNTRY_LIST;
  private newLangList = NEW_LANGUAGE_LIST;
  private localChangeCountry = new BehaviorSubject<string>('fr');

  private countryChoosed = new BehaviorSubject<CountryData>(COUNTRY_LIST[0]);
  private languageChoosed = new BehaviorSubject<NewLanguageData>(NEW_LANGUAGE_LIST[0]);


  constructor(
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId) {
  }

  init(): void {

    let locale = 'fr-FR';
    if (isPlatformBrowser(this.platformId)){
      sessionStorage.removeItem('locale');
    }
    if (isPlatformBrowser(this.platformId) && this.langs.findIndex(l => l.startsWith(navigator.language)) !== -1) {
      locale = navigator.language;
    }
    else {
      if (isPlatformBrowser(this.platformId) && sessionStorage.getItem('locale') && sessionStorage.getItem('locale') !== null){
        locale = sessionStorage.getItem('locale');
      }
    }
    let lang: string = this.translate.currentLang;
    if (this.translate.currentLang === 'fr' && locale !== 'fr-FR') {
      lang = this.langsList.find((l: LanguageData) => (l.languageCode === locale || l.code === locale)).countryCode;
    }
    const langData: LanguageData[] = this.langsList.filter((l: LanguageData) => lang.toUpperCase() === l.countryCode);
    if (langData && langData.length > 1) {
      const langSelected = langData.find((l: LanguageData) => locale === l.languageCode);
      if (langSelected) {
        locale = langSelected.code;
      } else {
        locale = langData.find((l: LanguageData) => l.languageCode === 'fr').code;
      }
    } else {
      locale = langData[0].code;
    }

    if (this.langs.findIndex(l => l.startsWith(locale)) !== -1) {
      this.locale = this.langs.find(l => l.startsWith(locale));
    } else {
      this.locale = this.langs.find(l => l.startsWith(lang));
    }
    if (this.locale === null || this.locale === undefined) {
      this.locale = 'fr-FR';
    }
    this.translate.use(this.getLang()).subscribe(
      () => {
        this.changeLocalCountry(this.getCountry());

        this.changeChoosedCountry(this.countryLits.find(c => c.countryCode === this.getCountry().toUpperCase()));
        this.changeChoosedLanguage(this.newLangList.find(c => c.trueLanguageCode === this.getLang().toLowerCase()));
      }
    );
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem('locale', this.locale);
    }

  }

  setLocale(lang: string, trueLang: string, country: string): void {
    if (this.isLocaleValid(lang, country)) {
      this.locale = `${lang}-${country.toUpperCase()}`;
      if (isPlatformBrowser(this.platformId)) {
        sessionStorage.removeItem('locale');
        sessionStorage.setItem('lang', this.locale);
        this.translate.use(trueLang).subscribe(() => {
           // this.localizeService.changeLanguage(lang); // this break NL switch languages
          this.changeLocalCountry(this.getCountry());
        });
      }

    }
  }

  isLocaleValid(lang: string, country: string): boolean {
    if (lang && country) {
      const langCountry = `${lang}-${country}`;
      if (this.langs.includes(langCountry)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  setLocaleFromCountry(country: string): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.getCountryFromLocale(this.locale) !== country.toLowerCase()) {
        const locale = this.langs.find(l => this.getCountryFromLocale(l).endsWith(country.toLowerCase()));
        if (locale) {
          this.locale = locale;
          sessionStorage.removeItem('locale');
          sessionStorage.setItem('locale', this.locale);
          this.translate.use(this.getLang()).subscribe(() => {
            this.changeLocalCountry(this.getCountry());
          });
        }
      }
    }
  }

  getLang(): string {
    return this.locale.split('-')[0];
  }

  getCountry(): string {
    return this.locale.split('-')[1].toLowerCase();
  }

  getCountryFromLocale(locale: string): string {
    return locale.split('-')[1].toLowerCase();
  }

  getLocale(): string {
    return this.locale;
  }

  getlocalChangeCountry(): Observable<string>{
    return this.localChangeCountry.asObservable();
  }

  private changeLocalCountry(country: string): void {
    this.localChangeCountry.next(country);
  }



  getChoosedCountry(): Observable<CountryData>{
    return this.countryChoosed.asObservable();
  }

  changeChoosedCountry(country: CountryData): void {
    this.countryChoosed.next(country);
  }

  getChoosedLanguage(): Observable<NewLanguageData>{
    return this.languageChoosed.asObservable();
  }

  changeChoosedLanguage(lang: NewLanguageData): void {
    this.languageChoosed.next(lang);
  }
}
