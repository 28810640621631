import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Center } from '../../models/center';
import { Chracteristics } from '../../models/characteristic';
import { InfoPaiement } from '../../models/infoPaiment';
import { Places } from '../../models/places';
import { Prices } from '../../models/prices';
import { ServiceInformation } from '../../models/serviceInformation';
import { ServicesSlots } from '../../models/slots';
import { CentersService } from '../../service/center.service';
import { InfoPaiementService, PriceService, SlotService, StateService } from '../../service/shared.service';
import { UserPreferenceService } from '../../service/user-preferences.service';
/* eslint-disable */
@Component({
  selector: 'app-multiple-prices',
  templateUrl: './multiple-prices.component.html',
  styleUrls: ['./multiple-prices.component.scss'],
  providers: [PriceService]
})
export class MultiplePricesComponent implements OnInit, OnDestroy {
  @Input() activities: any;
  @Input() centerId: string;
  @Input() center: Center;
  @Input() sportAvaibility: string
  @Input() servPrice: ServiceInformation;
  @Input() initial: ServiceInformation;
  @Input() slotId: string;
  @Input() placeDispo: number;
  @Input() fee: number;
  @Input() startReset = false;
  @Output() newInfoPaiement = new EventEmitter<InfoPaiement>();
  color: string = '#FFC300'
  displayAvailabilities = false;
  error: any;
  selectedDate: string;
  loading: boolean;
  //infoPaiement: InfoPaiement;
  prices: Prices[] = []
  places: Places[] = []
  empList: Array<Places> = [];
  characteristic: Chracteristics[] = []
  resaForm: UntypedFormGroup;
  selectedSport: any;
  typeSelected: boolean;
  sportSelected: boolean;
  partySize = 0;
  nbrPlaces = 0;
  selectedType: string;
  types: any[];
  filters: any[];
  sportName: boolean = false;
  servicesSlots: ServicesSlots[];
  serviceTypeFiltre: any[];
  numerPlaces: UntypedFormGroup;
  j: any;
  placePrise = 0
  closeModalWithValidate = false;
  //placeDispo=0
  @Input() isChangeNbrFromPaiement: boolean;
  @Input() infoPaiement: InfoPaiement;
  isReset: boolean = false;
  constructor(
    private centersService: CentersService,
    private activateRoute: ActivatedRoute,
    private slotService: SlotService,
    public modalService: NgbModal,
    private userPreferenceService: UserPreferenceService,
    private router: Router,
    private stateService: StateService,
    private cdRef: ChangeDetectorRef,
    public infoPaiementService: InfoPaiementService,) { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.servPrice && changes.servPrice.currentValue) {

    }
  }

  ngOnInit() {
    this.closeModalWithValidate = false;
    if (this.isReset === false) {
      this.init(this.servPrice.prices);
      if (this.startReset) {
        this.reset(this.servPrice)
      }
      this.sharedData();
    }
  }

  ngOnDestroy() {
    if (this.empList.length === 0 && this.infoPaiement) {
      this.empList = this.infoPaiement.places
    }
    this.validateNbrPlaces(this.servPrice);
  }

  private init(prices: any[]) {
    const group = {};
    this.total = 0
    prices.forEach(prix => {
      const name = prix.name
      const id = prix.id
      const prixInitParCat = prix.price
      if (this.isChangeNbrFromPaiement === true && this.isReset === false) {
        console.log(this.infoPaiement)
        const categorieInfos = this.infoPaiement.places.find(el => el.priceRuleId === id);
        if (categorieInfos === undefined) {
          group[name] = new UntypedFormControl(0);
        } else {
          group[name] = new UntypedFormControl(categorieInfos['nbPlace']);
        }
        this.placeDispo = this.servPrice.totalCapacity
        this.placePrise = this.servPrice.totalCapacity - this.servPrice.availablePlaces
        this.total = this.totalParCategorie(categorieInfos, prixInitParCat);
      } else {
        group[name] = new UntypedFormControl(0);
        this.total = 0
      }
      const price = prix.price
      group[price] = new UntypedFormControl();
    });
    this.numerPlaces = new UntypedFormGroup(group)
  }

  total = 0
  incrementNbrPlaces(name, prix, id, nbplace, i, prixtotal = null): void {

    let customObj = new Places();
    customObj.nbPlace = 0
    let incrementUnit = (nbplace && nbplace != null && nbplace != undefined) ? nbplace : 1;
    if (this.nbrPlaces + incrementUnit <= this.servPrice.availablePlaces && this.servPrice.availablePlaces >= 1) {
      let place = this.numerPlaces.controls[name].value;
      place++;
      this.placePrise += incrementUnit;
      this.servPrice.availablePlaces = this.servPrice.availablePlaces - incrementUnit;
      this.initial.prices.forEach(element => {
        this.numerPlaces.controls[name].setValue(place);
      });
      this.total = this.total + prix;
      this.partySize = this.servPrice.availablePlaces
      this.prixfinal = this.total
      if (this.isChangeNbrFromPaiement === true) {
        this.empList = this.infoPaiement.places
      }
      let val = this.empList.find(r => r.priceRuleId == id)
      if (val) {
        val.nbPlace++;
        customObj.nbPlace = val.nbPlace;
      } else {
        customObj.priceRuleId = id;
        customObj.nbPlace++;
        customObj.name = name;
        customObj.price = prix
        this.empList.push(customObj);
      }
      this.nbrPartUpdate();
    }

  }
  public prixfinal = 0

  decrementNbrPlaces(name, prix, idPrice, nbplace, i, prixtotal = null): void {
    let incrementUnit = (nbplace && nbplace != null && nbplace != undefined) ? nbplace : 1;
    let place = this.numerPlaces.controls[name].value;
    if (place >= 1) {
      this.placePrise -= incrementUnit;
      place--;
      this.servPrice.availablePlaces = this.servPrice.availablePlaces + incrementUnit;
      this.initial.prices.forEach(element => {
        this.numerPlaces.controls[name].setValue(place);
      });
      this.total = this.total - prix;
      if (this.isChangeNbrFromPaiement === true) {
        this.empList = this.infoPaiement.places
      }
      this.prixfinal = this.total
      let val = this.empList.find(r => r.priceRuleId == idPrice)
      val.nbPlace = val.nbPlace - 1;
      this.nbrPartUpdate();
    }
  }

  nbrPartUpdate(): void {
    if (this.activateRoute.snapshot.paramMap.get('sport')) {
      this.selectedSport = this.activateRoute.snapshot.paramMap.get('sport');
      this.fetchActivityTypes(this.centerId, this.selectedSport);
    }
    this.updateAvailabilities();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();

  }
  onSelectDefautType(): void {
    if (this.resaForm) {
      this.resaForm.controls.filterChoices = new UntypedFormArray([])
    }
    this.selectedType = this.types[0]["name"];
    this.filters = this.types[0]["resourceCharacteristicTypes"]
    let arrFiltre = this.types[0]["resourceCharacteristicTypes"].concat(this.types[0]["serviceCharacteristicTypes"])
    let foo = new Map();
    for (const tag of arrFiltre) {
      foo.set(tag.id, tag);
    }
    let final = [...foo.values()]
    this.filters = final;
    if (this.resaForm) {
      this.resaForm.controls.resaType.setValue(this.types[0].id);
    }
    this.fetchActivityFilters(this.types[0].id);
  }

  totalPrix = 0;
  totalParCategorie(categorieInfos, prixInitParCat): number {

    if (categorieInfos !== undefined) {
      const price = categorieInfos['nbPlace'] * prixInitParCat
      this.totalPrix = this.totalPrix + price
    }
    else {
      this.totalPrix = this.totalPrix
    }

    return this.totalPrix
  }
  // appelé quand le type est choisi
  fetchActivityFilters(type): void {
    this.loading = true;
    this.typeSelected = true;
    this.loading = false;
  }

  fetchActivityTypes(centerid, sport): void {
    this.loading = true;
    //brancher au moment d'api filter sera merger
    this.centersService.getFilter(centerid, sport).subscribe(
      res => {
        this.types = res;
        if (this.types.length === 1) {
          this.onSelectDefautType();
        }
        this.sportSelected = true;
        this.loading = false;
        this.typeSelected = true;
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.sportSelected = false;
        this.loading = false;
      }
    )
  }
  updateAvailabilities(): void {
    this.loading = true;
    let filters = "";
    let types = "";
    if (this.resaForm) {
      filters = this.resaForm.controls.filterChoices.value.toString();
      types = this.resaForm.controls.resaType.value;
    }
    this.centersService.getCentersAvailabilities(
      this.centerId,
      this.selectedSport,
      this.selectedDate,
      types,
      filters,
      this.partySize)
      .subscribe(
        res => {
          this.servicesSlots = res.data;
          this.slotService.emitChange(this.servicesSlots);
          this.loading = false;
          if (this.servicesSlots.length === 0) {
            this.displayAvailabilities = true;
          }
        },
        error => {
          this.error = error.message;
          this.loading = false;
        }
      );
  }
  public reset(servPrice): void {
    this.isReset = true
    this.servPrice.prices.forEach(prix => {
      this.numerPlaces.controls[prix.name].setValue(0)
    });
    this.placeDispo = servPrice.totalCapacity;
    this.placePrise = 0
    this.total = 0;

    if (this.isChangeNbrFromPaiement === true) {
      servPrice.availablePlaces = this.placeDispo;
      this.empList = null
      this.init(this.servPrice.prices);

      this.infoPaiement.places.forEach(element => {
        element.nbPlace = 0
      });
    }

    else {

      servPrice.availablePlaces = this.servPrice.totalCapacity;

      let infoPaiement = new InfoPaiement()
      infoPaiement.centerId = this.centerId;
      infoPaiement.serviceId = this.servPrice.id;
      infoPaiement.slotId = this.slotId;
      infoPaiement.price = this.prixfinal;
      infoPaiement.places = this.empList;
      this.infoPaiement = infoPaiement;
      this.infoPaiement.places.forEach(element => {
        element.nbPlace = 0
      });
    }
    this.init(this.servPrice.prices);
    this.infoPaiementService.servPrice = this.infoPaiement
    this.sharedData();
  }

  sharedData(): void {
    this.stateService.servPrice = this.servPrice;
    this.stateService.initial = this.initial;
    this.stateService.nbrBooked = this.placePrise;
    this.stateService.total = this.total;
    console.log('letotal', this.stateService)
    sessionStorage.setItem('stateService', JSON.stringify(this.stateService));
    if (this.fee) {
      this.stateService.total += this.fee;
    }

  }

  closeDialog() {
    this.modalService.dismissAll();
  }

  public validateNbrPlaces(service): void {
    let infoPaiement = new InfoPaiement()
    infoPaiement.centerId = this.centerId;
    infoPaiement.serviceId = this.servPrice.id;
    infoPaiement.slotId = this.slotId;
    infoPaiement.price = this.prixfinal;
    if (this.empList == null || this.empList.length == 0) {
      if (this.infoPaiementService.servPrice.places != undefined && this.infoPaiementService.servPrice.places.length > 0) {
        this.empList = this.infoPaiementService.servPrice.places;
      } else {
        this.fillWithEmpty();
        this.infoPaiementService.servPrice.places = this.empList;
      }
    }
    infoPaiement.places = this.empList;
    console.log('list', this.empList)
    this.newInfoPaiement.emit(infoPaiement)
    this.sharedData();
    let route = '';

    if (this.userPreferenceService.getCountry() !== 'fr') {
      route = '/es/checkout/' + this.centerId + '/' + service.id
    } else {
      route = '/checkout/' + this.centerId + '/' + service.id
    }
    this.infoPaiementService.servPrice = infoPaiement
    if (this.closeModalWithValidate == true) {
      this.router.navigate([route], { state: infoPaiement });
    }
    this.closeDialog();
  }

  fillWithEmpty(): void {
    this.empList = [];
    this.servPrice.prices.forEach(element => {
      let places = new Places();
      places.priceRuleId = element.serviceId;
      places.name = element.name;
      places.price = element.price;
      places.nbPlace = 0;
      this.empList.push(places);
    });
  }

}
