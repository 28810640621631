<div class="d-flex flex-column mt-4 mx-2" *ngIf="isMobileResolution; else reviewsDesktop">

    <div class="header-div d-flex flex-column mx-2">
        <div class="row mb-2">
            <div class="col-2">
                <button class="custom-img  align-self-start me-5" (click)="onCloseReviewPage(false)">
                    <img class="navbar-icon" src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/icon/close-circle-fill.png"
                        alt="{{'reviews.close' | translate}}">
                </button>
            </div>
            <div class="col-8">
                <div class="align-self-end card-title mb-0 ms-3">
                    {{'reviews.club_reviews' | translate}}
                </div>
            </div>
            <div class="col-2">
            </div>
        </div>
        <h2 class="mb-2 horizontal-line"></h2>
    </div>

    <div class="spinner-border spinner" [ngStyle]="{'color':'#ffc300'}" role="status" *ngIf="loading">
        <span class="sr-only"></span>
    </div>

    <div *ngIf="centerRatings && centerRatings.clubRatingCount > 0; else noReview">

        <div>
            <div class="d-flex justify-content-center mb-4 mx-2 mt-4">
                <app-star-rating [rating]="this.center.rating" [ratingCount]="this.center.ratingCount"
                    [veryShort]="true" [short]="true" [yellow]="true" [big]="true" [grey]="true">
                </app-star-rating>
                <div class="card-title mt-2 ms-3">
                    {{center.name}}
                </div>
            </div>

            <ng-container>
                <div class="d-flex justify-content-between mb-3 mx-4">
                    <h5 class="card-subtitle align-self-center">{{'reviews.club' | translate}}</h5>
                    <div class="me-1">
                        <app-multiple-stars-rating [rating]="centerRatings.clubRating"></app-multiple-stars-rating>
                    </div>
                </div>
                <div class="d-flex justify-content-between mb-3 mx-4" *ngIf="centerRatings.resourceRating!==undefined">
                    <h5 class="card-subtitle align-self-center">{{'reviews.sessions' | translate}}</h5>
                    <div class="me-1">
                        <app-multiple-stars-rating [rating]="centerRatings.resourceRating"></app-multiple-stars-rating>
                    </div>
                </div>
                <div class="d-flex justify-content-between mb-3 mx-4">
                    <h5 class="card-subtitle align-self-center">{{'reviews.facilities' | translate}}</h5>
                    <div class="me-1">
                        <app-multiple-stars-rating [rating]="centerRatings.serviceRating"></app-multiple-stars-rating>
                    </div>
                </div>
               <!--  <div class="d-flex justify-content-between mb-3 mx-4">
                    <h5 class="card-subtitle align-self-center">{{'reviews.teachers' | translate}}</h5>
                    <div class="me-1">
                        <app-multiple-stars-rating [rating]="centerRatings.staffRating"></app-multiple-stars-rating>
                    </div>
                </div>-->
            </ng-container>
        </div>

        <h2 class="mb-2 horizontal-line"></h2>


        <div class="d-flex flex-column mt-4">
            <ng-container *ngFor="let review of centerRatings.data">
                <div class="d-flex flex-column ms-4 mb-5">
                    <div class="me-1 mb-3">
                        <app-multiple-stars-rating [rating]="review.clubRating"></app-multiple-stars-rating>
                    </div>
                    <span class="card-subtitle">
                        {{review.content}}
                    </span>
                    <h2 class="mb-2 mt-2 horizontal-line horizontal-line-short"></h2>
                    <span class="card-subtitle grey-text mt-0">
                        {{review.reviewerName}} - {{review.createdAt | date:'MMMM y' }}
                    </span>
                </div>
            </ng-container>
        </div>

        <div class="d-flex mb-3 underline mx-3" *ngIf="false">
            <button class="custom-link-grey custom-img underline align-self-start" >
                {{'reviews.see_more_reviews' | translate}}
            </button>
            <img class="arrow-img custom-img align-self-center mb-0" alt="{{'reviews.star' | translate}}"
                src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/arrow/arrow-down-grey.png">
        </div>
    </div>

    <ng-template #noReview>
        {{'reviews.no_reviews' | translate}}
    </ng-template>

    <h2 class="mb-2 horizontal-line"></h2>

    <app-assistance class="mx-4" [isMobileResolution]="isMobileResolution"></app-assistance>

</div>

<ng-template #reviewsDesktop>

    <div *ngIf="centerRatings">

        <div class="d-flex flex-column mt-4 mx-2"
            [ngClass]="{'mt-4 mb-5': !isMobileResolution,  'mt-4 mb-0': isMobileResolution}"
            *ngIf="centerRatings.clubRatingCount > 0; else noReview">

            <h5 class="card-title mt-5 mx-4 mb-4">{{'reviews.club_reviews' | translate}}</h5>

            <div class="mb-4">
                <div class="d-flex  mb-5 mx-4 mt-4">
                    <app-star-rating [rating]="this.center.rating" [ratingCount]="center.ratingCount" [long]="true"
                        [short]="true" [yellow]="true" [big]="true" [grey]="true">
                    </app-star-rating>
                </div>

                <ng-container>
                    <div class="row mt-5 mb-3" style="margin-left: 0px;">
                        <div class="col-4">
                            <div class="d-flex justify-content-start mb-3 mx-2">
                                <h5 class="card-subtitle align-self-center">{{'reviews.club' | translate}}</h5>
                                <div class="ms-4">
                                    <app-multiple-stars-rating [rating]="centerRatings.clubRating">
                                    </app-multiple-stars-rating>
                                </div>
                            </div>
                        </div>
                        <div class="col-4" *ngIf="centerRatings.resourceRating!==undefined">
                            <div class="d-flex justify-content-start mb-3 mx-2">
                                <h5 class="card-subtitle align-self-center">{{'reviews.sessions' | translate}}</h5>
                                <div class="ms-4">
                                    <app-multiple-stars-rating [rating]="centerRatings.resourceRating">
                                    </app-multiple-stars-rating>
                                </div>
                            </div>
                        </div>
                        <div class="col-4" *ngIf='centerRatings.serviceRating!==0'>
                            <div class="d-flex justify-content-start mb-3 mx-2">
                                <h5 class="card-subtitle align-self-center">{{'reviews.facilities' | translate}}</h5>
                                <div class="ms-4">
                                    <app-multiple-stars-rating [rating]="centerRatings.serviceRating">
                                    </app-multiple-stars-rating>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <!-- <div class="d-flex justify-content-start mb-3 mx-2">
                                <h5 class="card-subtitle align-self-center">{{'reviews.teachers' | translate}}</h5>
                                <div class="ms-4">
                                    <app-multiple-stars-rating [rating]="centerRatings.staffRating">
                                    </app-multiple-stars-rating>
                                </div>
                            </div>-->
                        </div>
                        <div class="col-8">

                        </div>
                    </div>
                </ng-container>
            </div>

            <h2 *ngIf="centerRatings.data.length" class="mb-2 horizontal-line"></h2>


            <div class="d-flex flex-column mt-5">
                <div class="row mt-4">
                    <ng-container *ngFor="let review of centerRatings.data; let i = index">
                        <div class="w-100" *ngIf=" i % 2 === 0">
                        </div>
                        <div class="col">

                            <div class="d-flex flex-column ms-4 mb-5">
                                <div class="me-1 mb-3">
                                    <app-multiple-stars-rating [rating]="review.clubRating"></app-multiple-stars-rating>
                                </div>
                                <span class="card-subtitle">
                                    {{review.content}}
                                </span>
                                <h2 class="mb-2 mt-2 horizontal-line horizontal-line-short"></h2>
                                <span class="card-subtitle grey-text mt-0">
                                    {{review.reviewerName}} - {{review.createdAt | date:'MMMM y' }}
                                </span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <ng-template #noReview>
            {{'reviews.no_reviews' | translate}}
        </ng-template>

        <div class="d-flex mt-5 mb-3 underline mx-3" *ngIf="false">
            <button class="custom-link-grey custom-img underline align-self-start">
                {{'reviews.see_more_reviews' | translate}}
            </button>
            <img class="arrow-img custom-img align-self-center mb-0" alt="{{'reviews.star' | translate}}"
                src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677166288/assets/arrow/arrow-down-grey.png">
        </div>

    </div>
</ng-template>
