import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export enum Day {
    Today = 'today',
    Other_Day = 'other-day',
}

export enum FormatDate {
  short = 'shortDate',
  full = 'fullDate'
}

@Injectable({
  providedIn: 'root',
})

export class DatetimeService {

    private day = Day;

    constructor() { }

    public concatDatetime(date: string, time: string): string {
      return `${date}T${time}`;
    }

    public formatDate(newDate): string {
        const {year, month, day} = newDate;
        let formatMonth: string | number = month;
        let formatDay: string | number = day;
        if (month.toString().length === 1) {
          formatMonth = `0${month}`;
        }
        if (day.toString().length === 1) {
          formatDay = `0${day}`;
        }
        const date = `${year}-${formatMonth}-${formatDay}`;
        return date;
    }

    public formatDateStrucModel(dateInfo: string): NgbDateStruct {
        const dateTime = dateInfo.split('T');
        const date: string = dateTime[0];
        const [year, month, day] = date.split('-');
        const model: NgbDateStruct = {
          year: parseInt(year, 10),
          month: parseInt(month, 10),
          day:  parseInt(day, 10)
        };
        return model;
    }

    public getActualHours(): string {
        const hours: string = new Date().toLocaleTimeString('en-US',
        {
          hour12: false,
          hour: 'numeric',
          minute: 'numeric'
        });
        return hours;
    }

    private initFirstTimeSlot(day: Day): string {
        let firstSlot: string;
        if (day === this.day.Today) {
            const acutalHours = this.getActualHours();
            const [hour, min] = acutalHours.split(':');
            if (parseInt(min, 10) < 30) {
                firstSlot = `${hour}:00`;
            } else if (parseInt(min, 10) >= 30) {
                firstSlot = `${hour}:30`;
            }
        } else {
            firstSlot = '06:00';
        }
        return firstSlot;
    }

    private initArrayTimeSlot(day: Day): string[] {
        const allHours: string[] = [];
        allHours.push(this.initFirstTimeSlot(day));
        return allHours;
    }

    private isToday(newDate: Date, now: Date): Day {
        newDate.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);
        const date1 = newDate.getTime();
        const date2 = now.getTime();
        if (date1 > date2) {
            return this.day.Other_Day;
        } else if (date1 === date2 ) {
            return this.day.Today;
        }
    }

    public initTimeSlot(newDate: Date): string[] {
        const now = new Date();
        const day: Day = this.isToday(newDate, now);
        const allHours: string[] = this.initArrayTimeSlot(day);
        let slot: string = allHours[0];
        while (slot !== '23:30') {
          const time = slot.split(':');
          let hour: number | string = parseInt(time[0], 10);
          let min: string = time[1];
          if (min === '00') {
            min = '30';
          } else {
            min = '00';
            hour++;
          }
          if (hour.toString().length === 1) {
            hour = hour.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            });
          }
          slot = `${hour}:${min}`;
          allHours.push(slot);
        }
        return allHours;
    }

    public formatDateDisplay(date: string, locale: string, format: FormatDate): string {
      const datePipe: DatePipe = new DatePipe(locale);
      const newDate: string = datePipe.transform(date, format);
      return newDate;
    }

}
