import { Component, Input, OnInit } from '@angular/core';
import { UserPreferenceService } from '../service/user-preferences.service';

@Component({
  selector: 'app-assistance',
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.scss']
})
export class AssistanceComponent implements OnInit {
  @Input() isMobileResolution: boolean;
  lienUtile: string;
  lienUtile1: string;
  lienUtile2: string;
  lienUtile3: string;
  lienUtile4: string;
  lienUtile5: string;
  lienUtile6: string;
  currentLang: string;
  constructor(private userPreferenceService: UserPreferenceService) {

  }

  ngOnInit(): void {
    if (this.userPreferenceService.getCountry() === 'es'){
      this.lienUtile = 'https://support.anybuddyapp.com/es/';
      this.lienUtile2 = 'https://support.anybuddyapp.com/es/article/quieres-reservar-una-pista-pero-no-sabes-como-hacerlo-137h56m/';
      this.lienUtile3 = 'https://support.anybuddyapp.com/es/article/cuando-puedo-reservar-1tvrc0w/';
      this.lienUtile4 = 'https://support.anybuddyapp.com/es/article/reembolso-gypd6x/';
    }
    else{
      this.lienUtile = 'https://support.anybuddyapp.com/fr/';
      this.lienUtile1 = 'https://support.anybuddyapp.com/fr/article/covid-19-information-reservation-anybuddy-l89irn/';
      this.lienUtile2 = 'https://support.anybuddyapp.com/fr/article/comment-ca-fonctionne-qkcjfc/';
      this.lienUtile3 = 'https://support.anybuddyapp.com/fr/article/quand-est-ce-que-je-peux-reserver-1tn5h3v/';
      this.lienUtile4 = 'https://support.anybuddyapp.com/fr/article/anybuddy-incompatible-avec-un-mobile-android-les-solutions-2tnuu1/';
      this.lienUtile5 = 'https://support.anybuddyapp.com/fr/article/covid-19-information-et-regles-pour-le-tennis-france-8hzfm6/';
    }
  }

}
